import React, { useEffect, useState } from "react";

import { useParams, useNavigate } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { toast } from "react-toastify";

import Fetching from "components/fetching/Fetching";
import FormFields from "./FormFields";
import FormPermissions from "./FormPermissions";

import api from "services/api";

import { baseObject } from "../user.constants";

const UserForm = (props) => {
	let navigate = useNavigate();
	const { option, slug } = useParams();

	const { pageName, countries, allCommunes, customers, callbackAction } = props;
	const [isFetching, setIsFetching] = useState(true);
	const [data, setData] = useState({ ...baseObject });
	const [selectTab, setSelectTab] = useState("1");

	const handleClose = () => {
		setData({ ...baseObject });
		navigate("/admin/users");
	};

	const validSave = () => {
		if (!data.name || data.name === "") {
			toast.error("El campo nombre es obligatorio");
			return false;
		}
		if (!data.username || data.username === "") {
			toast.error("El campo nombre de usuario es obligatorio");
			return false;
		}
		if (!data.customer_id || data.customer_id === "") {
			toast.error("El campo cliente es obligatorio");
			return false;
		}

		if (!data.id) {
			if (!data.password || data.password === "") {
				toast.error("El campo de contraseña es obligatorio");
				return false;
			}
			if (data.password !== data.confirm_password) {
				toast.error("Las contraseñas introducidas no coinciden");
				return false;
			}
		}

		return true;
	};

	const handleSave = async () => {
		if (validSave()) {
			setIsFetching(true);
			if (await callbackAction(data)) {
				handleClose();
			} else {
				setIsFetching(false);
			}
		}
	};

	useEffect(() => {
		setIsFetching(false);
		if (option) {
			setIsFetching(true);
			api.get("/admin/user", { selected: slug }).then((response) => {
				setData(response.user);
				setIsFetching(false);
			});
		}

		// eslint-disable-next-line
	}, [option]);

	return (
		<Modal isOpen={option ? true : false} toggle={handleClose} size="lg" backdrop="static">
			{isFetching ? (
				<Fetching />
			) : (
				<>
					<ModalHeader toggle={handleClose}>{pageName}</ModalHeader>
					<ModalBody>
						<Nav tabs>
							<NavItem>
								<NavLink className={selectTab === "1" ? "active" : ""} onClick={() => setSelectTab("1")}>
									Formulario
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink className={selectTab === "3" ? "active" : ""} onClick={() => setSelectTab("3")}>
									Permisos
								</NavLink>
							</NavItem>
						</Nav>
						<TabContent activeTab={selectTab}>
							<TabPane className="fade show" tabId="1">
								<FormFields data={data} setData={setData} countries={countries} allCommunes={allCommunes} customers={customers} />
							</TabPane>
							<TabPane tabId="3">
								<FormPermissions data={data} setData={setData} />
							</TabPane>
						</TabContent>
					</ModalBody>
					<ModalFooter>
						<Button color="secondary" onClick={handleClose}>
							Cancelar
						</Button>
						<Button color="primary" onClick={() => handleSave()}>
							Guardar
						</Button>
					</ModalFooter>
				</>
			)}
		</Modal>
	);
};

export default UserForm;
