import React from "react";

import { Col, Label, Input } from "reactstrap";
import Select from "react-select";

const FormFields = (props) => {
	const { data, setData, customers } = props;

	const user_id = localStorage.getItem("user_id");

	const handleCustomer = (value) => {
		setData({
			...data,
			customer_id: value.value,
		});
	};

	return (
		<div className="form-tab-detail">
			<div className="row">
				<Col md="6 mb-2">
					<Label htmlFor="name">Etiqueta</Label>
					<Input
						className="form-control"
						name="name"
						type="text"
						value={data.name ? data.name : ""}
						onChange={(e) =>
							setData({
								...data,
								name: e.target.value,
							})
						}
					/>
				</Col>
				<Col md="6 mb-2">
					<Label htmlFor="description">Descripción</Label>
					<Input
						className="form-control"
						name="description"
						type="text"
						value={data.description ? data.description : ""}
						onChange={(e) =>
							setData({
								...data,
								description: e.target.value,
							})
						}
					/>
				</Col>
			</div>

			{Number(user_id) === 1 && (
				<div className="row">
					<Col md="6 mb-2">
						<Label htmlFor="customer_id">Cliente</Label>
						<Select
							defaultValue={data.customer_id ? customers.filter((c) => Number(c.value) === Number(data.customer_id)) : ""}
							placeholder={<div>Seleccione......</div>}
							options={customers}
							onChange={(value) => handleCustomer(value)}
						/>
					</Col>
				</div>
			)}
		</div>
	);
};

export default FormFields;
