import React from "react";

import classes from "../answer.module.scss";

const AnswerQuestionFormMultiple = ({ question, setQuestion, data, setData }) => {
	const letters = ["a.", "b.", "c.", "d.", "e.", "f.", "g.", "h.", "i."];

	const selectAlternative = (alternative) => {
		const findIndex = data.questions.findIndex((q) => q.id === question.id);
		data.questions[findIndex].selected = {
			answer: alternative.id,
		};
		setData({
			...data,
			questions: data.questions,
		});

		setQuestion({
			...question,
			selected: {
				answer: alternative.id,
			},
		});
	};

	return (
		<div>
			{question &&
				question.alternatives &&
				question.alternatives.map((alternative, i) => {
					return (
						<div
							key={i}
							className={`${classes.alternative} ${
								question.selected && Number(question.selected.answer) === Number(alternative.id) && classes.selected
							}`}
							onClick={() => selectAlternative(alternative)}
						>
							{letters[i]} {alternative.description}
						</div>
					);
				})}
		</div>
	);
};

export default AnswerQuestionFormMultiple;
