import React, { useState, useEffect, useCallback, Fragment, useMemo } from "react";
import { Container, Row, Col } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import fileDownload from "js-file-download";
import debounce from "lodash/debounce";

import Breadcrumb from "layout/breadcrumb";
import Paginator from "components/list/Paginator";
import Search from "components/list/Search";
import Fetching from "components/fetching/Fetching";
import Confirm from "components/confirm/Confirm";

import SubjectTable from "./table/SubjectTable";
import SubjectForm from "./form/SubjectForm";

import api from "services/api";
import { getToday } from "services/utils";
import { HANDLE_GLOBAL_FETCHING } from "../../../redux/actionTypes";

const Subject = (props) => {
	let navigate = useNavigate();
	const dispatch = useDispatch();

	const pageName = "Subjects";
	const [isFetching, setIsFetching] = useState(true);
	const [search, setSearch] = useState("");
	const [mainSearch, setMainSearch] = useState("");
	const [page, setPage] = useState(1);
	const [data, setData] = useState([]);
	const [permissions, setPermissions] = useState([]);
	const [from, setFrom] = useState("");
	const [to, setTo] = useState("");
	const [total, setTotal] = useState("");
	const [lastPage, setLastPage] = useState("");

	const [selected, setSelected] = useState("");
	const [modal, setModal] = useState(false);

	useEffect(() => {
		api.get("/admin/subjects", {
			page,
			search,
		}).then((response) => {
			updateData(response);
		});
		// eslint-disable-next-line
	}, [page, search]);

	const updateData = (response) => {
		const { subjects, permissions } = response;

		setPermissions(permissions);
		setData(subjects.data);
		setFrom(subjects.from);
		setTo(subjects.to);
		setTotal(subjects.total);
		setLastPage(subjects.last_page);

		setIsFetching(false);
	};

	const debouncedSetSearch = useMemo(
		() =>
			debounce((v) => {
				setSearch(v);
			}, 500),
		[setSearch]
	);

	const delayedQuery = useCallback(
		(...args) => {
			debouncedSetSearch(...args);
		},
		[debouncedSetSearch]
	);

	const setDelaySearch = (value) => {
		setMainSearch(value);
		delayedQuery(value);
	};

	// Form
	const createElement = () => {
		navigate("/admin/subjects/create");
	};

	const handleEdit = async (data) => {
		const response = await api.post("/admin/subjects_save", data).catch((e) => {
			return { error: e.message };
		});
		if (response.hasOwnProperty("error")) {
			return false;
		}

		updateData(response);
		toast.success("Registro guardado correctamente");
		return true;
	};

	const deleteElement = (item) => {
		setSelected(item.id);
		setModal(true);
	};

	const handleDelete = () => {
		api.post("/admin/subjects_delete", { selected }).then((response) => {
			updateData(response);
			setSelected("");

			toast.success("Registro eliminado correctamente");
		});
	};

	const handleExcel = () => {
		const filename = `Asignaturas ${getToday()}.xlsx`;

		dispatch({
			type: HANDLE_GLOBAL_FETCHING,
			payload: {
				isGlobalFetching: true,
				documentName: filename,
			},
		});

		api.getExcel("/admin/subjects/excel", { search }).then((data) => {
			fileDownload(data, filename);

			dispatch({
				type: HANDLE_GLOBAL_FETCHING,
				payload: {
					isGlobalFetching: false,
					documentName: "",
				},
			});
		});
	};

	return (
		<Fragment>
			<Breadcrumb title={"Asignaturas"} parent="Mantenedores" />
			<Container fluid={true}>
				<Row>
					<Search
						search={mainSearch}
						setSearch={setDelaySearch}
						to={to}
						from={from}
						total={total}
						permissions={permissions}
						handleCreate={createElement}
						handleExport={handleExcel}
					/>

					<Col sm="12">
						{isFetching ? <Fetching /> : <SubjectTable deleteElement={deleteElement} data={data} permissions={permissions} />}
					</Col>

					<Paginator page={page} setPage={setPage} lastPage={lastPage} total={total} />
				</Row>
			</Container>

			<SubjectForm pageName={pageName} callbackAction={handleEdit} />

			<Confirm modal={modal} setModal={setModal} callbackAction={handleDelete} />
		</Fragment>
	);
};

export default Subject;
