export const base_filters = [
	{
		key: "filter_subject_id",
		name: "Asignatura",
		value: "",
		type: "select",
		options: [],
	},
	{
		key: "filter_level_id",
		name: "Nivel de Pregunta",
		value: "",
		type: "select",
		options: [],
	},
	{
		key: "filter_name",
		name: "Nombre",
		value: "",
		type: "text",
		options: [],
	},
];
