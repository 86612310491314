import React, { useEffect, useState, Fragment } from "react";

import { Container, Row, Col, Button } from "reactstrap";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";

import classes from "../student.module.scss";

import { baseObject } from "../student.constants";
import Breadcrumb from "layout/breadcrumb";
import Fetching from "components/fetching/Fetching";
import FormProfile from "./FormProfile";
import FormUser from "./FormUser";
import CourseModalList from "../../courses/list/CourseModalList";
import EvaluationModalList from "../../evaluations/list/EvaluationModalList";

import api from "services/api";

import { isValidPermission } from "services/utils";
import FormCourse from "./FormCourse";

const StudentForm = (props) => {
	let navigate = useNavigate();
	const { slug } = useParams();

	const [isFetching, setIsFetching] = useState(true);
	const [data, setData] = useState({ ...baseObject });
	const [permissions, setPermissions] = useState([]);
	const [courses, setCourses] = useState([]);

	const [showCourses, setShowCourses] = useState(false);
	const [showEvaluations, setShowEvaluations] = useState(false);

	const validSave = () => {
		if (!data.username || data.username === "") {
			toast.error("El campo nombre de usuario es obligatorio");
			return false;
		}
		if (!data.name || data.name === "") {
			toast.error("El campo nombre es obligatorio");
			return false;
		}
		if (!data.email || data.email === "") {
			toast.error("El campo de correo electrónico es obligatorio");
			return false;
		}

		if (data.password) {
			if (data.password.length < 6) {
				toast.error("La contraseña debe contener al menos 6 caracteres");
				return false;
			}

			if (data.password !== data.confirm_password) {
				toast.error("Las contraseñas introducidas no coinciden");
				return false;
			}
		}

		return true;
	};

	const handleSave = async () => {
		setIsFetching(true);

		let newData = { ...data };
		newData.type = "student";

		let url = "/students_save";
		if (slug) {
			url = "/admin/users_save";
		}

		if (validSave()) {
			api.post(url, newData)
				.then((response) => {
					setIsFetching(false);
					response.student.password = "";
					setData({ ...response.student });
					toast.success("Registro guardado correctamente");
					navigate(`/students/edit/${response.student.slug}`);
				})
				.catch((error) => {
					setIsFetching(false);
				});
		} else {
			setIsFetching(false);
		}
	};

	const selectCourse = (course) => {
		let newSelectCourses = data.select_courses ? [...data.select_courses] : [];

		if (!newSelectCourses.includes(course.id)) {
			newSelectCourses.push(course.id);
		} else {
			newSelectCourses = newSelectCourses.filter((ns) => ns !== course.id);
		}

		setData({ ...data, select_courses: newSelectCourses });
	};

	useEffect(() => {
		setIsFetching(true);
		api.get("/student", { selected: slug }).then((response) => {
			setData(response.student ? response.student : { ...baseObject });
			setPermissions(response.permissions && response.permissions);
			setCourses(response.courses ? response.courses : []);
			setIsFetching(false);
		});
		// eslint-disable-next-line
	}, []);

	if (isFetching) {
		return <Fetching />;
	}

	return (
		<Fragment>
			<Breadcrumb title={"Detalle de Estudiante"} parent="Estudiantes" />

			{isValidPermission("edit", permissions) && (
				<Container className={classes.students} fluid={true}>
					{data.id && (
						<Row>
							<Col md="12">
								<Button color="primary" className={classes.button} onClick={() => setShowCourses(true)}>
									Cursos
								</Button>
								<Button color="primary" className={classes.button} onClick={() => setShowEvaluations(true)}>
									Evaluaciones
								</Button>
							</Col>
						</Row>
					)}

					<div className="edit-profile">
						<Row>
							{!data.id && <FormCourse data={data} setData={setData} courses={courses} selectCourse={selectCourse} />}

							<FormProfile data={data} setData={setData} handleSave={handleSave} />

							{data.id && <FormUser data={data} setData={setData} handleSave={handleSave} />}
						</Row>
					</div>
				</Container>
			)}

			<CourseModalList pageName={"Cursos"} courses={data.courses} list={showCourses} setList={setShowCourses} />

			<EvaluationModalList pageName={"Evaluaciones"} evaluations={data.evaluations} list={showEvaluations} setList={setShowEvaluations} />
		</Fragment>
	);
};

export default StudentForm;
