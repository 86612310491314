import React, { useState } from "react";
import { Badge, Input, Modal, ModalHeader, ModalBody } from "reactstrap";

import classes from "../evaluation.module.scss";

import Fetching from "components/fetching/Fetching";
import EvaluationReviewQuestionDetailAction from "./EvaluationReviewQuestionDetailAction";
import EvaluationReviewTeacher from "./EvaluationReviewTeacher";

import api from "services/api";

const EvaluationReviewQuestionDetail = ({ index, question, data, setData, divReview }) => {
	const [loading, setLoading] = useState(false);
	const [showPrompt, setShowPrompt] = useState(false);

	const userRoles = JSON.parse(localStorage.getItem("roles"));
	const userName = localStorage.getItem("full_name");
	const isAdmin = userRoles.includes(1) || userRoles.includes(2) || String(userName).toLowerCase().includes("stange");

	const additionalClass = question.type ? `type_${question.type.id}` : "";

	let userAnswer = null;
	if (question.selected && [1].includes(question.type.id) && question.selected.text_answer) {
		userAnswer = JSON.parse(question.selected.text_answer);
	}
	if (question.selected && [2, 3, 4].includes(question.type.id) && question.selected.text_answer) {
		userAnswer = question.selected;
	}

	const [localFeedback, setLocalFeedback] = useState(
		userAnswer && userAnswer.hasOwnProperty("feedback") && userAnswer.feedback
			? userAnswer.feedback
			: userAnswer && userAnswer.hasOwnProperty("ia_feedback") && userAnswer.ia_feedback
			? userAnswer.ia_feedback
			: ""
	);

	const handleSaveFeedback = () => {
		setLoading(true);
		api.post("/evaluations/review/question/feedback", { ...question.selected, newFeedback: localFeedback }).then((response) => {
			setLoading(false);
		});
	};

	return (
		<div className={classes.question}>
			<div className={classes.question_statement}>
				<div>
					{question.type && (
						<div>
							<Badge className={`${classes.question_badge} ${classes[additionalClass]}`}>{question.type.name}</Badge>
							{question.ia_question && isAdmin && (
								<Badge style={{ cursor: "pointer" }} className={`${classes.question_badge}`} onClick={() => setShowPrompt(true)}>
									<i className="fa fa-var-road"></i> Ver Prompt
								</Badge>
							)}
						</div>
					)}
					<div>
						{index + 1}. {question.statement}
					</div>
				</div>
			</div>
			<div className={classes.question_option}>
				{loading ? (
					<Fetching customStyle={{ padding: "initial", display: "flex", alignItems: "center" }} />
				) : window.location.pathname.includes("/finish/") ? (
					<EvaluationReviewTeacher question={question} data={data} setData={setData} setLoading={setLoading} />
				) : (
					<EvaluationReviewQuestionDetailAction
						question={question}
						data={data}
						setData={setData}
						setLoading={setLoading}
						divReview={divReview}
					/>
				)}
			</div>
			{[1, 3].includes(question.type.id) && (
				<div style={{ width: "50%" }}>
					{question &&
						question.alternatives &&
						question.alternatives.map((alternative, index) => {
							return (
								<div key={index} className={classes.answer}>
									<div className={`${alternative.is_correct && classes.text_check}`}>{alternative.description}</div>
								</div>
							);
						})}
				</div>
			)}
			{question.type.id === 2 && (
				<div className={classes.answer} style={{ width: "50%", flexDirection: "column", alignItems: "normal" }}>
					<div className={`${question.is_true && classes.is_true}`}>Verdadero</div>
					<div className={`${!question.is_true && classes.is_false}`}>Falso</div>
				</div>
			)}
			{question.type.id === 4 && <div style={{ width: "50%" }}></div>}
			{window.location.pathname.includes("/finish/") && (
				<div style={{ width: "50%" }}>
					{question.selected ? (
						<>
							<strong style={{ fontSize: "17px", fontWeight: "500", color: "#00a58d" }}>
								{Number(data.user_id) === Number(localStorage.getItem("user_id")) ? "Tu Respuesta" : "Respuesta del alumno"}
							</strong>
							<div>
								{[1].includes(question.type.id) && (
									<div>
										<strong>{userAnswer && userAnswer.description}</strong>
									</div>
								)}
								{[1, 3].includes(question.type.id) && (
									<div>
										<strong>{userAnswer && userAnswer.answer}</strong>
									</div>
								)}
								{[2].includes(question.type.id) && (
									<div>
										<div>
											<strong>{question.selected.text_answer[0].toUpperCase() + question.selected.text_answer.slice(1)}</strong>
										</div>
									</div>
								)}
								{[4].includes(question.type.id) && (
									<div>
										<div>
											<strong>{question.selected.text_answer}</strong>
										</div>
									</div>
								)}
							</div>
						</>
					) : (
						<strong style={{ fontSize: "17px", fontWeight: "500", color: "#00a58d" }}>Sin respuesta</strong>
					)}
				</div>
			)}
			{window.location.pathname.includes("/finish/") && Number(data.teacher_id) === Number(localStorage.getItem("user_id")) && (
				<div style={{ width: "100%" }}>
					<hr />
					<div style={{ fontSize: "17px", fontWeight: "500" }}>Comentarios</div>
					<Input
						className="form-control"
						name="description"
						type="textarea"
						value={localFeedback}
						rows={5}
						onChange={(e) => setLocalFeedback(e.target.value)}
						readOnly={loading}
					/>
					<div className={`${classes.save_button} ${loading && classes.disabled}`} onClick={() => (loading ? null : handleSaveFeedback())}>
						Guardar comentarios
					</div>
				</div>
			)}
			{window.location.pathname.includes("/finish/") && Number(data.teacher_id) !== Number(localStorage.getItem("user_id")) && (
				<div style={{ width: "100%" }}>
					<hr />
					<div>{userAnswer && userAnswer.feedback ? userAnswer.feedback : ""}</div>
				</div>
			)}

			<Modal size="lg" isOpen={showPrompt}>
				<ModalHeader toggle={() => setShowPrompt(false)}>Prompt</ModalHeader>
				<ModalBody>
					{question.prompt.split("\n").map((line, index) => (
						<span key={index}>
							{line}
							<br />
						</span>
					))}
				</ModalBody>
			</Modal>
		</div>
	);
};

export default EvaluationReviewQuestionDetail;
