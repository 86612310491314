import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, Label } from "reactstrap";
import Select from "react-select";
import { toast } from "react-toastify";

import Fetching from "components/fetching/Fetching";
import QuestionFormMultiple from "./QuestionFormMultiple";
import QuestionFormTOF from "./QuestionFormTOF";
import QuestionFormSentence from "./QuestionFormSentence";
import QuestionFormConnect from "./QuestionFormConnect";

import api from "services/api";
import UploadFile from "components/uploadFile/UploadFile";

const baseObject = {
	id: "",
	statement: "",
	is_true: false,
	question_category_id: "",
	question_level_id: "",
	question_content_id: "",
	subject_id: "",
	objectives: [],
	alternatives: [],
	type: {
		id: "",
		name: "",
		component: "",
	},
};

const QuestionForm = (props) => {
	let navigate = useNavigate();
	const { option, question_id } = useParams();

	const { pageName, callbackAction, questionsType, questionsCategory, questionsLevel, subjects, questionsContent, questionsObjective } = props;

	const [isFetching, setIsFetching] = useState(true);
	const [data, setData] = useState({ ...baseObject });
	const [files, setFiles] = useState([]);

	const handleClose = () => {
		setFiles([]);
		setData({ ...baseObject });
		navigate("/admin/questions");
	};

	const handleSave = () => {
		if (validSave()) {
			handleClose();
			callbackAction({ data, files: files });
			setData(baseObject);
		}
	};

	const validSave = () => {
		let is_valid = true;

		if (!data.type || data.type.id === "") {
			toast.error("El campo tipo es obligatorio");
			return false;
		}
		if (!data.question_category_id || data.question_category_id === "") {
			toast.error("El campo de categoría de pregunta es obligatorio");
			return false;
		}
		if (!data.question_level_id || data.question_level_id === "") {
			toast.error("El campo de nivel de pregunta es obligatorio");
			return false;
		}
		if (!data.statement || data.statement === "") {
			toast.error("El campo enunciado es obligatorio");
			return false;
		}

		if (data.type.id === 1) {
			is_valid = false;

			if (data.alternatives.length === 0) {
				toast.error("You must enter alternatives");
				return false;
			}
			data.alternatives.forEach((element, i) => {
				if (element.is_correct) {
					is_valid = true;
				}
			});
			if (!is_valid) {
				toast.error("You must select a correct alternative");
			}
		}

		if (data.type.id === 3) {
			if (data.alternatives.length === 0) {
				toast.error("You must enter possible answer");
				return false;
			}
		}

		if (data.type.id === 5) {
			if (data.alternatives.length === 0) {
				toast.error("You must enter statement connection");
				return false;
			}
		}

		return is_valid;
	};

	const handleType = (value) => {
		setData({
			...data,
			type: {
				id: value.value,
				name: value.label,
				component: value.component,
			},
		});
	};

	const handleQuestionLevel = (value) => {
		setData({
			...data,
			question_level_id: value.value,
		});
	};

	const handleQuestionCategory = (value) => {
		setData({
			...data,
			question_category_id: value.value,
		});
	};

	const handleSubject = (value) => {
		setData({
			...data,
			subject_id: value.value,
			question_category_id: "",
			question_level_id: "",
		});
	};

	const handleQuestionContent = (value) => {
		setData({
			...data,
			question_content_id: value.value,
		});
	};

	const changeStatement = (event, editor) => {
		const newDescription = editor.getData();

		setData({ ...data, statement: newDescription });
	};

	const components = {
		QuestionFormMultiple: <QuestionFormMultiple className="icon" data={data} setData={setData} />,
		QuestionFormTOF: <QuestionFormTOF className="icon" data={data} setData={setData} />,
		QuestionFormSentence: <QuestionFormSentence className="icon" data={data} setData={setData} />,
		QuestionFormConnect: <QuestionFormConnect className="icon" data={data} setData={setData} />,
	};

	useEffect(() => {
		setIsFetching(false);
		if ((option && question_id) || data.subject_id !== "") {
			if (data.id === "" && question_id) {
				setIsFetching(true);
				api.get("/admin/question", { selected: question_id }).then((response) => {
					setData(response.question);
					setFiles(response.question.files);
					setIsFetching(false);
				});
			}
		} else {
			let base = { ...baseObject };
			setData(base);
		}
		// eslint-disable-next-line
	}, [option, question_id, data.subject_id]);

	return (
		<Modal isOpen={option ? true : false} toggle={handleClose} size="lg" backdrop="static" scrollable={true}>
			{isFetching ? (
				<Fetching />
			) : (
				<>
					<ModalHeader toggle={handleClose}>{`${pageName} - ${data.type && data.type.name}`}</ModalHeader>
					<ModalBody>
						<Row className="questionFormMultiple" style={{ borderBottom: "1px solid #00a58d", marginBottom: "15px" }}>
							<Col md="12 mb-3">
								<Label htmlFor="name">Tipo de Pregunta</Label>
								<Select
									defaultValue={
										data.type && data.type.id ? questionsType.filter((c) => Number(c.value) === Number(data.type.id)) : ""
									}
									value={data.type && data.type.id ? questionsType.filter((c) => Number(c.value) === Number(data.type.id)) : ""}
									placeholder={<div>Seleccione......</div>}
									options={questionsType}
									onChange={(value) => handleType(value)}
								/>
							</Col>
						</Row>

						<Row className="questionFormMultiple">
							<Col md="6 mb-2">
								<Label htmlFor="name">Asignatura</Label>
								<Select
									defaultValue={data.subject_id ? subjects.filter((c) => Number(c.value) === Number(data.subject_id)) : ""}
									value={data.subject_id ? subjects.filter((c) => Number(c.value) === Number(data.subject_id)) : ""}
									placeholder={<div>Seleccione......</div>}
									options={subjects}
									onChange={(value) => handleSubject(value)}
								/>
							</Col>
							<Col md="6 mb-2">
								<Label htmlFor="question_level_id">Nivel</Label>
								<Select
									defaultValue={
										data.question_level_id ? questionsLevel.filter((c) => Number(c.value) === Number(data.question_level_id)) : ""
									}
									value={
										data.question_level_id ? questionsLevel.filter((c) => Number(c.value) === Number(data.question_level_id)) : ""
									}
									placeholder={<div>Seleccione......</div>}
									options={questionsLevel.filter((ql) => Number(ql.subject_id) === Number(data.subject_id))}
									onChange={(value) => handleQuestionLevel(value)}
								/>
							</Col>
							<Col md="6 mb-2">
								<Label htmlFor="question_category_id">Categoría</Label>
								<Select
									defaultValue={
										data.question_category_id
											? questionsCategory.filter((c) => Number(c.value) === Number(data.question_category_id))
											: ""
									}
									value={
										data.question_category_id
											? questionsCategory.filter((c) => Number(c.value) === Number(data.question_category_id))
											: ""
									}
									placeholder={<div>Seleccione......</div>}
									options={questionsCategory.filter(
										(qc) =>
											Number(qc.subject_id) === Number(data.subject_id) &&
											Number(qc.level_id) === Number(data.question_level_id)
									)}
									onChange={(value) => handleQuestionCategory(value)}
								/>
							</Col>
							<Col md="6 mb-2">
								<Label htmlFor="question_content_id">Contenido</Label>
								<Select
									defaultValue={
										data.question_content_id
											? questionsContent.filter((c) => Number(c.value) === Number(data.question_content_id))
											: ""
									}
									value={
										data.question_content_id
											? questionsContent.filter((c) => Number(c.value) === Number(data.question_content_id))
											: ""
									}
									placeholder={<div>Seleccione......</div>}
									options={questionsContent.filter(
										(qc) =>
											Number(qc.question_level_id) === Number(data.question_level_id) &&
											Number(qc.question_category_id) === Number(data.question_category_id)
									)}
									onChange={(value) => handleQuestionContent(value)}
								/>
							</Col>

							<Col md="12 mb-3">
								<Label htmlFor="customer_id">Objetivos</Label>
								<Select
									value={
										data && data.objectives && questionsObjective.filter((objective) => data.objectives.includes(objective.value))
									}
									isMulti
									placeholder={<div>Seleccione</div>}
									options={questionsObjective ? questionsObjective : []}
									onChange={(value) => {
										let newObjectives = [];
										value.forEach((element) => {
											newObjectives.push(element.value);
										});

										setData({
											...data,
											objectives: newObjectives,
										});
									}}
								/>
							</Col>

							<Col md="12 mb-3">
								<Label htmlFor="statement">Enunciado</Label>

								<CKEditor
									editor={ClassicEditor}
									onChange={changeStatement}
									config={{
										toolbar: ["heading", "|", "bold", "italic", "underline", "bulletedList", "numberedList", "link"],
										placeholder: "",
									}}
									data={data && data.statement ? data.statement : ""}
								/>
							</Col>

							{components[data.type.component]}

							<Col md="12 mb-3">
								<UploadFile files={files} setFiles={setFiles} />
							</Col>
						</Row>
					</ModalBody>
					<ModalFooter>
						<Button color="secondary" onClick={handleClose}>
							Cancelar
						</Button>
						<Button color="primary" onClick={() => handleSave()}>
							Guardar
						</Button>
					</ModalFooter>
				</>
			)}
		</Modal>
	);
};

export default QuestionForm;
