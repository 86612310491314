import React, { useState, useEffect, useCallback, Fragment, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row } from "reactstrap";
import { toast } from "react-toastify";
import debounce from "lodash/debounce";

import Breadcrumb from "layout/breadcrumb";
import Paginator from "components/list/Paginator";
import Search from "components/list/Search";
import Fetching from "components/fetching/Fetching";
import CustomerForm from "./form/CustomerForm";
import Confirm from "components/confirm/Confirm";

import CustomerTable from "./table/CustomerTable";

import api from "services/api";

const Customer = (props) => {
	let navigate = useNavigate();

	const pageName = "Clientes";
	const [isFetching, setIsFetching] = useState(true);
	const [search, setSearch] = useState("");
	const [mainSearch, setMainSearch] = useState("");
	const [page, setPage] = useState(1);
	const [data, setData] = useState([]);
	const [permissions, setPermissions] = useState([]);
	const [from, setFrom] = useState("");
	const [to, setTo] = useState("");
	const [total, setTotal] = useState("");
	const [lastPage, setLastPage] = useState("");

	const [selected, setSelected] = useState("");
	const [modal, setModal] = useState(false);

	const [countries, setCountries] = useState([]);
	const [allCommunes, setAllCommunes] = useState([]);

	useEffect(() => {
		api.get("/admin/customers", {
			page,
			search,
		}).then((response) => {
			updateData(response);
		});

		// eslint-disable-next-line
	}, [page, search]);

	const updateData = (response) => {
		const { customers, permissions, countries, communes } = response;

		setPermissions(permissions);
		setData(customers.data);
		setFrom(customers.from);
		setTo(customers.to);
		setTotal(customers.total);
		setLastPage(customers.last_page);

		setCountries(countries);
		setAllCommunes(communes);

		setIsFetching(false);
	};

	const debouncedSetSearch = useMemo(
		() =>
			debounce((v) => {
				setSearch(v);
			}, 500),
		[setSearch]
	);

	const delayedQuery = useCallback(
		(...args) => {
			debouncedSetSearch(...args);
		},
		[debouncedSetSearch]
	);

	const setDelaySearch = (value) => {
		setMainSearch(value);
		delayedQuery(value);
	};

	// Form
	const createElement = () => {
		navigate("/admin/customers/create");
	};

	const handleEdit = async (data) => {
		const response = await api.post("/admin/customers_save", data).catch((e) => {
			return { error: e.message };
		});
		if (response.hasOwnProperty("error")) {
			return false;
		}

		updateData(response);
		toast.success("Registro guardado correctamente");

		return true;
	};

	const deleteElement = (item) => {
		setSelected(item.id);
		setModal(true);
	};

	const handleDelete = () => {
		api.post("/admin/customers_delete", { selected }).then((response) => {
			updateData(response);

			toast.success("Registro eliminado correctamente");
		});
	};

	if (isFetching) {
		return <Fetching />;
	}

	return (
		<Fragment>
			<Breadcrumb title={"Clientes"} parent="Mantenedores" />
			<Container fluid={true}>
				<Row>
					<Search
						search={mainSearch}
						setSearch={setDelaySearch}
						to={to}
						from={from}
						total={total}
						permissions={permissions}
						handleCreate={createElement}
					/>

					<CustomerTable deleteElement={deleteElement} data={data} permissions={permissions} />

					<Paginator page={page} setPage={setPage} lastPage={lastPage} total={total} />
				</Row>
			</Container>

			<CustomerForm pageName={pageName} countries={countries} allCommunes={allCommunes} callbackAction={handleEdit} />

			<Confirm modal={modal} setModal={setModal} callbackAction={handleDelete} />
		</Fragment>
	);
};

export default Customer;
