import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { Container, Row, Col, Form, FormGroup, Input, Label, Button } from "reactstrap";
import { Password, Username } from "constant";
import "react-toastify/dist/ReactToastify.css";

import Fetching from "components/fetching/Fetching";

import api from "services/api";

const Login = (props) => {
	const [loading, setLoading] = useState(false);
	const [togglePassword, setTogglePassword] = useState(false);
	const [remember, setRemember] = useState(false);
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [name, setName] = useState(localStorage.getItem("full_name"));

	const token = localStorage.getItem("token");

	useEffect(() => {
		localStorage.setItem("full_name", name);
	}, [name, token]);

	const HideShowPassword = (tPassword) => {
		setTogglePassword(!tPassword);
	};

	const handleLogin = () => {
		const form = {
			username,
			password,
			remember,
		};

		setLoading(true);
		api.post("/auth/login", form)
			.then((response) => {
				const { user } = response;
				setName(user.full_name);

				localStorage.setItem("token", user.authorization);
				localStorage.setItem("full_name", user.full_name);
				localStorage.setItem("slug", user.slug);
				localStorage.setItem("expiration_date", user.expiration_date);
				localStorage.setItem("customer", user.customer);
				localStorage.setItem("user_id", user.user_id);
				localStorage.setItem("screens", JSON.stringify(user.screens));
				localStorage.setItem("roles", JSON.stringify(user.roles));
				setLoading(false);

				window.location.href = `/dashboard`;
			})
			.catch((error) => {
				setLoading(false);
			});
	};

	const handleKeyPress = (e) => {
		const keyCode = e.which || e.keyCode;
		if (keyCode === 13) {
			handleLogin();
		}
	};

	if (token) {
		window.location.href = `/dashboard`;
	}

	return (
		<Container fluid={true}>
			<Row>
				<Col
					xl="7"
					className="b-center bg-size"
					style={{
						backgroundImage: `url(${require("assets/images/login/2.png")})`,
						backgroundSize: "cover",
						backgroundPosition: "center",
						display: "block",
					}}
				>
					<img className="bg-img-cover bg-center" style={{ display: "none" }} src={require("assets/images/login/2.png")} alt="looginpage" />
				</Col>
				<Col xl="5" className="p-0">
					<div className="login-card">
						<div>
							<div>
								<a className="logo text-left" href="https://midio.cl">
									<img className="img-fluid for-light" src={require("assets/images/logo/login.png")} alt="looginpage" />
									<img className="img-fluid for-dark" src={require("assets/images/logo/logo_dark.png")} alt="looginpage" />
								</a>
							</div>
							<div className="login-main login-tab">
								{loading ? (
									<Fetching />
								) : (
									<Form className="theme-form">
										<h4>Iniciar sesión</h4>
										<p>{"Enter your email & password to login"}</p>
										<FormGroup>
											<Label className="col-form-label">{Username}</Label>
											<Input
												value={username}
												onChange={(e) => setUsername(e.target.value)}
												className="form-control"
												type="text"
												required=""
												placeholder="demo@midio.cl"
												onKeyPress={(e) => handleKeyPress(e)}
											/>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label">{Password}</Label>
											<Input
												className="form-control"
												type={togglePassword ? "text" : "password"}
												name="login[password]"
												value={password}
												onChange={(e) => setPassword(e.target.value)}
												required=""
												placeholder="*********"
												onKeyPress={(e) => handleKeyPress(e)}
											/>
											<div className="show-hide" onClick={() => HideShowPassword(togglePassword)}>
												<span className={togglePassword ? "" : "show"}></span>
											</div>
										</FormGroup>
										<FormGroup className="mb-0">
											<div className="checkbox ml-3">
												<Input
													id="checkbox1"
													type="checkbox"
													value={remember}
													onChange={(e) => setRemember(e.target.checked)}
												/>
												<Label className="text-muted" for="checkbox1">
													Recordar contraseña
												</Label>
											</div>

											<Button color="primary" className="btn-block" onClick={(e) => handleLogin(e)}>
												Iniciar sesión
											</Button>
										</FormGroup>
									</Form>
								)}
							</div>
						</div>
					</div>
				</Col>
			</Row>
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="colored"
			/>
		</Container>
	);
};

export default Login;
