import axios from "axios";
import { toast } from "react-toastify";

const setupInterceptorsConst = {
	setupInterceptors: (store, props) => {
		axios.interceptors.response.use(
			(response) => {
				return response;
			},
			(error) => {
				switch (error && error.response && error.response.status) {
					case 400:
						toast.error(error.response.data.message, {
							toastId: "error-400-id",
						});
						return Promise.reject(error);
					case 401:
						toast.error("Su sesión ha expirado, por favor conéctese de nuevo para continuar");
						localStorage.removeItem("token");
						localStorage.removeItem("full_name");
						localStorage.removeItem("expiration_date");
						localStorage.removeItem("customer");
						localStorage.removeItem("screens");
						localStorage.removeItem("roles");

						window.location.href = `/login`;
						return new Promise(() => {});
					default:
						toast.error("Se ha producido un error inesperado, póngase en contacto con el servicio de asistencia", {
							toastId: "error-400-id",
						});
						return Promise.reject(error);
				}
			}
		);
	},
};

export default setupInterceptorsConst;
