import React from "react";
import { useNavigate } from "react-router-dom";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import { ReactComponent as EditIcon } from "assets/icons/editar.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/basura.svg";
import { isValidPermission } from "services/utils";
import { Badge } from "reactstrap";

const QuestionLevelsTable = (props) => {
	let navigate = useNavigate();

	const { deleteElement, data, permissions } = props;

	return (
		<div style={{ width: "100%" }}>
			<Table className="base-table">
				<Thead>
					<Tr>
						<Th style={{ width: "30%" }}>Enunciado</Th>
						<Th>Asignatura</Th>
						<Th>Nivel</Th>
						<Th>Categoría</Th>
						<Th>Contenido</Th>
						<Th style={{ width: "14%" }}>Objetivos</Th>
						<Th>Tipo</Th>
						<Th>Acciones</Th>
					</Tr>
				</Thead>
				<Tbody>
					{data.map((item, i) => {
						return (
							<Tr key={i}>
								<Td style={{ fontSize: "13px" }}>{item.statement}</Td>
								<Td style={{ fontSize: "13px" }}>{item.subject}</Td>
								<Td style={{ fontSize: "13px" }}>{item.question_level}</Td>
								<Td style={{ fontSize: "13px" }}>{item.question_category}</Td>
								<Td style={{ fontSize: "13px" }}>{item.question_content}</Td>
								<Td>
									{item.objectives &&
										item.objectives.map((element, index) => {
											return (
												<Badge color="primary" key={index} style={{ fontSize: "11px", fontWeight: "400" }}>
													{element}
												</Badge>
											);
										})}
								</Td>
								<Td style={{ fontSize: "13px" }}>{item.question_type}</Td>
								<Td>
									<div>
										{isValidPermission("edit", permissions) && (
											<span className="table-actions" onClick={() => navigate("/admin/questions/edit/" + item.id)}>
												<EditIcon className="icon-edit" />
											</span>
										)}
										{isValidPermission("delete", permissions) && (
											<span className="table-actions icon trash" onClick={() => deleteElement(item)}>
												<DeleteIcon className="icon-delete" />
											</span>
										)}
									</div>
								</Td>
							</Tr>
						);
					})}
				</Tbody>
			</Table>
		</div>
	);
};

export default QuestionLevelsTable;
