import React from "react";
import { Modal, ModalHeader, ModalFooter, Button } from "reactstrap";

const EvaluationFinish = ({ showModal, setShowModal, handleFinish }) => {
	const toggle = () => {
		setShowModal(!showModal);
	};

	return (
		<Modal isOpen={showModal} toggle={toggle} className="modal-body" backdrop="static">
			<ModalHeader toggle={toggle}>Estas seguro que deseas finalizar la evaluación?</ModalHeader>
			<ModalFooter>
				<Button color="secondary" onClick={toggle}>
					Cancelar
				</Button>
				<Button color="primary" onClick={() => handleFinish()}>
					Confirmar
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default EvaluationFinish;
