import React from "react";

import { Col, Row } from "reactstrap";

import classes from "../question.module.scss";

const QuestionFormTOF = (props) => {
	const { data, setData } = props;

	return (
		<Col md="12 mb-3">
			<Row>
				<Col md="6 mb-2">
					<div
						className={`${classes.buttonTrueFalse} ${!data.is_true && data.is_true !== "" && classes.false}`}
						onClick={() => setData({ ...data, is_true: false })}
					>
						Falso
					</div>
				</Col>
				<Col md="6 mb-2">
					<div className={`${classes.buttonTrueFalse} ${data.is_true && classes.true}`} onClick={() => setData({ ...data, is_true: true })}>
						Verdadero
					</div>
				</Col>
			</Row>
		</Col>
	);
};

export default QuestionFormTOF;
