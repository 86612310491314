import React from "react";

import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

import CourseModalTable from "./CourseModalTable";

const CourseModalList = (props) => {
	const { pageName, courses, list, setList} = props;

	const handleClose = () => {
		setList(false);
	};

	return (
		<Modal isOpen={list} toggle={handleClose} size="lg">
			<>
				<ModalHeader toggle={handleClose}>{(pageName)}</ModalHeader>
				<ModalBody>
					<CourseModalTable  courses={courses} />
				</ModalBody>
				<ModalFooter>
					<Button color="primary" onClick={() => handleClose()}>
						Aceptar
					</Button>
				</ModalFooter>
			</>
		</Modal>
	);
};

export default CourseModalList;
