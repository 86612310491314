import React from "react";

import { useNavigate } from "react-router-dom";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import { ReactComponent as EditIcon } from "assets/icons/editar.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/basura.svg";
import { isValidPermission, dateToLocale } from "services/utils";
import { Col } from "reactstrap";

const CustomerTable = (props) => {
	let navigate = useNavigate();

	const { deleteElement, data, permissions } = props;

	return (
		<Col>
			<Table className="base-table">
				<Thead>
					<Tr>
						<Th>Nombre</Th>
						<Th>Email</Th>
						<Th>Teléfono</Th>
						<Th>Comuna</Th>
						<Th>Fecha de Registro</Th>
						<Th>Fecha de Expiración</Th>
						<Th>Estado</Th>
						<Th>Acciones</Th>
					</Tr>
				</Thead>
				<Tbody>
					{data.map((item, i) => {
						return (
							<Tr key={i}>
								<Td>{item.name}</Td>
								<Td>{item.email}</Td>
								<Td>{item.phone}</Td>
								<Td>{item.commune && item.commune.name}</Td>
								<Td>{dateToLocale(item.created_at)}</Td>
								<Td>{dateToLocale(item.expiration_date)}</Td>
								<Td style={{ textAlign: "center" }}>
									{item.active ? (
										<button className="btn btn-sm btn-success" disabled>
											Activo
										</button>
									) : (
										<button className="btn btn-sm btn-danger" disabled>
											Inactivo
										</button>
									)}
								</Td>
								<Td>
									<div>
										{isValidPermission("edit", permissions) && (
											<span className="table-actions" onClick={() => navigate("/admin/customers/edit/" + item.slug)}>
												<EditIcon className="icon-edit" />
											</span>
										)}
										{isValidPermission("delete", permissions) && (
											<span className="table-actions icon trash" onClick={() => deleteElement(item)}>
												<DeleteIcon className="icon-delete" />
											</span>
										)}
									</div>
								</Td>
							</Tr>
						);
					})}
				</Tbody>
			</Table>
		</Col>
	);
};

export default CustomerTable;
