import React from "react";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import { ReactComponent as EditIcon } from "assets/icons/editar.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/basura.svg";
import { isValidPermission, dateToLocale } from "services/utils";

import classes from "../../midio.module.scss";
import NoData from "components/list/NoData/NoData";
import { Badge } from "reactstrap";

const CourseTable = (props) => {
	const { editElement, deleteElement, data, permissions } = props;

	if (data && data.length === 0) {
		return <NoData />;
	}

	return (
		<div>
			<Table className={classes.main_table} cellSpacing="0">
				<Thead>
					<Tr>
						<Th>Nombre</Th>
						<Th>Código</Th>
						<Th>Asignatura</Th>
						<Th>Nivel</Th>
						<Th>Fecha de Registro</Th>
						<Th>Estado</Th>
						<Th>{`# Evaluaciones`}</Th>
						<Th>{`# Estudiantes`}</Th>
						<Th>Acciones</Th>
					</Tr>
				</Thead>
				<Tbody>
					{data.map((item, i) => {
						return (
							<Tr key={i} className={classes.tr}>
								<Td className={classes.td}>
									<div className={`${classes.div_td} ${classes.first}`}>{item.name}</div>
								</Td>
								<Td className={classes.td}>
									<div className={classes.div_td}>{item.code}</div>
								</Td>
								<Td className={classes.td} style={{ textAlign: "center" }}>
									<div className={classes.div_td}>{item.subject && item.subject.name}</div>
								</Td>
								<Td className={classes.td} style={{ textAlign: "center" }}>
									<div className={classes.div_td}>{item.level && item.level.name}</div>
								</Td>
								<Td className={classes.td} style={{ textAlign: "center" }}>
									<div className={classes.div_td}>{dateToLocale(item.created_at)}</div>
								</Td>
								<Td className={classes.td} style={{ textAlign: "center" }}>
									<div className={classes.div_td}>
										{!item.finalized_at ? (
											<Badge color="secondary">En Progreso</Badge>
										) : (
											<Badge color="success">Finalizado</Badge>
										)}
									</div>
								</Td>
								<Td className={classes.td} style={{ textAlign: "center" }}>
									<div className={classes.div_td}>{item.evaluations && item.evaluations.length}</div>
								</Td>
								<Td className={classes.td} style={{ textAlign: "center" }}>
									<div className={classes.div_td}>{item.students && item.students.length}</div>
								</Td>
								<Td className={classes.td}>
									<div className={`${classes.div_td} ${classes.icon_div} ${classes.last}`}>
										{isValidPermission("edit", permissions) && (
											<span className="table-actions" onClick={() => editElement(item)}>
												<EditIcon className={`${classes.icon} ${classes.edit}`} />
											</span>
										)}
										{isValidPermission("delete", permissions) && (
											<span className="table-actions icon trash" onClick={() => deleteElement(item)}>
												<DeleteIcon className={`${classes.icon} ${classes.delete}`} />
											</span>
										)}
									</div>
								</Td>
							</Tr>
						);
					})}
				</Tbody>
			</Table>
		</div>
	);
};

export default CourseTable;
