import React from "react";
import { Badge } from "reactstrap";
import { useNavigate } from "react-router-dom";

import classes from "../evaluation.module.scss";

import { ReactComponent as CheckedIcon } from "assets/icons/Evaluation/checked.svg";

const EvaluationReviewStudent = ({ data, setData }) => {
	const navigate = useNavigate();

	return (
		<>
			<div style={{ marginTop: "15px" }}>
				<div className={classes.title_table}>
					<div className={classes.name}>Estudiante</div>
					<div className={classes.review}>P. Revisadas</div>
					<div className={classes.pending}>P. Pendientes</div>
					<div className={classes.correct}>Correctas</div>
					<div className={classes.state}>Estado</div>
					<div className={classes.action}>Acción</div>
				</div>
				<div>
					{data &&
						data.evaluation_user &&
						data.evaluation_user.map((evaluation, i) => {
							return (
								<div key={i} className={classes.div_user}>
									<div className={classes.name}>{evaluation ? evaluation.user_name : ""}</div>
									<div className={classes.review}>{evaluation ? evaluation.quantity_checked : ""}</div>
									<div className={classes.pending}>
										{evaluation ? evaluation.questions.length - evaluation.quantity_checked : ""}
									</div>
									<div className={`${classes.correct} ${classes.value}`}>
										{evaluation ? `${evaluation.quantity_correct} / ${evaluation.questions.length}` : ""}
									</div>
									<div className={classes.state}>
										{!evaluation.teacher_review ? <Badge>En Revisión</Badge> : <Badge color="primary">Revisado</Badge>}
									</div>
									<div className={classes.action}>
										{!evaluation.teacher_review && (
											<CheckedIcon
												onClick={() => navigate(`/evaluations/finish/${data.slug}/${evaluation.user_slug}`)}
												style={{
													width: "2.5em",
													height: "2.5em",
													cursor: "pointer",
												}}
											/>
										)}
									</div>
								</div>
							);
						})}
				</div>
			</div>
		</>
	);
};

export default EvaluationReviewStudent;
