import Fetching from "components/fetching/Fetching";
import React, { useState } from "react";
import { Modal, ModalHeader, ModalFooter, Button } from "reactstrap";

import api from "services/api";

const EvaluationFinishConfirm = ({ data, user, showModal, setShowModal }) => {
	const [loading, setLoading] = useState(false);

	const toggle = () => {
		setShowModal(!showModal);
	};

	const handleFinish = () => {
		setLoading(true);
		api.post("/evaluations/review/question/finish_answer_review", { data, user }).then((response) => {
			setLoading(false);
			window.location.href = "/evaluations/review/" + data.slug;
		});
	};

	return (
		<Modal isOpen={showModal} toggle={toggle} className="modal-body" backdrop="static">
			{loading ? (
				<Fetching />
			) : (
				<>
					<ModalHeader toggle={toggle}>Estas seguro que deseas confirmar la revisión?</ModalHeader>
					<ModalFooter>
						<Button color="secondary" onClick={toggle}>
							Cancelar
						</Button>
						<Button color="primary" onClick={() => handleFinish()}>
							Confirmar
						</Button>
					</ModalFooter>
				</>
			)}
		</Modal>
	);
};

export default EvaluationFinishConfirm;
