import React from "react";
import { useSelector } from "react-redux";
import { Col } from "reactstrap";
import _ from "lodash";

import classes from "./answer.module.scss";

const EvaluationInCourseTime = () => {
	const { seconds } = useSelector((content) => content.Evaluation);

	let second = seconds;
	const hour = _.floor(second / 3600 >= 1 ? second / 3600 : 0);

	second = hour > 0 ? second - hour * 3600 : second;

	const minute = _.floor(second / 60 >= 1 ? second / 60 : 0);

	second = minute > 0 ? second - minute * 60 : second;

	const pad = (num, size) => {
		num = num.toString();
		while (num.length < size) num = "0" + num;
		return num;
	};

	return (
		<Col md={3} sm={6}>
			<div className={classes.globalTime}>{`${pad(hour, 2)}:${pad(
				minute,
				2
			)}:${pad(second, 2)}`}</div>
		</Col>
	);
};

export default EvaluationInCourseTime;
