import React from "react";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import { ReactComponent as EditIcon } from "assets/icons/editar.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/basura.svg";
import { isValidPermission } from "services/utils";

const ScreenOptionTable = (props) => {
	const { editElement, deleteElement, data, permissions } = props;

	return (
		<div>
			<Table className="base-table">
				<Thead>
					<Tr>
						<Th>Etiqueta</Th>
						<Th>Descripción</Th>
						<Th>("Icon")</Th>
						<Th>Acciones</Th>
					</Tr>
				</Thead>
				<Tbody>
					{data.map((item, i) => {
						return (
							<Tr key={i}>
								<Td>{item.label}</Td>
								<Td>{item.description}</Td>
								<Td>{item.icon}</Td>
								<Td>
									<div>
										{isValidPermission("edit", permissions) && (
											<span className="table-actions" onClick={() => editElement(item)}>
												<EditIcon className="icon-edit" />
											</span>
										)}
										{isValidPermission("delete", permissions) && (
											<span className="table-actions icon trash" onClick={() => deleteElement(item)}>
												<DeleteIcon className="icon-delete" />
											</span>
										)}
									</div>
								</Td>
							</Tr>
						);
					})}
				</Tbody>
			</Table>
		</div>
	);
};

export default ScreenOptionTable;
