import React, { Fragment } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { useNavigate } from "react-router-dom";

import Breadcrumb from "../../layout/breadcrumb";
import { ReactComponent as WidgetIcon } from "assets/images/courses/widget.svg";
import { ReactComponent as SpriteIcon } from "assets/images/courses/icon-dash.svg";

import classes from "../app.module.scss";

const Dashboard = () => {
	const navigate = useNavigate();
	const userName = localStorage.getItem("full_name");

	return (
		<Fragment>
			<Breadcrumb title="Principal" />
			<Container fluid={true}>
				<Row>
					<Col sm="6">
						<Card>
							<CardBody className={classes.mainHello}>
								<Row>
									<Col sm="6" style={{ padding: "2em 1em" }}>
										<h4> Hola {userName ? userName : "Usuario"}</h4>
										<p>Aprenda algo nuevo cada día con los mejores cursos.</p>
									</Col>
									<Col sm="6">
										<WidgetIcon style={{ maxWidth: "18em", height: "auto" }} />
									</Col>
								</Row>
							</CardBody>
						</Card>

						<Row>
							<Col sm="6">
								<Card style={{ cursor: "pointer" }} onClick={() => navigate("/my_courses")}>
									<CardBody className={classes.cardBody}>
										<div className={classes.iconSprite}>
											<SpriteIcon className={classes.iconFill} />
										</div>
										<div className={classes.iconText}>
											<div> Ver Cursos</div>
										</div>
									</CardBody>
								</Card>
							</Col>
							<Col sm="6">
								<Card style={{ cursor: "pointer" }} onClick={() => navigate("/evaluations")}>
									<CardBody className={classes.cardBody}>
										<div className={`${classes.iconSprite} ${classes.warning}`}>
											<SpriteIcon className={classes.iconFill} />
										</div>
										<div className={classes.iconText}>
											<div> Ver Evaluaciones</div>
										</div>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};

export default Dashboard;
