import React, { useState, useEffect, useCallback, Fragment, useMemo } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { toast } from "react-toastify";
import debounce from "lodash/debounce";

import Breadcrumb from "layout/breadcrumb";
import Paginator from "components/list/Paginator";
import Search from "components/list/Search";
import ScreenTable from "./table/ScreenTable";
import Fetching from "components/fetching/Fetching";
import ScreenForm from "./form/ScreenForm";
import Confirm from "components/confirm/Confirm";

import api from "services/api";

const Screen = (props) => {
	const pageName = "Screens";
	const [isFetching, setIsFetching] = useState(true);
	const [search, setSearch] = useState("");
	const [mainSearch, setMainSearch] = useState("");
	const [page, setPage] = useState(1);
	const [data, setData] = useState([]);
	const [permissions, setPermissions] = useState([]);
	const [from, setFrom] = useState("");
	const [to, setTo] = useState("");
	const [total, setTotal] = useState("");
	const [lastPage, setLastPage] = useState("");

	const [selected, setSelected] = useState("");
	const [form, setForm] = useState(false);
	const [modal, setModal] = useState(false);

	useEffect(() => {
		api.get("/admin/screens", {
			page,
			search,
		}).then((response) => {
			updateData(response);
		});
	}, [page, search]);

	const updateData = (response) => {
		const { screens, permissions } = response;

		setPermissions(permissions);
		setData(screens.data);
		setFrom(screens.from);
		setTo(screens.to);
		setTotal(screens.total);
		setLastPage(screens.last_page);
		setIsFetching(false);
	};

	const debouncedSetSearch = useMemo(
		() =>
			debounce((v) => {
				setSearch(v);
			}, 500),
		[setSearch]
	);

	const delayedQuery = useCallback(
		(...args) => {
			debouncedSetSearch(...args);
		},
		[debouncedSetSearch]
	);

	const setDelaySearch = (value) => {
		setMainSearch(value);
		delayedQuery(value);
	};

	// Form
	const createElement = () => {
		setSelected("");
		setForm(true);
	};

	const editElement = (item) => {
		setSelected(item.id);
		setForm(true);
	};

	const handleEdit = (data) => {
		api.post("/admin/screens_save", data).then((response) => {
			updateData(response);
			setForm(false);
			setSelected("");

			toast.success("Registro guardado correctamente");
		});
	};

	const deleteElement = (item) => {
		setSelected(item.id);
		setModal(true);
	};

	const handleDelete = () => {
		const form = {
			selected,
		};

		api.post("/admin/screens_delete", form).then((response) => {
			updateData(response);
			setSelected("");

			toast.success("Registro eliminado correctamente");
		});
	};

	return (
		<Fragment>
			<Breadcrumb title={"Pantallas"} parent="Mantenedores" />
			<Container fluid={true}>
				<Row>
					<Search
						search={mainSearch}
						setSearch={setDelaySearch}
						to={to}
						from={from}
						total={total}
						permissions={permissions}
						handleCreate={createElement}
					/>

					<Col sm="12">
						<Card>
							<CardBody>
								{isFetching ? (
									<Fetching />
								) : (
									<ScreenTable editElement={editElement} deleteElement={deleteElement} data={data} permissions={permissions} />
								)}
							</CardBody>
						</Card>
					</Col>

					<Paginator page={page} setPage={setPage} lastPage={lastPage} total={total} />
				</Row>
			</Container>

			<ScreenForm pageName={pageName} selected={selected} form={form} setForm={setForm} callbackAction={handleEdit} />

			<Confirm modal={modal} setModal={setModal} callbackAction={handleDelete} />
		</Fragment>
	);
};

export default Screen;
