import React from "react";

import { useNavigate } from "react-router-dom";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import { ReactComponent as EditIcon } from "assets/icons/editar.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/basura.svg";
import { isValidPermission, dateToLocale } from "services/utils";
import { Badge } from "reactstrap";

const UserTable = (props) => {
	let navigate = useNavigate();

	const user_id = localStorage.getItem("user_id");

	const { deleteElement, data, permissions } = props;

	return (
		<div>
			<Table className="base-table">
				<Thead>
					<Tr>
						<Th>Nombre</Th>
						<Th>Email</Th>
						<Th>Roles</Th>
						<Th>Comuna</Th>
						{Number(user_id) === 1 && <Th>Cliente</Th>}
						<Th>Fecha de Registro</Th>
						<Th>Acciones</Th>
					</Tr>
				</Thead>
				<Tbody>
					{data.map((item, i) => {
						return (
							<Tr key={i}>
								<Td>{item.name}</Td>
								<Td>{item.email}</Td>
								<Td>
									{item &&
										item.roles &&
										item.roles.map((role, i) => {
											return (
												<Badge key={i} color="secondary" style={{ fontWeight: "500" }}>
													{role.description}
												</Badge>
											);
										})}
								</Td>
								<Td>{item.commune && item.commune.name}</Td>
								{Number(user_id) === 1 && <Td>{item.customer && item.customer.name}</Td>}
								<Td>{dateToLocale(item.created_at)}</Td>
								<Td>
									<div>
										{isValidPermission("edit", permissions) && (
											<span className="table-actions" onClick={() => navigate("/admin/users/edit/" + item.id)}>
												<EditIcon className="icon-edit" />
											</span>
										)}
										{isValidPermission("delete", permissions) && (
											<span className="table-actions icon trash" onClick={() => deleteElement(item)}>
												<DeleteIcon className="icon-delete" />
											</span>
										)}
									</div>
								</Td>
							</Tr>
						);
					})}
				</Tbody>
			</Table>
		</div>
	);
};

export default UserTable;
