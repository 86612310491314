import React from "react";

import { Button, Modal, ModalHeader, ModalFooter } from "reactstrap";

const Confirm = (props) => {
	const { modal, setModal, callbackAction } = props;

	const toggle = () => setModal(!modal);

	const applyAction = () => {
		callbackAction();
		setModal(false);
	};

	return (
		<Modal isOpen={modal} toggle={toggle}>
			<ModalHeader toggle={toggle} style={{ fontSize: "1rem" }}>
				¿Está seguro de que desea realizar esta acción?
			</ModalHeader>
			<ModalFooter>
				<Button color="secondary" onClick={toggle}>
					Cancelar
				</Button>
				<Button color="primary" onClick={applyAction}>
					Aceptar
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default Confirm;
