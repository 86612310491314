import React, { useEffect, useState, useRef } from "react";
import { Button } from "reactstrap";

import classes from "./option.module.scss";

const Option = ({ options }) => {
	const [open, setOpen] = useState(false);

	const refButton = useRef(null);
	const refDiv = useRef(null);

	useEffect(() => {
		document.addEventListener("click", handleClickOutside, true);
	}, []);

	const handleClickOutside = (event) => {
		if (refButton.current && !refButton.current.contains(event.target) && refDiv.current && !refDiv.current.contains(event.target)) {
			setOpen(false);
		}
	};

	return (
		<div className={classes.optionDiv}>
			<div onClick={() => setOpen(!open)} ref={refButton}>
				<Button color="primary" size="xs" className={classes.optionButton}>
					Acciones
					<span>
						<i className="icofont icofont-arrow-down"></i>
					</span>
				</Button>
			</div>

			<div className={`${classes.optionMenu} ${open && classes.show}`} ref={refDiv}>
				<div className={classes.optionTitle}>Acciones</div>
				<hr className={classes.optionHr} />
				<div className={classes.optionsItems}>
					{options.map((option, index) => (
						<Button
							key={index}
							color="link"
							className={classes.optionItem}
							onClick={() => {
								option.action();
								setOpen(false);
							}}
						>
							<span className={classes.icon}>
								<i className={`icofont ${option.icon}`}></i>
							</span>
							{option.label}
						</Button>
					))}
				</div>
			</div>
		</div>
	);
};

export default Option;
