export const base_filters = [
	{
		key: "filter_subject_id",
		name: "Asignatura",
		value: "",
		type: "select",
		options: [],
	},
	{
		key: "filter_level_id",
		name: "Nivel de Pregunta",
		value: "",
		type: "select",
		options: [],
		filter_by: "filter_subject_id",
	},
	{
		key: "filter_category_id",
		name: "Categoría de Pregunta",
		value: "",
		type: "select",
		options: [],
		filter_by: "filter_level_id",
	},
	{
		key: "filter_content_id",
		name: "Contenido de Pregunta",
		value: "",
		type: "select",
		options: [],
		filter_by: "filter_category_id",
	},
	{
		key: "filter_name",
		name: "Nombre",
		value: "",
		type: "text",
		options: [],
	},
];
