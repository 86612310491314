import React, { useState, useEffect, useCallback, Fragment, useMemo, useRef } from "react";
import { Container, Row, Col } from "reactstrap";
import debounce from "lodash/debounce";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import fileDownload from "js-file-download";

import Breadcrumb from "layout/breadcrumb";
import Paginator from "components/list/Paginator";
import Search from "components/list/Search";
import StudentTable from "./table/StudentTable";
import Fetching from "components/fetching/Fetching";
import Confirm from "components/confirm/Confirm";
import BaseFilter from "components/filter/BaseFilter";

import api from "services/api";
import { getToday } from "services/utils";
import { base_filters } from "./student.constants";

import { HANDLE_GLOBAL_FETCHING } from "../../../redux/actionTypes";

const Student = () => {
	const dispatch = useDispatch();
	let navigate = useNavigate();

	const fileRef = useRef(null);

	const [isFetching, setIsFetching] = useState(true);
	const [search, setSearch] = useState("");
	const [mainSearch, setMainSearch] = useState("");
	const [page, setPage] = useState(1);
	const [data, setData] = useState([]);
	const [permissions, setPermissions] = useState([]);
	const [from, setFrom] = useState("");
	const [to, setTo] = useState("");
	const [total, setTotal] = useState("");
	const [lastPage, setLastPage] = useState("");
	const [showFilter, setShowFilter] = useState(false);

	const [selected, setSelected] = useState("");
	const [modal, setModal] = useState(false);

	const [filters, setFilters] = useState([...base_filters]);

	useEffect(() => {
		const reduceFilters = filters.reduce((obj, item) => {
			obj[item.key] = item.value;
			return obj;
		}, {});

		setIsFetching(true);
		api.get("/students", {
			page,
			search,
			...reduceFilters,
		}).then((response) => {
			updateData(response);
		});
		// eslint-disable-next-line
	}, [page, search, filters.map((f) => f.value).join("|")]);

	const updateData = (response) => {
		const { students, permissions, courses } = response;

		setPermissions(permissions);
		setData(students.data);
		setFrom(students.from);
		setTo(students.to);
		setTotal(students.total);
		setLastPage(students.last_page);

		updateFilters(courses);

		setIsFetching(false);
	};

	const updateFilters = (courses) => {
		let newFilters = [...filters];

		let courseKey = newFilters.findIndex((item) => item.key === "filter_course_id");
		newFilters[courseKey].options = courses;

		setFilters([...newFilters]);

		setIsFetching(false);
	};

	const debouncedSetSearch = useMemo(
		() =>
			debounce((v) => {
				setSearch(v);
			}, 500),
		[setSearch]
	);

	const delayedQuery = useCallback(
		(...args) => {
			debouncedSetSearch(...args);
		},
		[debouncedSetSearch]
	);

	const setDelaySearch = (value) => {
		setMainSearch(value);
		delayedQuery(value);
	};

	// Form
	const createElement = () => {
		navigate("/students/create");
	};

	const editElement = (item) => {
		navigate(`/students/edit/${item.slug}`);
	};

	const deleteElement = (item) => {
		setSelected(item.id);
		setModal(true);
	};

	const handleDelete = () => {
		const form = {
			selected,
		};

		setIsFetching(true);
		api.post("/students_delete", form).then((response) => {
			updateData(response);
			setSelected("");
			setIsFetching(false);

			toast.success("Registro eliminado correctamente");
		});
	};

	const applyFilter = (newFilters) => {
		setShowFilter(false);
		setFilters([...newFilters]);
	};

	const handleExcel = () => {
		const filename = `Estudiantes ${getToday()}.xlsx`;

		const reduceFilters = filters.reduce((obj, item) => {
			obj[item.key] = item.value;
			return obj;
		}, {});

		dispatch({
			type: HANDLE_GLOBAL_FETCHING,
			payload: {
				isGlobalFetching: true,
				documentName: filename,
			},
		});

		api.getExcel("/students/excel", { ...reduceFilters }).then((data) => {
			fileDownload(data, filename);

			dispatch({
				type: HANDLE_GLOBAL_FETCHING,
				payload: {
					isGlobalFetching: false,
					documentName: "",
				},
			});
		});
	};

	const handleExcelImport = () => {
		fileRef.current.click();
	};

	const confirmImport = (e) => {
		const reduceFilters = filters.reduce((obj, item) => {
			obj[item.key] = item.value;
			return obj;
		}, {});

		const file = e.target.files[0];

		if (file) {
			const formData = new FormData();

			Object.entries(reduceFilters).forEach(([key, value]) => {
				formData.append(key, value);
			});

			formData.append("page", page);
			formData.append("search", search);
			formData.append("list", 1);
			formData.append("file", file);

			setIsFetching(true);
			api.post("/students/excel/import", formData)
				.then((response) => {
					setIsFetching(false);
					updateData(response);
					toast.success("Estudiantes importados correctamente");

					fileRef.current.value = null;
				})
				.catch(() => {
					fileRef.current.value = null;
					setIsFetching(false);
				});
		}
	};

	const actions = [
		{
			key: "create",
			label: "Crear",
			icon: "AddIcon",
			function: createElement,
		},
		{
			key: "export",
			label: "Exportar",
			icon: "ExportIcon",
			function: handleExcel,
		},
		{
			key: "import",
			label: "Importar",
			icon: "ImportIcon",
			function: handleExcelImport,
			next_function: confirmImport,
		},
	];

	return (
		<Fragment>
			<Breadcrumb title={"Estudiantes"} parent="Midio" />
			<Container fluid={true}>
				<Row>
					<Search
						search={mainSearch}
						setSearch={setDelaySearch}
						to={to}
						from={from}
						total={total}
						permissions={permissions}
						haveFilter={true}
						showFilter={showFilter}
						setShowFilter={setShowFilter}
						listActions={actions}
					/>

					<input ref={fileRef} type="file" name="file" style={{ display: "none" }} onChange={(e) => confirmImport(e)} />

					{showFilter && <BaseFilter filters={filters} applyFilter={applyFilter} />}

					<Col sm={showFilter ? "9" : "12"} style={{ paddingRight: "0px", paddingLeft: "0px" }}>
						{isFetching ? (
							<Fetching />
						) : (
							<StudentTable editElement={editElement} deleteElement={deleteElement} data={data} permissions={permissions} />
						)}
					</Col>

					<Paginator page={page} setPage={setPage} lastPage={lastPage} total={total} />
				</Row>
			</Container>

			<Confirm modal={modal} setModal={setModal} callbackAction={handleDelete} />
		</Fragment>
	);
};

export default Student;
