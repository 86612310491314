import React, { useState, useEffect } from "react";
import { Col, Label, Input } from "reactstrap";
import Select from "react-select";

const CustomerFields = ({ data, setData, countries, allCommunes }) => {
	const [communes, setCommunes] = useState([]);

	useEffect(() => {
		if (data.country_id) {
			setCommunes(allCommunes.filter((c) => Number(c.country_id) === Number(data.country_id)));
		}
	}, [data.country_id, allCommunes]);

	const handleCountry = (value) => {
		const filter = allCommunes.filter((c) => Number(c.country_id) === Number(value.value));
		setData({
			...data,
			country_id: value.value,
		});
		setCommunes(filter);
	};

	const handleCommune = (value) => {
		setData({
			...data,
			commune_id: value.value,
		});
	};

	return (
		<>
			<div className="row" style={{ marginTop: "10px" }}>
				<Col md="6 mb-2">
					<Label htmlFor="name">Nombre</Label>
					<Input
						className="form-control"
						name="name"
						type="text"
						value={data && data.name}
						onChange={(e) =>
							setData({
								...data,
								name: e.target.value,
							})
						}
					/>
				</Col>
				<Col md="6 mb-2">
					<Label htmlFor="email">Email</Label>
					<Input
						className="form-control"
						name="email"
						type="text"
						value={data && data.email}
						onChange={(e) =>
							setData({
								...data,
								email: e.target.value,
							})
						}
					/>
				</Col>
			</div>

			<div className="row">
				<Col md="6 mb-2">
					<Label htmlFor="phone">Celular</Label>
					<Input
						className="form-control"
						name="phone"
						type="text"
						value={data && data.phone}
						onChange={(e) =>
							setData({
								...data,
								phone: e.target.value,
							})
						}
					/>
				</Col>

				<Col md="6 mb-2">
					<Label htmlFor="number">Teléfono</Label>
					<Input
						className="form-control"
						name="number"
						type="text"
						value={data && data.number}
						onChange={(e) =>
							setData({
								...data,
								number: e.target.value,
							})
						}
					/>
				</Col>
			</div>

			<div className="row">
				<Col md="6 mb-2">
					<Label htmlFor="country_id">País</Label>

					<Select
						defaultValue={data.country_id ? countries.filter((c) => Number(c.value) === Number(data.country_id)) : ""}
						placeholder={<div>Seleccione......</div>}
						options={countries}
						onChange={(value) => handleCountry(value)}
					/>
				</Col>

				<Col md="6 mb-2">
					<Label htmlFor="commune_id">Comuna</Label>
					<Select
						defaultValue={data.commune_id ? allCommunes.filter((c) => Number(c.value) === Number(data.commune_id)) : ""}
						placeholder={<div>Seleccione......</div>}
						options={communes}
						onChange={(value) => handleCommune(value)}
					/>
				</Col>
			</div>

			<div className="row">
				<Col md="12 mb-3">
					<Label htmlFor="address">Dirección</Label>
					<Input
						className="form-control"
						name="address"
						type="text"
						value={data && data.address}
						onChange={(e) =>
							setData({
								...data,
								address: e.target.value,
							})
						}
					/>
				</Col>
			</div>

			<div className="row">
				<Col md="6 mb-2">
					<Label htmlFor="expiration_date">Fecha de Expiración</Label>
					<Input
						className="form-control"
						name="expiration_date"
						type="date"
						value={data && data.expiration_date}
						onChange={(e) =>
							setData({
								...data,
								expiration_date: e.target.value,
							})
						}
					/>
				</Col>
			</div>
		</>
	);
};

export default CustomerFields;
