import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import LayoutApp from "./components/app";
import store from "./store";

import interceptor from "./services/api.interceptor";
import { isValidToken } from "./services/utils";

import Login from "./page/authentication/Login";

import { routes } from "./route";

const App = (props) => {
	interceptor.setupInterceptors(store, props);

	const token = isValidToken() ? localStorage.getItem("token") : "";

	if (!token) {
		localStorage.removeItem("token");
		localStorage.removeItem("full_name");
		localStorage.removeItem("expiration_date");
		localStorage.removeItem("customer");
		localStorage.removeItem("screens");
		localStorage.removeItem("roles");
	}

	return (
		<Provider store={store}>
			<Router>
				<Routes>
					<Route path={`/login`} element={<Login />} />

					<Route path="/" element={<LayoutApp />}>
						{routes.map(({ path, Component }) => {
							return <Route key={path} path={path} element={<Component />} />;
						})}
					</Route>
				</Routes>
			</Router>
		</Provider>
	);
};

export default App;
