import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Container, Label } from "reactstrap";
import Select from "react-select";

import api from "services/api";
import Fetching from "components/fetching/Fetching";

const EvaluationReviewQuestionCreate = ({ data, setData, openModal, setOpenModal, typesQuestion }) => {
	const [questionType, setQuestionType] = useState("");
	const [isFetching, setIsFetching] = useState(false);

	const toggle = () => {
		setQuestionType("");
		setOpenModal(!openModal);
	};

	const handleCreate = () => {
		setIsFetching(true);
		api.post("/evaluations/review/question/create", { evaluationID: data.id, questionType: questionType })
			.then((response) => {
				let newData = { ...data };
				if (response.question) {
					newData.questions.push(response.question);
					setData(newData);
				}
				setIsFetching(false);

				toggle();
			})
			.catch(() => {
				setIsFetching(false);
			});
	};

	return (
		<Modal isOpen={openModal} toggle={toggle} className="modal-body" centered={true} backdrop="static">
			<ModalHeader toggle={toggle}>Agregar nueva pregunta</ModalHeader>
			<ModalBody>
				{isFetching ? (
					<Fetching />
				) : (
					<Container fluid={true} className="bd-example-row">
						<Label htmlFor="type_question">Selecciona el tipo de pregunta</Label>
						<Select
							value={questionType ? typesQuestion.filter((f) => Number(f.value) === Number(questionType)) : ""}
							placeholder={<div>Seleccione......</div>}
							options={typesQuestion}
							onChange={(value) => setQuestionType(value.value)}
							onKeyDown={(e) => {
								if (e.key === "Enter") {
									handleCreate();
								}
							}}
						/>
					</Container>
				)}
			</ModalBody>
			<ModalFooter>
				<Button disabled={isFetching ? true : false} color="secondary" onClick={toggle}>
					Cancelar
				</Button>
				<Button disabled={isFetching ? true : false} color="primary" onClick={() => handleCreate()}>
					Agregar
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default EvaluationReviewQuestionCreate;
