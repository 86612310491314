import React from "react";

import { Container, Row, Col, Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Home } from "react-feather";
import { Link, useNavigate } from "react-router-dom";

import { ReactComponent as BackIcon } from "assets/icons/back.svg";

const Breadcrumbs = (props) => {
	let navigate = useNavigate();

	return (
		<Container fluid={true}>
			<div className="page-title" style={props.style}>
				<Row>
					<Col xs="6">
						{props.parent !== "Midio" && props.title !== "Dashboard" && (
							<BackIcon
								onClick={() => navigate(props.parentUrl ? props.parentUrl : -1)}
								style={{
									width: "1.5em",
									height: "1.5em",
									marginRight: "10px",
									marginTop: "3px",
									cursor: "pointer",
								}}
							/>
						)}

						<h3 style={{ color: props.color_title ? props.color_title : "black" }}>{props.title}</h3>
					</Col>
					<Col xs="6">
						<Breadcrumb>
							<BreadcrumbItem>
								<Link to={`/`}>
									<Home />
								</Link>
							</BreadcrumbItem>
							{props.parent && <BreadcrumbItem>{props.parent}</BreadcrumbItem>}
							<BreadcrumbItem active>{props.title}</BreadcrumbItem>
						</Breadcrumb>
					</Col>
				</Row>
			</div>
		</Container>
	);
};

export default Breadcrumbs;
