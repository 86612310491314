import React from "react";
import { Spinner } from "reactstrap";

import { ReactComponent as CreateSvg } from "assets/icons/Create.svg";

const EvaluationStepCreate = ({ data }) => {
	return (
		<div>
			<div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
				<CreateSvg style={{ width: "50%" }} />
			</div>
			<div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "10px" }}>
				<Spinner animation="border" variant="success" />
			</div>
			<div style={{ display: "flex", justifyContent: "center", alignItems: "center", fontSize: "20px" }}>
				Estamos creando tu evaluación, por favor espera un momento...
			</div>
		</div>
	);
};

export default EvaluationStepCreate;
