import React, { useMemo, useState, useCallback } from "react";

import { useDropzone } from "react-dropzone";
import ImageViewer from "react-simple-image-viewer";

import { baseStyle, activeStyle, acceptStyle, rejectStyle, thumb, thumbInner, Img, thumbsContainer } from "./uploadFile.style";

const UploadFile = ({ files, setFiles }) => {
	const [currentImage, setCurrentImage] = useState(0);
	const [isViewerOpen, setIsViewerOpen] = useState(false);

	const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
		accept: "image/*",
		onDrop: (acceptedFiles) => {
			setFiles(
				acceptedFiles.map((file) => {
					return Object.assign(file, {
						preview: URL.createObjectURL(file),
					});
				})
			);
		},
	});

	const style = useMemo(
		() => ({
			...baseStyle,
			...(isDragActive ? activeStyle : {}),
			...(isDragAccept ? acceptStyle : {}),
			...(isDragReject ? rejectStyle : {}),
		}),
		[isDragActive, isDragReject, isDragAccept]
	);

	const thumbs = files.map((file, i) => (
		<div style={thumb} key={i}>
			<div style={thumbInner}>
				<img alt={file.name} src={file.preview} style={Img} onClick={() => openImageViewer(i)} />
			</div>
		</div>
	));

	let images = [];
	files.forEach((element) => {
		images.push(element.preview);
	});

	const openImageViewer = useCallback((index) => {
		setCurrentImage(index);
		setIsViewerOpen(true);
	}, []);

	const closeImageViewer = () => {
		setCurrentImage(0);
		setIsViewerOpen(false);
	};

	return (
		<section className="container">
			<div
				{...getRootProps({
					style,
					className: "dropzone",
				})}
			>
				<input {...getInputProps()} />
				<p style={{ marginBottom: "0rem" }}>Arrastre y suelte algunos archivos aquí, o haga clic para seleccionar archivos</p>
			</div>
			<aside style={thumbsContainer}>{thumbs}</aside>

			{isViewerOpen && (
				<ImageViewer
					src={images}
					currentIndex={currentImage}
					onClose={closeImageViewer}
					disableScroll={false}
					backgroundStyle={{
						backgroundColor: "rgba(0,0,0,0.9)",
					}}
					closeOnClickOutside={true}
				/>
			)}
		</section>
	);
};

export default UploadFile;
