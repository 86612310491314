import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Col, Label, Input } from "reactstrap";
import Select from "react-select";
import { toast } from "react-toastify";

import Fetching from "components/fetching/Fetching";

import api from "services/api";

const baseObject = {
	id: "",
	name: "",
	description: "",
	subject_id: "",
	question_level_id: "",
	question_category_id: "",
};

const QuestionObjetivesForm = (props) => {
	let navigate = useNavigate();
	const { option, slug } = useParams();

	const [isFetching, setIsFetching] = useState(true);
	const [data, setData] = useState({ ...baseObject });

	const { pageName, subjects, questionLevels, callbackAction } = props;

	const handleClose = () => {
		setData({ ...baseObject });
		navigate("/admin/question_objectives");
	};

	const handleSave = async () => {
		if (validSave()) {
			setIsFetching(true);
			if (await callbackAction(data)) {
				handleClose();
				setIsFetching(false);
			} else {
				setIsFetching(false);
			}
		}
	};

	const validSave = () => {
		if (!data.name || data.name === "") {
			toast.error("El campo nombre es obligatorio");
			return false;
		}
		if (!data.subject_id || data.subject_id === "") {
			toast.error("El campo asignatura es obligatorio");
			return false;
		}
		if (!data.question_level_id || data.question_level_id === "") {
			toast.error("El campo nivel de pregunta es obligatorio");
			return false;
		}

		return true;
	};

	const handleSubject = (value) => {
		setData({
			...data,
			subject_id: value.value,
		});
	};

	const handleQuestionLevel = (value) => {
		setData({
			...data,
			question_level_id: value.value,
		});
	};

	useEffect(() => {
		setIsFetching(false);
		if (slug) {
			setIsFetching(true);
			api.get("/admin/question_objective", { selected: slug }).then((response) => {
				setData(response.question_objective);
				setIsFetching(false);
			});
		} else {
			setData(baseObject);
		}
	}, [slug]);

	return (
		<Modal isOpen={option ? true : false} toggle={handleClose} size="lg" backdrop="static">
			{isFetching ? (
				<Fetching />
			) : (
				<>
					<ModalHeader toggle={handleClose}>{pageName}</ModalHeader>
					<ModalBody>
						<div className="row">
							<Col md="12 mb-3">
								<Label htmlFor="name">Nombre</Label>
								<Input
									className="form-control"
									name="name"
									type="text"
									value={data.name}
									onChange={(e) =>
										setData({
											...data,
											name: e.target.value,
										})
									}
								/>
							</Col>
							<Col md="12 mb-3">
								<Label htmlFor="description">Descripción</Label>
								<Input
									className="form-control"
									name="description"
									type="textarea"
									rows="5"
									value={data.description}
									onChange={(e) =>
										setData({
											...data,
											description: e.target.value,
										})
									}
								/>
							</Col>
							<Col md="6 mb-2">
								<Label htmlFor="name">Asignatura</Label>
								<Select
									value={data.subject_id ? subjects.filter((c) => String(c.value) === String(data.subject_id)) : ""}
									placeholder={<div>Seleccione......</div>}
									options={subjects}
									onChange={(value) => handleSubject(value)}
								/>
							</Col>
							<Col md="6 mb-2">
								<Label htmlFor="name">Nivel de Pregunta</Label>
								<Select
									value={
										data.question_level_id
											? questionLevels.filter((ql) => String(ql.value) === String(data.question_level_id))
											: ""
									}
									placeholder={<div>Seleccione......</div>}
									options={data.subject_id ? questionLevels.filter((ql) => String(ql.subject_id) === String(data.subject_id)) : []}
									onChange={(value) => handleQuestionLevel(value)}
								/>
							</Col>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button color="secondary" onClick={handleClose}>
							Cancelar
						</Button>
						<Button color="primary" onClick={() => handleSave()}>
							Guardar
						</Button>
					</ModalFooter>
				</>
			)}
		</Modal>
	);
};

export default QuestionObjetivesForm;
