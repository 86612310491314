import React from "react";
import { Col, Label, Input, Button } from "reactstrap";
import Select from "react-select";
import { toast } from "react-toastify";

import { ReactComponent as CreateSvg } from "assets/icons/create_evaluation.svg";
import { integerValidate } from "services/utils";

const EvaluationStepTitle = ({ data, setData, handleSave, isSave }) => {
	const handleValid = () => {
		if (!data.title) {
			toast.error("El campo título es obligatorio");
			return false;
		}
		if (!data.quantity_questions) {
			toast.error("El campo cantidad de preguntas es obligatorio");
			return false;
		}
		if (data.quantity_questions && data.quantity_questions > 40) {
			toast.error("El campo cantidad de preguntas no puede ser mayor a 40");
			return false;
		}
		handleSave(2);
	};

	return (
		<div>
			<div style={{ display: "flex" }}>
				<CreateSvg style={{ width: "50%" }} />
				<div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
					<h4>
						<span style={{ color: "#00a58d" }}>Hola</span>, vamos a crear una nueva evaluación
					</h4>
					<small>
						Guardaremos un borrador de la evaluación y cuando te sientas listo, podrás confirmarlo. En ese momento, la evaluación estará
						preparada para ser realizada por los estudiantes.
					</small>
				</div>
			</div>

			{window.location && window.location.pathname.includes("evaluations") && (
				<div className="row">
					<Col md="12 mb-3">
						<Label style={{ fontSize: "16px" }} htmlFor="title">
							A que curso pertenece esta evaluación?
						</Label>
						<Select
							value={data.courseId ? data.courses.filter((c) => Number(c.value) === Number(data.courseId)) : {}}
							placeholder={<div>Seleccione......</div>}
							options={data.courses}
							onChange={(value) =>
								setData({
									...data,
									courseId: value.value,
									subject_id: value.subject_id,
									level_id: value.level_id,
								})
							}
						/>
					</Col>
				</div>
			)}

			<div className="row">
				<Col md="12 mb-3">
					<Label style={{ fontSize: "16px" }} htmlFor="title">
						Como vamos la vamos a identificar?
					</Label>
					<Input
						className="form-control"
						name="title"
						type="text"
						value={data ? data.title : ""}
						placeholder="Ej: Evaluación Diagnóstica"
						onChange={(e) =>
							setData({
								...data,
								title: e.target.value,
							})
						}
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								handleValid();
							}
						}}
					/>
				</Col>

				<Col md="12 mb-3">
					<Label style={{ fontSize: "16px" }} htmlFor="quantity_questions">
						Cuantas preguntas tendrá?
					</Label>
					<Input
						className="form-control"
						name="quantity_questions"
						type="text"
						value={data ? data.quantity_questions : ""}
						onChange={(e) =>
							setData({
								...data,
								quantity_questions: e.target.value,
							})
						}
						onKeyDown={(e) => {
							integerValidate(e);
							if (e.key === "Enter") {
								handleValid();
							}
						}}
					/>
				</Col>
			</div>

			<div style={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
				<Button disabled={isSave} color="primary" style={{ padding: "10px 30px" }} onClick={() => handleValid()}>
					{isSave ? "Guardando..." : "Siguiente"}
				</Button>
			</div>
		</div>
	);
};

export default EvaluationStepTitle;
