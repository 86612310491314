import React from "react";
import { toast } from "react-toastify";
import { UncontrolledTooltip } from "reactstrap";

import classes from "../evaluation.module.scss";

import { ReactComponent as MagicIcon } from "assets/icons/magic.svg";
import { ReactComponent as ConfirmIcon } from "assets/icons/confirm.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/close.svg";

import api from "services/api";

const EvaluationReviewQuestionDetailAction = ({ question, data, setData, setLoading, divReview }) => {
	const handleConfirm = () => {
		setLoading(true);
		api.post("/evaluations/review/question/confirm", { questionID: question.id, evaluationID: data.id }).then((response) => {
			let newData = { ...data };
			newData.questions = newData.questions.map((responseQuestion) => {
				if (responseQuestion.id === question.id) {
					responseQuestion.pivot.confirm = 1;
				}
				return responseQuestion;
			});
			setData(newData);

			toast.success(response.message);
			setLoading(false);

			if (newData.questions.filter((question) => question.pivot.confirm === 1).length === newData.questions.length) {
				divReview.current.scrollIntoView({ behavior: "smooth" });
			}
		});
	};

	const handleReplace = () => {
		setLoading(true);
		api.post("/evaluations/review/question/replace", { questionID: question.id, evaluationID: data.id })
			.then((response) => {
				let newData = { ...data };
				newData.questions = newData.questions.map((responseQuestion) => {
					if (responseQuestion.id === question.id) {
						return response.question;
					}
					return responseQuestion;
				});
				setData(newData);

				toast.success(response.message);
				setLoading(false);
			})
			.catch(() => {
				setLoading(false);
			});
	};

	const handleDelete = () => {
		setLoading(true);
		api.post("/evaluations/review/question/delete", { questionID: question.id, evaluationID: data.id })
			.then((response) => {
				let newData = { ...data };
				newData.questions = newData.questions.filter((responseQuestion) => responseQuestion.id !== question.id);
				setData(newData);

				toast.success(response.message);
				setLoading(false);

				if (newData.questions.filter((question) => question.pivot.confirm === 1).length === newData.questions.length) {
					divReview.current.scrollIntoView({ behavior: "smooth" });
				}
			})
			.catch(() => {
				setLoading(false);
			});
	};

	return (
		<>
			{!question.pivot.confirm && question.ia_question && (
				<div>
					<UncontrolledTooltip autohide={true} placement="left" target="MagicIcon">
						Remplazar pregunta
					</UncontrolledTooltip>
					<MagicIcon
						onClick={() => (!question.pivot.confirm ? handleReplace() : null)}
						className={`${classes.icon_option} ${classes.refresh}`}
						id="MagicIcon"
					/>
				</div>
			)}

			<div>
				<UncontrolledTooltip autohide={true} placement="left" target="ConfirmIcon">
					Confirmar
				</UncontrolledTooltip>
				<ConfirmIcon
					onClick={() => (!question.pivot.confirm ? handleConfirm() : null)}
					className={`${classes.icon_option} ${classes.confirm} ${question.pivot.confirm && classes.active}`}
					id="ConfirmIcon"
				/>
			</div>

			{!question.pivot.confirm && (
				<div>
					<UncontrolledTooltip autohide={true} placement="left" target="DeleteIcon">
						Eliminar pregunta
					</UncontrolledTooltip>
					<DeleteIcon
						onClick={() => (!question.pivot.confirm ? handleDelete() : null)}
						className={`${classes.icon_option} ${classes.delete}`}
						id="DeleteIcon"
					/>
				</div>
			)}
		</>
	);
};

export default EvaluationReviewQuestionDetailAction;
