import React from "react";

import { Row, Col, Pagination, PaginationItem, PaginationLink } from "reactstrap";

const Paginator = (props) => {
	const { page, setPage, lastPage, total } = props;

	let data = [];
	for (let index = 0; index < Math.ceil(total / 15); index++) {
		data.push(index + 1);
	}
	if (data.length > 9) {
		data = data.filter((element) => element >= page - 5).slice(0, 9);
	}

	return (
		<Col sm="12" className="base-paginator">
			<Row>
				<Col sm="3 offset-md-9">
					<Pagination className="pagination-primary pagination-right">
						{page !== 1 && (
							<PaginationItem>
								<PaginationLink onClick={() => setPage(page - 1)}>
									<span aria-hidden="true">«</span>
									<span className="sr-only"></span>
								</PaginationLink>
							</PaginationItem>
						)}

						{data.map((data_page, i) => {
							return (
								<PaginationItem key={i} active={data_page === page ? true : false}>
									<PaginationLink onClick={() => setPage(data_page)}>{data_page}</PaginationLink>
								</PaginationItem>
							);
						})}
						{lastPage > page && (
							<PaginationItem>
								<PaginationLink onClick={() => setPage(page + 1)}>
									<span aria-hidden="true">»</span>
									<span className="sr-only"></span>
								</PaginationLink>
							</PaginationItem>
						)}
					</Pagination>
				</Col>
			</Row>
		</Col>
	);
};

export default Paginator;
