import React, { useEffect, useState, Fragment } from "react";

import { Container, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane, Button } from "reactstrap";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";

import classes from "../course.module.scss";

import { baseObject } from "../course.constants";
import Breadcrumb from "layout/breadcrumb";
import Fetching from "components/fetching/Fetching";
import FormFields from "./FormFields";
import FormStudents from "./FormStudents";
import FormEvaluations from "./FormEvaluations";
import EvaluationForm from "../../evaluations/form/EvaluationForm";

import api from "services/api";
import Confirm from "components/confirm/Confirm";

const CourseForm = (props) => {
	let navigate = useNavigate();
	const { selected, option } = useParams();

	const [isFetching, setIsFetching] = useState(true);
	const [selectTab, setSelectTab] = useState("1");
	const [data, setData] = useState(baseObject);
	const [description, setDescription] = useState("");
	const [questionsLevel, setQuestionsLevel] = useState([]);
	const [subjects, setSubjects] = useState([]);

	const [showEvaluation, setShowEvaluation] = useState(option && option === "create_evaluation" ? true : false);
	const [reload, setReload] = useState(false);

	const [finishCourse, setFinishCourse] = useState(false);

	const validSave = () => {
		if (!data.name || data.name === "") {
			toast.error("El campo nombre es obligatorio");
			return false;
		}
		if (!data.subject_id || data.subject_id === "") {
			toast.error("El campo asignatura es obligatorio");
			return false;
		}
		if (!data.level_id || data.level_id === "") {
			toast.error("El campo de nivel de pregunta es obligatorio");
			return false;
		}
		if (!data.code || data.code === "") {
			toast.error("El campo código es obligatorio");
			return false;
		}

		if (data.id === "" && (!data.password || data.password === "")) {
			toast.error("El campo contraseña es obligatorio");
			return false;
		} else if (data.id === "" && data.password !== data.confirm_password) {
			toast.error("Las contraseñas no coinciden");
			return false;
		}

		return true;
	};

	const handleSave = async () => {
		setIsFetching(true);

		let newData = { ...data };
		newData.description = description;
		delete newData.evaluations;
		delete newData.students;

		if (validSave()) {
			api.post("/courses_save", newData)
				.then((response) => {
					let updateData = { ...data };
					updateData.id = response.course.id;
					updateData.slug = response.course.slug;
					setData(updateData);

					setIsFetching(false);
					toast.success("Registro guardado correctamente");
					navigate(`/courses/${response.course.slug}/edit`);
				})
				.catch((e) => {
					setIsFetching(false);
				});
		} else {
			setIsFetching(false);
		}
	};

	const handleFinishCourse = () => {
		setIsFetching(true);

		api.post("/courses/finish", data)
			.then((response) => {
				setIsFetching(false);
				toast.success("Curso completado con éxito");
				navigate(`/courses`);
			})
			.catch((e) => {
				setIsFetching(false);
			});
	};

	useEffect(() => {
		setIsFetching(true);
		api.get("/course", { selected }).then((response) => {
			if (response.course) {
				setData(response.course && response.course);
				setDescription(response.course && response.course.description);
			}
			setSubjects(response.subjects);
			setQuestionsLevel(response.questions_level);
			setIsFetching(false);
		});
		// eslint-disable-next-line
	}, [reload]);

	if (isFetching) {
		return <Fetching />;
	}

	return (
		<Fragment>
			{/* Courses form */}
			<Breadcrumb title={"Detalle de Curso"} parent="Cursos" />

			<Container className={classes.courses} fluid={true}>
				{data.id && (
					<Row style={{ width: "50%", padding: "10px 5px" }}>
						<Button
							style={{ padding: "12px 0px" }}
							color="primary"
							onClick={() => {
								navigate(`/courses/${data.slug}/edit/create_evaluation`);
								setShowEvaluation(true);
							}}
						>
							Nueva evaluación
						</Button>
					</Row>
				)}

				<Row>
					<Col>
						<Nav tabs>
							<NavItem>
								<NavLink className={selectTab === "1" ? "active" : ""} onClick={() => setSelectTab("1")}>
									Formulario
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink className={selectTab === "2" ? "active" : ""} onClick={() => setSelectTab("2")}>
									Estudiantes
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink className={selectTab === "3" ? "active" : ""} onClick={() => setSelectTab("3")}>
									Evaluaciones
								</NavLink>
							</NavItem>
						</Nav>
						<TabContent activeTab={selectTab}>
							<TabPane className="fade show" tabId="1">
								<FormFields
									data={data}
									setData={setData}
									description={description}
									setDescription={setDescription}
									questionsLevel={questionsLevel}
									subjects={subjects}
									setFinishCourse={setFinishCourse}
									handleSave={handleSave}
								/>
							</TabPane>
							<TabPane tabId="2">
								<FormStudents data={data} setData={setData} reload={reload} setReload={setReload} setIsFetching={setIsFetching} />
							</TabPane>
							<TabPane tabId="3">
								<FormEvaluations data={data} setData={setData} />
							</TabPane>
						</TabContent>
					</Col>
				</Row>
			</Container>

			<EvaluationForm form={showEvaluation} setForm={setShowEvaluation} courseId={data.id} />

			<Confirm modal={finishCourse} setModal={setFinishCourse} callbackAction={handleFinishCourse} />
		</Fragment>
	);
};

export default CourseForm;
