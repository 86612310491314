import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { useParams, useNavigate } from "react-router-dom";

import classes from "../course.module.scss";

import Breadcrumb from "layout/breadcrumb";
import CourseTitle from "./CourseTitle";

import api from "services/api";
import { baseObject } from "../course.constants";

import EvaluationListElement from "../../evaluations/list/EvaluationListElement";
import Fetching from "components/fetching/Fetching";
import CourseDetailFilter from "./CourseDetailFilter";

const CourseDetail = ({ t }) => {
	let navigate = useNavigate();

	const { slug } = useParams();

	const [evaluationsFilter, setEvaluationsFilter] = useState("Pending");
	const [isFetching, setIsFetching] = useState(true);
	const [course, setCourse] = useState(baseObject);

	useEffect(() => {
		setIsFetching(true);
		api.get("/my_course", {
			selected: slug,
		}).then((response) => {
			setIsFetching(false);

			if (!response.course) {
				navigate("/my_courses");
			}

			setCourse(response.course);
		});
		// eslint-disable-next-line
	}, []);

	if (isFetching) {
		return <Fetching />;
	}

	return (
		<div>
			<Breadcrumb title={course && `${course.customer && course.customer.name} - ${course.name} (${course.code})`} parent="Mis Cursos" />

			<Container className={classes.courses} fluid={true}>
				<CourseTitle course={course} />
			</Container>

			<Container fluid={true}>
				<Row>
					<CourseDetailFilter evaluationsFilter={evaluationsFilter} setEvaluationsFilter={setEvaluationsFilter} />

					<Col md={12} style={{ paddingLeft: "5px", paddingRight: "5px" }}>
						{course.evaluations &&
							course.evaluations.map((evaluation, i) => {
								return (
									<EvaluationListElement key={i} course={course} evaluation={evaluation} evaluationsFilter={evaluationsFilter} />
								);
							})}
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default CourseDetail;
