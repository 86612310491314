import React, { useState } from "react";

import classes from "../evaluation.module.scss";

import EvaluationReviewQuestionDetail from "./EvaluationReviewQuestionDetail";

import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import EvaluationReviewQuestionCreate from "./EvaluationReviewQuestionCreate";

const EvaluationReviewQuestion = ({ data, setData, typesQuestion, divReview }) => {
	const [openNewQuestion, setOpenNewQuestion] = useState(false);

	return (
		<div>
			{data.questions.map((question, index) => {
				return (
					<div className={classes.question_review} key={index}>
						<EvaluationReviewQuestionDetail index={index} question={question} data={data} setData={setData} divReview={divReview} />
					</div>
				);
			})}
			{!window.location.pathname.includes("/finish/") && (
				<div style={{ display: "flex", justifyContent: "center", marginTop: "10px", marginBottom: "10px" }}>
					<div>
						<PlusIcon
							onClick={() => setOpenNewQuestion(true)}
							style={{ width: "4em", height: "4em", fill: "#00a58d", cursor: "pointer" }}
						/>
					</div>
				</div>
			)}

			<EvaluationReviewQuestionCreate
				data={data}
				setData={setData}
				openModal={openNewQuestion}
				setOpenModal={setOpenNewQuestion}
				typesQuestion={typesQuestion}
			/>
		</div>
	);
};

export default EvaluationReviewQuestion;
