import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import reducers from "../redux/index";
import { loadState, saveState } from "services/localStorage";
import throttle from "lodash/throttle";

const persistedState = loadState();

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducers, persistedState, composeEnhancers(applyMiddleware(sagaMiddleware)));

store.subscribe(
	throttle(() => {
		saveState(store.getState());
	}, 1000)
);

export default store;
