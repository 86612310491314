import React, { useState, useEffect } from "react";

import { Row, Col } from "reactstrap";

import { ReactComponent as AddIcon } from "assets/icons/plus.svg";
import { ReactComponent as ExportIcon } from "assets/icons/arrow-down.svg";
import { isValidPermissionPage } from "services/utils";
import Filter from "./Filter/Filter";

const icons = {
	AddIcon: <AddIcon className="icon" />,
	ExportIcon: <ExportIcon className="icon" />,
	ImportIcon: <ExportIcon className="icon" style={{ transform: "rotate(180deg)" }} />,
};

const Search = ({ to, from, total, permissions, handleCreate, handleExport, haveFilter, showFilter, setShowFilter, listActions = null }) => {
	const actions = listActions || [
		{
			key: "create",
			label: "Crear",
			icon: "AddIcon",
			function: handleCreate,
		},
		{
			key: "export",
			label: "Exportar",
			icon: "ExportIcon",
			function: handleExport,
		},
	];

	const [realPermissions, setRealPermissions] = useState([]);

	useEffect(() => {
		if (permissions.length !== 0 && realPermissions.length === 0) {
			let newPermissions = [...permissions];
			if (actions.find((action) => action.key === "import")) {
				newPermissions.push({ permission: "import", value: true });
			}

			const p = isValidPermissionPage(actions, newPermissions);
			setRealPermissions(p);
		}
		// eslint-disable-next-line
	}, [permissions]);

	return (
		<Col sm="12" className="header-table">
			<Row>
				{haveFilter && <Filter showFilter={showFilter} setShowFilter={setShowFilter} />}

				{realPermissions.map((action, i) => {
					return (
						<Col key={i} sm={6 / realPermissions.length} className="main-action" onClick={() => action.function()}>
							<div className="action">
								{icons[action.icon]} {action.label}
							</div>
						</Col>
					);
				})}

				<Col sm={`5 ${realPermissions.length === 0 && "offset-md-6"} `} className="label-pagination">
					Presentando {from} - {to} de {total}
				</Col>
			</Row>
		</Col>
	);
};

export default Search;
