import React from "react";
import { Row, Col, Card, CardBody, Media } from "reactstrap";
import { useNavigate } from "react-router-dom";

import classes from "../course.module.scss";

import { dateToLocale, formatMiles } from "services/utils";

const CardEvaluation = ({ evaluation }) => {
	let navigate = useNavigate();

	return (
		<Card
			style={{
				cursor: "pointer",
			}}
			onClick={() => {
				if (Number(evaluation.state_id) === 4) {
					navigate(`/evaluations/edit/${evaluation.slug}`);
				} else {
					window.open(`${window.location.origin}/evaluations/review/${evaluation.slug}`, "_blank");
				}
			}}
		>
			<div className="job-search">
				<CardBody>
					<Media>
						<Media body>
							<h6 className="f-w-600">
								{evaluation.title}

								<span className="badge badge-primary pull-right">{evaluation.badgeValue}</span>
							</h6>
							<p>
								{`${evaluation.minutes} minutos`}
								{" - "}
								{`fecha de finalización ${dateToLocale(evaluation.finish_date)}`}
							</p>
						</Media>
					</Media>
					<p>{evaluation.description}</p>

					<table className={classes.tableResume}>
						<tbody>
							<tr>
								<td>Total de evaluaciones</td>
								<td style={{ textAlign: "center" }}>
									<span className={`${classes.countElement} ${classes.students}`}>{formatMiles(evaluation.students.length)}</span>
								</td>
							</tr>
							<tr>
								<td>Total de evaluaciones finalizadas</td>
								<td style={{ textAlign: "center" }}>
									<span className={`${classes.countElement} ${classes.answered}`}>0</span>
								</td>
							</tr>
							<tr>
								<td>Total de evaluaciones pendientes</td>
								<td style={{ textAlign: "center" }}>
									<span className={`${classes.countElement} ${classes.pending}`}>0</span>
								</td>
							</tr>
						</tbody>
					</table>
				</CardBody>
			</div>
		</Card>
	);
};

const FormEvaluations = (props) => {
	const { data } = props;

	return (
		<>
			<Row className={classes.rowTableStudents}>
				<Col md="12">
					{data.evaluations && data.evaluations.length > 0 ? (
						<Row>
							<Col md="12">
								<Row>
									{data.evaluations.map((evaluation, i) => {
										return (
											<Col md="12" key={i}>
												<CardEvaluation evaluation={evaluation} />
											</Col>
										);
									})}
								</Row>
							</Col>
						</Row>
					) : (
						<div className={classes.noRegistered}>No se ha registrado ninguna evaluación</div>
					)}
				</Col>
			</Row>
		</>
	);
};

export default FormEvaluations;
