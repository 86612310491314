import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import fileDownload from "js-file-download";
import { useDispatch } from "react-redux";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { toast } from "react-toastify";

import { HANDLE_GLOBAL_FETCHING } from "../../../../redux/actionTypes";

import { ReactComponent as DeleteIcon } from "assets/icons/basura.svg";
import Confirm from "components/confirm/Confirm";

import classes from "../course.module.scss";

import api from "services/api";
import FormStudentsImport from "./FormStudentsImport";

const FormStudents = (props) => {
	const dispatch = useDispatch();
	const { data, setData, reload, setReload, setIsFetching } = props;

	const [modal, setModal] = useState(false);
	const [index, setIndex] = useState("");
	const [showImport, setShowImport] = useState(false);
	const [selectStudents, setSelectStudents] = useState([]);

	const removeStudent = () => {
		setIsFetching(true);

		const form = {
			course_id: data.id,
			student: index ? data.students[index] : null,
			students: selectStudents,
		};

		api.post("/courses_delete_user", form).then((response) => {
			setData(response);
			setIsFetching(false);
			setReload(!reload);
			toast.success("Usuarios eliminados correctamente");
		});
	};

	const handleExportStudent = () => {
		const filename = `Curso ${data.name} - Estudiantes.xlsx`;

		const reduceFilters = {
			filter_name: "",
			filter_course_id: data.id,
		};

		dispatch({
			type: HANDLE_GLOBAL_FETCHING,
			payload: {
				isGlobalFetching: true,
				documentName: filename,
			},
		});

		api.getExcel("/students/excel", { ...reduceFilters }).then((data) => {
			fileDownload(data, filename);

			dispatch({
				type: HANDLE_GLOBAL_FETCHING,
				payload: {
					isGlobalFetching: false,
					documentName: "",
				},
			});
		});
	};

	const handleImportStudent = () => {
		setIsFetching(true);

		const form = new FormData();
		form.append("course_id", data.id);
		form.append("selected", data.slug);
		form.append("file", document.getElementById("fileStudent").files[0]);

		api.post("/students/excel/import", form).then((response) => {
			setShowImport(false);
			setIsFetching(false);
			setData(response.course);
			toast.success("Se han importado los estudiantes correctamente.");
		});
	};

	return (
		<>
			<Row className={classes.rowTableStudents}>
				<Col md="12">
					<div className={classes.divButton}>
						<div className={`${classes.button} ${classes.excel_down}`} onClick={() => handleExportStudent()}>
							Descargar Plantilla
						</div>
						<div className={`${classes.button} ${classes.excel_up}`} onClick={() => setShowImport(true)}>
							Cargar Plantilla Estudiantes
						</div>
					</div>
				</Col>

				{selectStudents.length > 0 && (
					<Col md="12">
						<div className={classes.divButton}>
							<div
								className={`${classes.button} ${classes.delete}`}
								onClick={() => {
									setModal(true);
								}}
							>
								Eliminar Estudiantes Seleccionados
							</div>
						</div>
					</Col>
				)}

				<Col md="12">
					{data.students && data.students.length > 0 ? (
						<Table className="secondary-table">
							<Thead>
								<Tr>
									{window.innerWidth > 768 && (
										<Th>
											<div className="actions">
												<span className="table-actions icon">
													<input
														type="checkbox"
														onChange={(e) => {
															if (e.target.checked) {
																setSelectStudents(data.students.map((student) => student.id));
															} else {
																setSelectStudents([]);
															}
														}}
													/>
												</span>
											</div>
										</Th>
									)}

									<Th>Nombre</Th>
									<Th>Apellido Paterno</Th>
									<Th>Apellido Materno</Th>
									<Th>Email</Th>
									<Th>Teléfono</Th>
									<Th></Th>
								</Tr>
							</Thead>
							<Tbody>
								{data.students &&
									data.students.length > 0 &&
									data.students.map((student, i) => {
										return (
											<Tr key={i}>
												{window.innerWidth > 768 && (
													<Td>
														<div className="actions">
															<span className="table-actions icon">
																<input
																	type="checkbox"
																	checked={selectStudents.includes(student.id)}
																	onChange={(e) => {
																		if (e.target.checked) {
																			setSelectStudents([...selectStudents, student.id]);
																		} else {
																			const newStudents = selectStudents.filter((item) => item !== student.id);
																			setSelectStudents(newStudents);
																		}
																	}}
																/>
															</span>
														</div>
													</Td>
												)}
												<Td>{student.name}</Td>
												<Td>{student.last_name}</Td>
												<Td>{student.last_name_mother}</Td>
												<Td>{student.email}</Td>
												<Td>{student.mobile}</Td>
												<Td>
													<div className="actions">
														<span
															className="table-actions icon trash"
															onClick={() => {
																setIndex(i);
																setModal(true);
															}}
														>
															<DeleteIcon className="icon-delete" />
														</span>
													</div>
												</Td>
											</Tr>
										);
									})}
							</Tbody>
						</Table>
					) : (
						<div className={classes.noRegistered}>No se ha registrado ningún usuario</div>
					)}
				</Col>
			</Row>

			<Confirm modal={modal} setModal={setModal} callbackAction={removeStudent} />

			<FormStudentsImport modal={showImport} setModal={setShowImport} callbackAction={handleImportStudent} />
		</>
	);
};

export default FormStudents;
