/* CUSTOMIZER */
export const ADD_COSTOMIZER = "ADD_COSTOMIZER";
export const ADD_LAYOUT = "ADD_LAYOUT";
export const ADD_SIDEBAR_TYPES = "ADD_SIDEBAR_TYPES";
export const ADD_SIDEBAR_SETTINGS = "ADD_SIDEBAR_SETTINGS";
export const ADD_COLOR = "ADD_COLOR";
export const ADD_MIX_BACKGROUND_LAYOUT = "ADD_MIX_BACKGROUND_LAYOUT";
export const ROUTER_ANIMATION = "ROUTER_ANIMATION";
export const REFRESH_SCREENS = "REFRESH_SCREENS";
export const HANDLE_GLOBAL_FETCHING = "HANDLE_GLOBAL_FETCHING";

// EVALUATION
export const START_EVALUATION = "START_EVALUATION";
export const TIME_EVALUATION = "TIME_EVALUATION";
export const INITIAL_EVALUATION = "INITIAL_EVALUATION";
