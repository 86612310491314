import React, { Fragment, useState, useEffect } from "react";
import { Container, Row, Col, Card, CardHeader, CardBody, Button } from "reactstrap";
import { useNavigate } from "react-router-dom";

import classes from "../course.module.scss";

import Fetching from "components/fetching/Fetching";
import Breadcrumb from "layout/breadcrumb";

import api from "services/api";
import { formatMiles } from "services/utils";

const CourseStudent = () => {
	let navigate = useNavigate();

	const [isFetching, setIsFetching] = useState(true);
	const [data, setData] = useState([]);
	const [filter, setFilter] = useState("active");

	useEffect(() => {
		setIsFetching(true);
		api.get("/my_courses").then((response) => {
			setIsFetching(false);
			setData(response.courses.data);
		});
		// eslint-disable-next-line
	}, []);

	if (isFetching) {
		return <Fetching />;
	}

	let list = data.filter((d) => !d.finalized_at);
	if (filter !== "active") {
		list = data.filter((d) => d.finalized_at);
	}

	return (
		<Fragment>
			<Breadcrumb title={"Mis Cursos"} parent="Midio" />

			<Container fluid={true} className={classes.listCourses}>
				<div className={classes.filter}>
					<div className={`${classes.button} ${filter === "active" && classes.active_success}`} onClick={() => setFilter("active")}>
						Abierto
					</div>
					<div className={`${classes.button} ${filter !== "active" && classes.active_error}`} onClick={() => setFilter("inactive")}>
						Finalizado
					</div>
				</div>

				<Row>
					{list.length !== 0 ? (
						list.map((course, i) => {
							return (
								<Col md={6} key={i}>
									<Card>
										<CardHeader
											style={{
												background: "-webkit-linear-gradient(top, #0d9488, #0d9488, #0d9488, #134e4a)",
												color: "white",
											}}
										>
											<h5>
												{course.name} - {course.code}
											</h5>
											<span style={{ color: "white" }}>{course && course.subject && course.subject.name}</span>
											<span style={{ color: "white" }}>{course && course.level && course.level.name}</span>
										</CardHeader>
										<CardBody>
											<span
												style={{
													marginBottom: "5px",
												}}
												className={`badge ${course.finalized_at ? classes.errorBadge : classes.succesBadge}`}
											>
												{course.finalized_at ? "Finalizado" : "En curso"}
											</span>

											<div
												dangerouslySetInnerHTML={{
													__html: course.description,
												}}
											></div>

											<p className={classes.span_quantity}>
												<strong>Evaluaciones</strong>
												{"  "}
												{formatMiles(course.evaluations.length)}
											</p>

											<p className={classes.span_quantity}>
												<strong>Estudiantes</strong>
												{"  "}
												{formatMiles(course.students.length)}
											</p>

											<div className={classes.buttonGo}>
												<Button color="primary" onClick={() => navigate(`/my_courses/${course.slug}`)}>
													Ir al curso
												</Button>
											</div>
										</CardBody>
									</Card>
								</Col>
							);
						})
					) : (
						<div className={classes.divNotFound}>
							<h3>Aún no tiene ningún curso registrado</h3>
							<img src={require("assets/images/courses/pageNotFound2.png")} alt="NoCourses" />
						</div>
					)}
				</Row>
			</Container>
		</Fragment>
	);
};

export default CourseStudent;
