import React from "react";

import { Row, Col } from "reactstrap";

import classes from "../course.module.scss";

const CourseDetailFilter = ({ evaluationsFilter, setEvaluationsFilter }) => {
	return (
		<Col md={12} className={classes.courseEvaluationFilters}>
			<Row>
				<Col
					md={3}
					className={`${classes.FilterButton} ${evaluationsFilter === "All" && classes.active} `}
					onClick={() => setEvaluationsFilter("All")}
				>
					Todas
				</Col>
				<Col
					md={3}
					className={`${classes.FilterButton} ${evaluationsFilter === "Pending" && classes.active} `}
					onClick={() => setEvaluationsFilter("Pending")}
				>
					Pendientes
				</Col>
				<Col
					md={3}
					className={`${classes.FilterButton} ${evaluationsFilter === "Finished" && classes.active} `}
					onClick={() => setEvaluationsFilter("Finished")}
				>
					Finalizadas
				</Col>
			</Row>
		</Col>
	);
};

export default CourseDetailFilter;
