import React, { useState } from "react";

import { Badge } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { useNavigate } from "react-router-dom";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import { dateToLocale } from "services/utils";

import NoData from "components/list/NoData/NoData";

import classes from "../../midio.module.scss";
import Option from "components/options/Option";
import EvaluationClone from "./EvaluationClone";

const CourseTable = (props) => {
	let navigate = useNavigate();

	const [modal, setModal] = useState(false);
	const [selectEvaluation, setSelectEvaluation] = useState({});
	const [selectCourses, setSelectCourses] = useState([]);

	const { data, roles, courses, updateData, handlePrint } = props;

	if (data && data.length === 0) {
		return <NoData />;
	}

	return (
		<div>
			<Table className={classes.main_table} cellSpacing="0">
				<Thead>
					<Tr>
						<Th>Curso</Th>
						<Th>Título</Th>
						<Th>Profesor</Th>
						<Th>Fecha Inicio</Th>
						<Th>Fecha Final</Th>
						<Th>Estado</Th>
						{roles.includes(4) && <Th>Calificación</Th>}
						<Th>Minutos</Th>
						<Th>Acciones</Th>
					</Tr>
				</Thead>
				<Tbody>
					{data.map((item, i) => {
						let colorBadge = "primary";
						if (item.state === "Borrador") {
							colorBadge = "dark";
						}
						if (item.state === "Revisión de Preguntas") {
							colorBadge = "info";
						}
						if (item.state === "Revisada") {
							colorBadge = "success";
						}

						let teacherOptions = [
							{
								label: "Ver",
								icon: "icofont-eye-alt",
								action: () => navigate("/evaluations/review/" + item.slug),
							},
						];
						if (item.is_teacher && item.state === "En Proceso") {
							teacherOptions.push({
								label: "Revisar Estudiantes",
								icon: "icofont-check-alt",
								action: () => navigate("/evaluations/review/" + item.slug),
							});
						}
						if (item.is_teacher && item.state === "Revisión de Preguntas") {
							teacherOptions.push({
								label: "Revisar Preguntas",
								icon: "icofont-check-alt",
								action: () => navigate("/evaluations/review/" + item.slug),
							});
						}
						if (item.is_teacher && item.state === "Revisada") {
							teacherOptions.push({
								label: "Ver",
								icon: "icofont-eye-alt",
								action: () => navigate("/evaluations/finish/" + item.slug),
							});
						}
						if (item.is_teacher && item.state === "Borrador") {
							teacherOptions.push({
								label: "Editar",
								icon: "icofont-edit",
								action: () => {
									props.setSlug(item.slug);
									props.setShowEvaluation(true);
									navigate("/evaluations/edit/" + item.slug);
								},
							});
						}
						if (
							item.is_teacher &&
							["En Proceso", "En Revisión", "Revisado", "Evaluación Finalizada", "Curso Finalizado"].includes(item.state)
						) {
							teacherOptions.push({
								label: "Clonar",
								icon: "icofont-copy",
								action: () => {
									setSelectEvaluation(item);
									setModal(true);
								},
							});
							teacherOptions.push({
								label: "Imprimir",
								icon: "icofont-print",
								action: () => {
									handlePrint(item);
								},
							});
						}

						let studentOptions = [];
						if (item.is_student && item.state === "Pendiente") {
							studentOptions.push({
								label: "Responder",
								icon: "icofont-check-alt",
								action: () => navigate("/evaluations/run/" + item.slug),
							});
						}
						if (item.is_student && item.state === "En Proceso") {
							studentOptions.push({
								label: "Continuar",
								icon: "icofont-check-alt",
								action: () => navigate("/evaluations/run/" + item.slug),
							});
						}
						if (item.is_student && ["Revisado", "Evaluación Finalizada", "Curso Finalizado"].includes(item.state)) {
							studentOptions.push({
								label: "Ver Respuestas",
								icon: "icofont-eye-alt",
								action: () => navigate("/evaluations/finish/" + item.slug),
							});
						}
						if (item.is_student && item.state === "En Revisión") {
							studentOptions.push({
								label: "En Revisión",
								icon: "icofont-eye-alt",
								action: () => navigate("/evaluations/finish/" + item.slug),
							});
						}

						return (
							<Tr key={i} className={classes.tr}>
								<Td className={classes.td}>
									<div className={`${classes.div_td} ${classes.first} ${classes.start}`}>{item.course ? item.course.name : ""}</div>
								</Td>
								<Td className={classes.td}>
									<div className={`${classes.div_td} ${classes.start}`}>{item.title}</div>
								</Td>
								<Td className={classes.td}>
									<div className={`${classes.div_td} ${classes.smallText}`}>{item.teacher ? item.teacher.name : ""}</div>
								</Td>
								<Td className={classes.td}>
									<div className={classes.div_td}>{dateToLocale(item.initial_date)}</div>
								</Td>
								<Td className={classes.td}>
									<div className={classes.div_td}>{dateToLocale(item.finish_date)}</div>
								</Td>
								<Td className={classes.td}>
									<div className={classes.div_td}>
										<Badge color={colorBadge} style={{ fontWeight: 400 }}>
											{item.state}
										</Badge>
									</div>
								</Td>
								{roles.includes(4) && (
									<Td className={classes.td}>
										<div className={classes.div_td}>
											{item.state === "Revisada" && (
												<>
													{item.quantity_correct}/{item.quantity_question}
												</>
											)}
										</div>
									</Td>
								)}
								<Td className={classes.td}>
									<div className={classes.div_td}>{item.minutes}</div>
								</Td>
								<Td className={classes.td}>
									<div className={`${classes.div_td} ${classes.icon_div} ${classes.last}`}>
										{item.is_student && <Option options={studentOptions} />}
										{item.is_teacher && <Option options={teacherOptions} />}
									</div>
								</Td>
							</Tr>
						);
					})}
				</Tbody>
			</Table>

			<EvaluationClone
				selectCourses={selectCourses}
				selectEvaluation={selectEvaluation}
				setSelectCourses={setSelectCourses}
				setSelectEvaluation={setSelectEvaluation}
				courses={courses}
				updateData={updateData}
				modal={modal}
				setModal={setModal}
			/>
		</div>
	);
};

export default CourseTable;
