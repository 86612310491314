import React from "react";
import { Col, Card, CardHeader, CardBody, Badge } from "reactstrap";

import classes from "../student.module.scss";

const FormCourse = ({ data, setData, courses, selectCourse }) => {
	return (
		<Col xl={`6 offset-md-3`}>
			<Card>
				<CardHeader>
					<h4 className="card-title mb-0">Asignar Curso</h4>
				</CardHeader>
				<CardBody>
					<span>Selecciona los cursos para que el usuario ingresado quede registrado automaticamente.</span>

					<div style={{ marginTop: "10px" }}>
						{courses.map((course, i) => {
							return (
								<div
									key={i}
									className={`${classes.course} ${data.select_courses.includes(course.id) && classes.selected}`}
									onClick={() => selectCourse(course)}
								>
									<div>{course.code}</div>
									<div>{course.name}</div>
									<div>{data.select_courses.includes(course.id) && <Badge color="primary">Seleccionado</Badge>}</div>
								</div>
							);
						})}
					</div>
				</CardBody>
			</Card>
		</Col>
	);
};

export default FormCourse;
