import React, { useState, Fragment } from "react";
import { Container, Row, Col, Card, Input, Label } from "reactstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import classes from "./course.module.scss";

import Breadcrumb from "layout/breadcrumb";
import { ReactComponent as RegisterCourseIcon } from "assets/icons/registerCourse.svg";
import Fetching from "components/fetching/Fetching";

import { registerCourse } from "./course.constants";

import api from "services/api";

const RegisterCourse = (props) => {
	let navigate = useNavigate();
	const [fetching, setFetching] = useState(false);

	const [data, setData] = useState(registerCourse);

	const handleRegister = () => {
		setFetching(true);
		api.post("/course_register", data)
			.then((response) => {
				toast.success("Registro completado con éxito");
				setFetching(false);

				navigate(`/my_courses/${response.course.slug}`);
			})
			.catch((error) => {
				setFetching(false);
			});
	};

	const handleKeyPress = (e) => {
		const keyCode = e.which || e.keyCode;
		if (keyCode === 13) {
			handleRegister();
		}
	};

	return (
		<Fragment>
			<Breadcrumb title={"Registrar Curso"} parent="Midio" />

			<Container fluid={true}>
				<Row>
					<Col sm="12">
						<Card className={classes.registerCourse}>
							<Row className={classes.divRegister}>
								<RegisterCourseIcon className={classes.iconRegister} />

								{fetching ? (
									<Fetching />
								) : (
									<>
										<Col md={12} className={classes.rowInput}>
											<Label htmlFor="code">Código</Label>
											<Input
												className="form-control"
												name="code"
												type="text"
												value={data.code && data.code}
												onChange={(e) =>
													setData({
														...data,
														code: e.target.value,
													})
												}
												onKeyPress={(e) => handleKeyPress(e)}
											/>
										</Col>

										<Col md={12} className={classes.rowInput}>
											<Label htmlFor="password">Contraseña</Label>
											<Input
												className="form-control"
												name="name"
												type="password"
												value={data.password && data.password}
												onChange={(e) =>
													setData({
														...data,
														password: e.target.value,
													})
												}
												onKeyPress={(e) => handleKeyPress(e)}
											/>
										</Col>
									</>
								)}

								<Col md={12} className={classes.rowInput}>
									<div
										className={`${fetching && classes.fetching} ${classes.button}`}
										onClick={() => !fetching && handleRegister()}
									>
										{fetching ? "Cargando..." : "Registrar Curso"}
									</div>
								</Col>
							</Row>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};

export default RegisterCourse;
