import React from "react";
import { Col } from "reactstrap";

import classes from "./answer.module.scss";

const EvaluationInCourseButton = ({ data, selectQuestion, setSelectQuestion }) => {
	return (
		<Col md={12} sm={12}>
			<div className={classes.divButtons}>
				{data &&
					data.questions &&
					data.questions.map((question, i) => (
						<div
							key={i}
							className={`${classes.buttonNumber} ${question.answered && classes.active} ${selectQuestion === i && classes.selected}`}
							onClick={() => setSelectQuestion(i)}
						>
							{i + 1}
						</div>
					))}
			</div>
		</Col>
	);
};

export default EvaluationInCourseButton;
