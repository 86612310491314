import React from "react";

import { Row, Col, Card, CardHeader, CardBody, Label, Input, FormGroup, Media } from "reactstrap";

import man from "assets/images/dashboard/profile.png";

const FormProfile = (props) => {
	const { data, setData, handleSave } = props;

	return (
		<Col xl={`${!data.id ? "6" : "4"} ${!data.id && "offset-md-3"}`}>
			<Card>
				<CardHeader>
					<h4 className="card-title mb-0">Usuario</h4>
				</CardHeader>
				<CardBody>
					<Row className="mb-2">
						<div className="col-auto">
							<Media style={{ cursor: "pointer" }} className="img-70 rounded-circle" alt="" src={data.photo ? data.photo : man} />
						</div>
						<Col>
							<h3 className="mb-1" style={{ fontSize: "20px" }}>
								{data.name ? data.name : ""}
								{data.last_name ? data.last_name : ""}
								{data.last_name_mother ? data.last_name_mother : ""}
							</h3>
							<p className="mb-4">{data.academic_title ? data.academic_title : ""}</p>
						</Col>
					</Row>
					<FormGroup>
						<Label className="form-label">Nombre de Usuario</Label>
						<Input className="form-control" name="username" value={data.username ? data.username : ""} readOnly={true} />
					</FormGroup>
					<FormGroup>
						<Label className="form-label">Nombre</Label>
						<Input
							className="form-control"
							name="name"
							value={data.name ? data.name : ""}
							onChange={(e) =>
								setData({
									...data,
									name: e.target.value,
								})
							}
						/>
					</FormGroup>

					{!data.id && (
						<>
							<FormGroup>
								<Label className="form-label">Apellido Paterno</Label>
								<Input
									className="form-control"
									name="last_name"
									value={data.last_name ? data.last_name : ""}
									onChange={(e) =>
										setData({
											...data,
											last_name: e.target.value,
										})
									}
								/>
							</FormGroup>
							<FormGroup>
								<Label className="form-label">Apellido Materno</Label>
								<Input
									className="form-control"
									name="last_name_mother"
									value={data.last_name_mother ? data.last_name_mother : ""}
									onChange={(e) =>
										setData({
											...data,
											last_name_mother: e.target.value,
										})
									}
								/>
							</FormGroup>
						</>
					)}
					<FormGroup>
						<Label className="form-label">Email</Label>
						<Input
							className="form-control"
							name="email"
							value={data.email ? data.email : ""}
							readOnly={data.id ? true : false}
							onChange={(e) =>
								setData({
									...data,
									username: e.target.value,
									email: e.target.value,
								})
							}
						/>
					</FormGroup>
					{data.id && (
						<>
							<FormGroup>
								<Label className="form-label">Contraseña</Label>
								<Input
									className="form-control"
									type="password"
									name="password"
									value={data.password ? data.password : ""}
									onChange={(e) =>
										setData({
											...data,
											password: e.target.value,
										})
									}
								/>
							</FormGroup>
							<FormGroup>
								<Label className="form-label">Confirmar Contraseña</Label>
								<Input
									className="form-control"
									type="password"
									name="confirm_password"
									value={data.confirm_password ? data.confirm_password : ""}
									onChange={(e) =>
										setData({
											...data,
											confirm_password: e.target.value,
										})
									}
								/>
							</FormGroup>
						</>
					)}

					{!data.id && (
						<div className="form-footer">
							<button className="btn btn-primary btn-block" onClick={() => handleSave()}>
								Guardar
							</button>
						</div>
					)}
				</CardBody>
			</Card>
		</Col>
	);
};

export default FormProfile;
