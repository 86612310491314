import { START_EVALUATION, TIME_EVALUATION, INITIAL_EVALUATION } from "../actionTypes";

import { loadState } from "services/localStorage";

const initial_state = (loadState() && loadState().Evaluation) || {
	start: false,
	evaluation_id: "",
	slug: "",
	evaluation_title: "",
	course_code: "",
	course_name: "",
	seconds: 0,
	response: {},
};

// eslint-disable-next-line
export default (state = initial_state, action) => {
	switch (action.type) {
		case INITIAL_EVALUATION:
			return {
				...{
					start: false,
					evaluation_id: "",
					slug: "",
					evaluation_title: "",
					course_code: "",
					course_name: "",
					seconds: 0,
					response: {},
				},
			};
		case START_EVALUATION:
			return {
				...state,
				start: action.payload.start,
				evaluation_id: action.payload.evaluation_id,
				slug: action.payload.slug,
				evaluation_title: action.payload.evaluation_title,
				course_code: action.payload.course_code,
				course_name: action.payload.course_name,
				seconds: action.payload.seconds,
				response: action.payload.response,
			};
		case TIME_EVALUATION:
			return {
				...state,
				seconds: action.payload.seconds,
			};
		default:
			return { ...state };
	}
};
