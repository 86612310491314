// dashbaord
import Dashboard from "components/dashboard";
import Customer from "page/maintainers/customer/Customer";
import Role from "page/maintainers/role/Role";
import Screen from "page/maintainers/screen/Screen";
import ScreenOption from "page/maintainers/screen_option/ScreenOption";
import User from "page/maintainers/user/User";

import Subject from "page/maintainers/subject/Subject";
import QuestionLevels from "page/maintainers/questionLevels/QuestionLevels";
import QuestionCategories from "page/maintainers/questionCategories/QuestionCategories";
import QuestionContents from "page/maintainers/questionContents/QuestionContents";
import QuestionObjetives from "page/maintainers/questionObjetives/QuestionObjetives";
import Question from "page/maintainers/question/Question";

import Course from "page/midio/courses/Course";
import CourseStudent from "page/midio/courses/student/CourseStudent";
import CourseDetail from "page/midio/courses/student/CourseDetail";
import RegisterCourse from "page/midio/courses/RegisterCourse";
import CourseForm from "page/midio/courses/form/CourseForm";
import Student from "page/midio/students/Student";
import StudentForm from "page/midio/students/form/StudentForm";
import Evaluation from "page/midio/evaluations/Evaluation";
import EvaluationAnswer from "page/midio/evaluations/answer/EvaluationAnswer";
import EvaluationReview from "page/midio/evaluations/review/EvaluationReview";
import EvaluationFinish from "page/midio/evaluations/finish/EvaluationFinish";

export const routes = [
	{
		path: `/`,
		Component: Dashboard,
	},
	{
		path: `/dashboard`,
		Component: Dashboard,
	},
	{
		path: `/admin/customers/:type?/:slug?`,
		Component: Customer,
	},
	{
		path: `/admin/screens`,
		Component: Screen,
	},
	{
		path: `/admin/screens_option`,
		Component: ScreenOption,
	},
	{
		path: `/admin/roles`,
		Component: Role,
	},
	{
		path: `/admin/users/:option?/:slug?`,
		Component: User,
	},
	{
		path: `/admin/subjects/:option?/:slug?`,
		Component: Subject,
	},
	{
		path: `/admin/question_levels/:option?/:slug?`,
		Component: QuestionLevels,
	},
	{
		path: `/admin/question_categories/:option?/:slug?`,
		Component: QuestionCategories,
	},
	{
		path: `/admin/question_contents/:option?/:slug?`,
		Component: QuestionContents,
	},
	{
		path: `/admin/question_objectives/:option?/:slug?`,
		Component: QuestionObjetives,
	},
	{
		path: `/admin/questions/:option?/:question_id?`,
		Component: Question,
	},
	{
		path: `/courses`,
		Component: Course,
	},
	{
		path: `/courses/create`,
		Component: CourseForm,
	},
	{
		path: `/courses/:selected/edit/:option?`,
		Component: CourseForm,
	},
	{
		path: `/register_course`,
		Component: RegisterCourse,
	},
	{
		path: `/students`,
		Component: Student,
	},
	{
		path: `/students/:option/:slug?`,
		Component: StudentForm,
	},
	{
		path: `/profile/:slug?`,
		Component: StudentForm,
	},
	{
		path: `/evaluations/:action?/:evaluation_slug?`,
		Component: Evaluation,
	},
	{
		path: `/evaluations/review/:slug`,
		Component: EvaluationReview,
	},
	{
		path: `/evaluations/run/:slug`,
		Component: EvaluationAnswer,
	},
	{
		path: `/evaluations/finish/:slug/:user_slug?`,
		Component: EvaluationFinish,
	},
	{
		path: `/my_courses`,
		Component: CourseStudent,
	},
	{
		path: `/my_courses/:slug`,
		Component: CourseDetail,
	},
];
