import React from "react";
import { toast } from "react-toastify";

import classes from "../evaluation.module.scss";

import api from "services/api";

const EvaluationReviewTeacher = ({ question, data, setData, setLoading }) => {
	let userAnswer = null;
	let isTeacher = Number(localStorage.getItem("user_id")) === Number(data.teacher_id);

	if (question.selected && [1, 3].includes(question.type.id) && question.selected.text_answer) {
		userAnswer = question.selected;
	}
	if (question.selected && [2].includes(question.type.id) && question.selected.text_answer) {
		userAnswer = question.selected;
	}
	if (question.selected && [4].includes(question.type.id) && question.selected.text_answer) {
		userAnswer = question.selected;
	}

	const handleChangeCorrect = (is_correct) => {
		setLoading(true);
		api.post("/evaluations/review/question/change_answer", { ...userAnswer, is_correct }).then((response) => {
			let newData = { ...data };
			newData.questions = newData.questions.map((responseQuestion) => {
				if (responseQuestion.selected && userAnswer.question_id === responseQuestion.id) {
					responseQuestion.selected.is_correct = is_correct;
				}
				return responseQuestion;
			});
			setData(newData);

			toast.success(response.message);
			setLoading(false);
		});
	};

	return (
		<>
			{userAnswer && (
				<>
					<div
						onClick={() => (isTeacher ? handleChangeCorrect(!userAnswer.is_correct) : null)}
						className={userAnswer.is_correct ? classes.is_correct : classes.is_incorrect}
						style={{ height: "25px", cursor: "pointer" }}
					>
						{userAnswer.is_correct ? "Correcto" : "Incorrecto"}{" "}
					</div>
				</>
			)}
		</>
	);
};

export default EvaluationReviewTeacher;
