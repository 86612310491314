import React from "react";
import { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import { Col, Label, Input, Button } from "reactstrap";
import { toast } from "react-toastify";

const EvaluationStepTitle = ({ data, setData, handleSave, isSave }) => {
	const CustomOption = (props) => {
		return (
			<components.Option {...props}>
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<div>{props.data.label}</div>
					<i className="fa fa-info-circle fa-lg font-primary" title={props.data.description}></i>
				</div>
			</components.Option>
		);
	};

	const handleValid = () => {
		if (data.categories.filter((c) => c.selected).length === 0) {
			toast.error("Por favor, seleccione una unidad de aprendizaje");
			return false;
		}
		if (data.contents.filter((c) => c.selected).length === 0) {
			toast.error("Por favor, seleccione al menos un contenido");
			return false;
		}

		handleSave(3);
	};

	return (
		<div>
			<div style={{ display: "flex" }}>
				<div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
					<h4>
						Muy bien, <span style={{ color: "#00a58d" }}>continuemos... </span>
					</h4>
				</div>
			</div>

			<div className="row">
				<Col md="12 mb-3">
					<Label style={{ fontSize: "16px" }} htmlFor="description">
						Que <span style={{ color: "#00a58d" }}>unidad</span> vamos a evaluar?
					</Label>

					<CreatableSelect
						defaultValue={data && data.categories ? data.categories.filter((c) => c.selected) : []}
						placeholder={<div>Seleccione...</div>}
						options={
							data.subject_id && data.level_id
								? data.categories.filter(
										(c) => String(c.subject_id) === String(data.subject_id) && String(c.level_id) === String(data.level_id)
								  )
								: []
						}
						isClearable={true}
						onChange={(opt, meta) => {
							let newCategories = [...data.categories];
							if (meta.action === "create-option") {
								newCategories = [
									...newCategories,
									{
										label: opt.label,
										value: opt.label,
										selected: 1,
										isNew: true,
										subject_id: data.subject_id,
										level_id: data.level_id,
									},
								];
							} else {
								newCategories = newCategories.map((c) => {
									if (!opt) {
										return { ...c, selected: 0 };
									}

									if (String(c.id) === String(opt.id)) {
										return { ...c, selected: 1 };
									}
									return { ...c, selected: 0 };
								});
							}

							let newContents = [...data.contents];
							newContents = newContents.map((c) => {
								return { ...c, selected: 0 };
							});

							setData({
								...data,
								categories: newCategories,
								contents: newContents,
							});
						}}
					/>
				</Col>

				<Col md="12 mb-3">
					<Label style={{ fontSize: "16px" }} htmlFor="description">
						Que <span style={{ color: "#00a58d" }}>contenidos</span> deseas incluir?
					</Label>

					<CreatableSelect
						key={data.categories.filter((c) => c.selected).map((c) => String(c.value))}
						defaultValue={data.contents ? data.contents.filter((c) => c.selected) : []}
						placeholder={<div>Seleccione...</div>}
						isMulti={true}
						options={
							data.contents
								? data.contents.filter((c) =>
										data.categories
											.filter((c) => c.selected)
											.map((c) => String(c.value))
											.includes(String(c.category_id))
								  )
								: []
						}
						onChange={(opt, meta) => {
							if (data.categories.filter((c) => c.selected).length === 0) {
								toast.error("Por favor, primero seleccione una unidad de aprendizaje");
								return;
							}

							let newContents = [...data.contents];
							newContents = newContents.map((c) => {
								return { ...c, selected: 0 };
							});

							opt.forEach((option) => {
								let findContent = newContents.find((c) => c.value === option.value);
								if (findContent) {
									newContents = newContents.map((c) => {
										if (c.value === option.value) {
											return { ...c, selected: 1 };
										}
										return { ...c };
									});
								} else {
									newContents = [
										...newContents,
										{
											id: option.value,
											label: option.label,
											value: option.value,
											selected: 1,
											isNew: true,
											category_id: data.categories.filter((c) => c.selected)[0].value,
										},
									];
								}
							});

							setData({
								...data,
								contents: newContents,
							});
						}}
					/>
				</Col>

				<Col md="12 mb-3">
					<Label style={{ fontSize: "16px" }} htmlFor="description">
						Que <span style={{ color: "#00a58d" }}>objetivos</span> se busca evaluar?
					</Label>

					<CreatableSelect
						defaultValue={data && data.objectives ? data.objectives.filter((c) => c.selected) : []}
						placeholder={<div>Seleccione...</div>}
						isMulti={true}
						options={
							data.subject_id && data.level_id
								? data.objectives.filter(
										(o) => String(o.subject_id) === String(data.subject_id) && String(o.level_id) === String(data.level_id)
								  )
								: []
						}
						components={{ Option: CustomOption }}
						onChange={(opt, meta) => {
							let newObjectives = [...data.objectives];
							newObjectives = newObjectives.map((c) => {
								return { ...c, selected: 0 };
							});

							opt.forEach((option) => {
								let findObjetive = newObjectives.find((c) => c.value === option.value);
								if (findObjetive) {
									newObjectives = newObjectives.map((c) => {
										if (String(c.value) === String(option.value)) {
											return { ...c, selected: 1 };
										}
										return { ...c };
									});
								} else {
									newObjectives = [
										...newObjectives,
										{
											id: option.value,
											label: option.label,
											value: option.value,
											selected: 1,
											isNew: true,
											subject_id: data.subject_id,
										},
									];
								}
							});

							setData({
								...data,
								objectives: newObjectives,
							});
						}}
					/>
				</Col>

				<Col md="12 mb-3">
					<Label style={{ fontSize: "13px" }} htmlFor="description">
						Danos una descripción extra de la evaluación, esto nos ayudará a buscar las mejores preguntas para ti
					</Label>
					<Input
						className="form-control"
						name="description"
						type="textarea"
						rows="5"
						value={data.description && data.description}
						onChange={(e) =>
							setData({
								...data,
								description: e.target.value,
							})
						}
					/>
				</Col>
			</div>

			<div style={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
				<Button disabled={isSave} color="secondary" style={{ padding: "10px 30px", marginRight: "10px" }} onClick={() => handleSave(1)}>
					Volver
				</Button>

				<Button disabled={isSave} color="primary" style={{ padding: "10px 30px" }} onClick={() => handleValid()}>
					{isSave ? "Guardando..." : "Continuar"}
				</Button>
			</div>
		</div>
	);
};

export default EvaluationStepTitle;
