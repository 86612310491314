export const base_filters = [
	{
		key: "filter_customer_id",
		name: "Cliente",
		value: "",
		type: "select",
		options: [],
	},
	{
		key: "filter_name",
		name: "Nombre",
		value: "",
		type: "text",
		options: [],
	},
];
