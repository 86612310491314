import React from "react";

import { useNavigate } from "react-router-dom";

import { Card, CardBody, Media } from "reactstrap";
import { dateToLocale, datetimeToLocale, formatMiles } from "services/utils";

const EvaluationListElement = ({ course, evaluation, evaluationsFilter }) => {
	let navigate = useNavigate();

	const date = new Date();
	const date_finish = new Date(evaluation.finish_date);

	if (evaluationsFilter === "Pending" && date_finish < date && !evaluation.answers && !course.finalized_at) {
		return "";
	}

	if (evaluationsFilter === "Finished" && (date_finish >= date || evaluation.answers || course.finalized_at)) {
		return "";
	}

	return (
		<Card>
			<div className="job-search">
				<CardBody>
					<Media>
						<Media body>
							<h6 className="f-w-600">
								<div
									style={{
										color: "#00a58d",
										cursor: "pointer",
									}}
								>
									{evaluation.title} ({`${evaluation.minutes} minutos`})
								</div>

								{course.finalized_at && (
									<span
										className="badge badge-danger pull-right"
										style={{
											marginRight: "5px",
											padding: "1em",
										}}
									>
										Curso Finalizado
									</span>
								)}

								{!course.finalized_at && date_finish.getTime() > date.getTime() && !evaluation.answers && (
									<span
										className="badge pull-right"
										style={{
											background: "#999999",
											cursor: "pointer",
											padding: "1em",
										}}
										onClick={() => navigate("/evaluations/run/" + evaluation.slug)}
									>
										Iniciar Evaluación
									</span>
								)}

								{!course.finalized_at &&
									date_finish.getTime() > date.getTime() &&
									evaluation.answers &&
									evaluation.user_evaluation &&
									!evaluation.user_evaluation.finish && (
										<span
											className="badge pull-right"
											style={{
												background: "#999999",
												cursor: "pointer",
												padding: "1em",
											}}
											onClick={() => navigate("/evaluations/run/" + evaluation.slug)}
										>
											Continuar
										</span>
									)}

								{!course.finalized_at &&
									date_finish.getTime() <= date.getTime() &&
									!evaluation.answers &&
									!(evaluation.user_evaluation && evaluation.user_evaluation.finish) && (
										<span
											className="badge badge-danger pull-right"
											style={{
												marginRight: "5px",
												padding: "1em",
											}}
										>
											Fecha límite alcanzada
										</span>
									)}

								{(!course.finalized_at && date_finish.getTime() <= date.getTime() && evaluation.answers) ||
								(evaluation && evaluation.user_evaluation && evaluation.user_evaluation.finish) ? (
									<>
										<span
											className="badge  pull-right"
											style={{
												backgroundColor: "#5e4cc6",
												cursor: "pointer",
											}}
											onClick={() => navigate("/evaluations/run/" + evaluation.slug)}
										>
											Ver Respuestas
										</span>
										<span
											className="badge badge-primary pull-right"
											style={{
												marginRight: "5px",
											}}
										>
											Finalizada
										</span>
									</>
								) : (
									""
								)}
							</h6>
							<p>{`desde ${dateToLocale(evaluation.initial_date)} hasta  ${dateToLocale(evaluation.finish_date)}`}</p>
						</Media>
					</Media>
					<div style={{ display: "flex", flexDirection: "column" }}>
						<small>
							{`Cantidad de Preguntas `}
							<strong>{evaluation && formatMiles(evaluation.quantity_questions)}</strong>
						</small>
						{evaluation && evaluation.user_evaluation && evaluation.user_evaluation.finish_date ? (
							<small>
								{`Fecha Finalización `}
								<strong>{datetimeToLocale(evaluation.user_evaluation.finish_date)}</strong>
							</small>
						) : (
							""
						)}
					</div>
					<p>{evaluation.description}</p>
				</CardBody>
			</div>
		</Card>
	);
};

export default EvaluationListElement;
