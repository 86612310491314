import React from "react";

import { Col, Input, Row } from "reactstrap";
import { useLocation } from "react-router-dom";

import { ReactComponent as ErrorIcon } from "assets/icons/FormQuestion/eliminar.svg";

import classes from "../question.module.scss";

const QuestionFormConnect = (props) => {
	const { data, setData } = props;

	let location = useLocation();
	const showButtons = location.pathname.includes("evaluations");

	const addAnswer = () => {
		const baseAlternative = {
			id: "",
			description: "",
			connect: "",
			is_correct: "",
		};

		let newAlternatives = [...data.alternatives];
		newAlternatives.push(baseAlternative);

		setData({
			...data,
			alternatives: newAlternatives,
		});
	};

	return (
		<Col md="12 mb-3">
			<Row>
				{!showButtons && (
					<Col md="12 mb-3">
						<div className={classes.addFormItem} onClick={() => addAnswer()}>
							Nuevo enunciado
						</div>
					</Col>
				)}

				{data &&
					data.alternatives &&
					data.alternatives.map((alternative, i) => {
						return (
							<Col key={i} md="12" xs="12" className={`${classes.answer}`}>
								<Row>
									<Col md="5" xs="5">
										<Input
											className="form-control"
											name="description"
											type="text"
											value={alternative.description ? alternative.description : ""}
											onClick={(e) => {
												e.preventDefault();
												e.stopPropagation();
											}}
											onChange={(e) => {
												e.preventDefault();
												e.stopPropagation();

												let newAlternatives = [...data.alternatives];
												newAlternatives[i].description = e.target.value;

												setData({
													...data,
													alternatives: newAlternatives,
												});
											}}
										/>
									</Col>
									<Col md="5" xs="5">
										<Input
											className="form-control"
											name="description"
											type="text"
											value={alternative.connect ? alternative.connect : ""}
											onClick={(e) => {
												e.preventDefault();
												e.stopPropagation();
											}}
											onChange={(e) => {
												e.preventDefault();
												e.stopPropagation();

												let newAlternatives = [...data.alternatives];
												newAlternatives[i].connect = e.target.value;

												setData({
													...data,
													alternatives: newAlternatives,
												});
											}}
										/>
									</Col>
									{!showButtons && (
										<Col className={classes.colIcon}>
											<ErrorIcon
												className={classes.icon}
												onClick={(e) => {
													e.preventDefault();
													e.stopPropagation();

													let newAlternatives = [...data.alternatives];
													newAlternatives.splice(i, 1);

													setData({
														...data,
														alternatives: newAlternatives,
													});
												}}
											/>
										</Col>
									)}
								</Row>
							</Col>
						);
					})}
			</Row>
		</Col>
	);
};

export default QuestionFormConnect;
