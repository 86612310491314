import React, { useEffect, useState } from "react";

import { useParams, useNavigate } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Col, Label, Input } from "reactstrap";
import Select from "react-select";
import { toast } from "react-toastify";

import Fetching from "components/fetching/Fetching";

import api from "services/api";

const baseObject = {
	id: "",
	code: "",
	name: "",
	description: "",
	subject_id: "",
	indice: "",
};

const QuestionLevelsForm = (props) => {
	let navigate = useNavigate();
	const { option, slug } = useParams();

	const [isFetching, setIsFetching] = useState(true);
	const [data, setData] = useState({ ...baseObject });

	const { subjects, callbackAction } = props;

	const handleClose = () => {
		setData({ ...baseObject });
		navigate("/admin/question_levels");
	};

	const handleSave = async () => {
		if (validSave()) {
			setIsFetching(true);
			if (await callbackAction(data)) {
				handleClose();
			} else {
				setIsFetching(false);
			}
		}
	};

	const validSave = () => {
		if (!data.name || data.name === "") {
			toast.error("El campo nombre es obligatorio");
			return false;
		}
		if (!data.subject_id || data.subject_id === "") {
			toast.error("El campo asignatura es obligatorio");
			return false;
		}

		return true;
	};

	const handleSubject = (value) => {
		setData({
			...data,
			subject_id: value.value,
		});
	};

	useEffect(() => {
		if (slug) {
			setIsFetching(true);
			api.get("/admin/question_level", { selected: slug }).then((response) => {
				setData(response.question_level);
				setIsFetching(false);
			});
		} else {
			setData({ ...baseObject });
			setIsFetching(false);
		}
	}, [slug]);

	return (
		<Modal isOpen={option ? true : false} toggle={handleClose} size="lg">
			{isFetching ? (
				<Fetching />
			) : (
				<>
					<ModalHeader toggle={handleClose}>Niveles de Pregunta</ModalHeader>
					<ModalBody>
						<div className="row">
							<Col md="6 mb-2">
								<Label htmlFor="name">Código</Label>
								<Input
									className="form-control"
									name="code"
									type="text"
									value={data.code}
									onChange={(e) =>
										setData({
											...data,
											code: e.target.value,
										})
									}
								/>
							</Col>

							<Col md="6 mb-2">
								<Label htmlFor="name">Asignatura</Label>
								<Select
									defaultValue={data.subject_id ? subjects.filter((c) => Number(c.value) === Number(data.subject_id)) : ""}
									placeholder={<div>Seleccione......</div>}
									options={subjects}
									onChange={(value) => handleSubject(value)}
								/>
							</Col>

							<Col md="6 mb-2">
								<Label htmlFor="name">Nombre</Label>
								<Input
									className="form-control"
									name="name"
									type="text"
									value={data.name}
									onChange={(e) =>
										setData({
											...data,
											name: e.target.value,
										})
									}
								/>
							</Col>

							<Col md="6 mb-2">
								<Label htmlFor="indice">Indice</Label>
								<Input
									className="form-control"
									name="indice"
									type="number"
									value={data.indice}
									onChange={(e) =>
										setData({
											...data,
											indice: e.target.value,
										})
									}
								/>
							</Col>
						</div>

						<div className="row">
							<Col md="12 mb-3">
								<Label htmlFor="description">Descripción</Label>
								<Input
									className="form-control"
									name="description"
									type="textarea"
									rows="5"
									value={data.description}
									onChange={(e) =>
										setData({
											...data,
											description: e.target.value,
										})
									}
								/>
							</Col>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button color="secondary" onClick={handleClose}>
							Cancelar
						</Button>
						<Button color="primary" onClick={() => handleSave()}>
							Guardar
						</Button>
					</ModalFooter>
				</>
			)}
		</Modal>
	);
};

export default QuestionLevelsForm;
