import React from "react";
import { Badge } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import classes from "../../midio.module.scss";
import { ReactComponent as EditIcon } from "assets/icons/editar.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/basura.svg";
import { isValidPermission } from "services/utils";
import NoData from "components/list/NoData/NoData";

const CourseTable = (props) => {
	const { editElement, deleteElement, data, permissions } = props;

	if (data && data.length === 0) {
		return <NoData text={"Una vez los usuarios se registren en un curso, podrá visualizar su información"} />;
	}

	return (
		<div>
			<Table className={classes.main_table} cellSpacing="0">
				<Thead>
					<Tr>
						<Th style={{ width: "20%" }}>Nombre</Th>
						<Th style={{ width: "20%" }}>Apellido Paterno</Th>
						<Th style={{ width: "20%" }}>Apellido Materno</Th>
						<Th style={{ width: "20%" }}>Email</Th>
						<Th style={{ width: "10%" }}>Cursos</Th>
						<Th style={{ width: "10%" }}>Acciones</Th>
					</Tr>
				</Thead>
				<Tbody>
					{data.map((item, i) => {
						return (
							<Tr key={i} className={classes.tr}>
								<Td className={classes.td}>
									<div className={`${classes.div_td} ${classes.first}`}>{item.name}</div>
								</Td>
								<Td className={classes.td}>
									<div className={`${classes.div_td}`}>{item.last_name}</div>
								</Td>
								<Td className={classes.td}>
									<div className={`${classes.div_td}`}>{item.last_name_mother}</div>
								</Td>
								<Td className={classes.td}>
									<div className={classes.div_td}>{item.email}</div>
								</Td>
								<Td className={classes.td}>
									<div className={classes.div_td}>
										{item.courses.map((course, index) => {
											return (
												<Badge key={index} color="primary" style={{ fontWeight: 500 }}>
													{course.name}
												</Badge>
											);
										})}
									</div>
								</Td>
								<Td className={classes.td}>
									<div className={`${classes.div_td} ${classes.icon_div} ${classes.last}`}>
										{isValidPermission("edit", permissions) && (
											<span className="table-actions" onClick={() => editElement(item)}>
												<EditIcon className={`${classes.icon} ${classes.edit}`} />
											</span>
										)}
										{isValidPermission("delete", permissions) && (
											<span className="table-actions icon trash" onClick={() => deleteElement(item)}>
												<DeleteIcon className={`${classes.icon} ${classes.delete}`} />
											</span>
										)}
									</div>
								</Td>
							</Tr>
						);
					})}
				</Tbody>
			</Table>
		</div>
	);
};

export default CourseTable;
