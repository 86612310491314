import React, { useState } from "react";
import { Col, Row } from "reactstrap";

import classes from "./answer.module.scss";
import EvaluationInCourseButton from "./EvaluationInCourseButton";
import EvaluationInCourseTime from "./EvaluationInCourseTime";
import EvaluationQuestion from "./EvaluationQuestion";

const EvaluationInCourse = ({ data, setData }) => {
	const [selectQuestion, setSelectQuestion] = useState(data && data.selectedQuestion ? data.selectedQuestion : 0);

	return (
		<Row className={classes.divInCourse} style={{ marginRight: "0px", marginLeft: "0px" }}>
			{/* Title */}
			<Col md={9} sm={6} className={classes.title}>
				<h4 style={{ marginBottom: "0px" }}>{`${data && data.course && data.course.name} - ${data && data.title}`}</h4>
				<span className={classes.subtitle}>
					{`${data && data.course && data.course.subject ? data.course.subject.name : ""} - ${
						data && data.course && data.course.level ? data.course.level.name : ""
					}`}
				</span>
				{(data.questions.filter((q) => q.answered).length * 100) / data.questions.length === 100 && (
					<div style={{ color: "#007933", fontSize: "16px" }}>
						Ya has terminado! Puedes revisar tus respuestas antes de finalizar la evaluación.
					</div>
				)}
				<div>
					<small>Completado {(data.questions.filter((q) => q.answered).length * 100) / data.questions.length} %</small>
					<div className={classes.div_complete}>
						<div
							className={`${classes.div_complete_active}`}
							style={{
								width: `${(data.questions.filter((q) => q.answered).length * 100) / data.questions.length}%`,
								backgroundColor:
									(data.questions.filter((q) => q.answered).length * 100) / data.questions.length > 50 ? "#2ecc71" : "#f39c12",
							}}
						></div>
					</div>
				</div>
			</Col>
			<EvaluationInCourseTime />
			<EvaluationInCourseButton data={data} selectQuestion={selectQuestion} setSelectQuestion={setSelectQuestion} />
			<EvaluationQuestion selectQuestion={selectQuestion} setSelectQuestion={setSelectQuestion} data={data} setData={setData} />
		</Row>
	);
};

export default EvaluationInCourse;
