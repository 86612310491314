import React from "react";
import { ReactComponent as NoDataSvg } from "assets/icons/nodata.svg";

import classes from "./NoData.module.scss";

const NoData = ({ text }) => {
	return (
		<div className={classes.div_main}>
			<NoDataSvg className={classes.icon} />

			{text && <span style={{ fontSize: "20px" }}>{text}</span>}
		</div>
	);
};

export default NoData;
