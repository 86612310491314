import React, { Fragment, useState, useEffect, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { ICONS, MENUITEMS } from "./menu";
import { ArrowRight, ArrowLeft, Grid } from "react-feather";
import { Link, useNavigate } from "react-router-dom";

import configDB from "data/customizer/config";
import * as IconFeathers from "react-feather";

const Sidebar = (props) => {
	let navigate = useNavigate();
	// eslint-disable-next-line
	const [initalState, setInitalState] = useState(true);
	const [mainmenu, setMainMenu] = useState([...MENUITEMS]);
	const [margin, setMargin] = useState(0);
	const [sidebartoogle, setSidebartoogle] = useState(true);
	const wrapper = useSelector((content) => content.Customizer.sidebar_types.type) || configDB.data.settings.sidebar.type;
	const width = useWindowSize();

	function useWindowSize() {
		const [size, setSize] = useState([0, 0]);
		useLayoutEffect(() => {
			function updateSize() {
				setSize(window.innerWidth);
			}
			window.addEventListener("resize", updateSize);
			updateSize();
			return () => window.removeEventListener("resize", updateSize);
		}, []);
		return size;
	}

	const handleScroll = () => {
		if (window.scrollY > 400) {
			if (
				configDB.data.settings.sidebar.type.split(" ").pop() === "material-type" ||
				configDB.data.settings.sidebar.type.split(" ").pop() === "advance-layout"
			)
				document.querySelector(".sidebar-main").className = "sidebar-main hovered";
		} else {
			document.querySelector(".sidebar-main").className = "sidebar-main";
		}
	};

	useEffect(() => {
		document.querySelector(".left-arrow").classList.add("d-none");
		const currentUrl = window.location.pathname;

		if (initalState) {
			let newMainMenu = [...mainmenu];

			newMainMenu.forEach((items, i) => {
				items.Items.forEach((screen_option, j) => {
					screen_option.children.forEach((screen, k) => {
						if (currentUrl.includes(screen.path)) {
							screen_option.children[k].active = true;
							items.Items[j].active = true;
						}
					});
				});
			});

			setMainMenu(newMainMenu);
		}

		window.addEventListener("scroll", handleScroll);
		handleScroll();
		setInitalState(false);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};

		// eslint-disable-next-line
	}, [mainmenu]);

	const setNavActive = (item) => {
		let newMainMenu = [...mainmenu];

		mainmenu.forEach((menuItems) => {
			menuItems.Items.forEach((Items) => {
				if (Items !== item) {
					Items.active = false;
					document.querySelector(".bg-overlay1").classList.remove("active");
				}
				if (Items.children && Items.children.includes(item)) {
					Items.active = true;
					document.querySelector(".sidebar-link").classList.add("active");
				}
				if (Items.children) {
					Items.children.forEach((submenuItems) => {
						if (submenuItems.children && submenuItems.children.includes(item)) {
							Items.active = true;
							submenuItems.active = true;
							return true;
						} else {
							return false;
						}
					});
				}
				return Items;
			});
			return menuItems;
		});
		item.active = !item.active;
		setMainMenu(newMainMenu);
	};

	const toggleNavMain = () => {
		let newMainMenu = [...mainmenu];

		newMainMenu.forEach((a) => {
			a.Items.forEach((Items) => {
				if (Items.active) {
					Items.active = false;
				}
				if (!Items.children) return false;
				Items.children.forEach((b) => {
					if (b.active) {
						b.active = false;
					}
					if (!b.children) return false;
					b.children.forEach((c) => {
						if (c.active) {
							c.active = false;
						}
					});
				});
			});
		});

		setMainMenu(newMainMenu);
		navigate("/dashboard");
	};

	const toggletNavActive = (item) => {
		let newMainMenu = [...mainmenu];

		if (window.innerWidth <= 991) {
			document.querySelector(".page-header").className = "page-header close_icon";
			document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper close_icon ";

			if (document.querySelector(".mega-menu-container")) {
				document.querySelector(".mega-menu-container").classList.remove("d-block");
			}

			if (item.type === "sub") {
				document.querySelector(".page-header").className = "page-header ";
				document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper ";
			}
		}

		if (!item.active) {
			newMainMenu.forEach((a) => {
				a.Items.filter((Items) => {
					if (a.Items.includes(item)) Items.active = false;
					if (!Items.children) return false;
					Items.children.forEach((b) => {
						if (Items.children.includes(item)) {
							b.active = false;
						}
						if (!b.children) return false;
						b.children.forEach((c) => {
							if (b.children.includes(item)) {
								c.active = false;
							}
						});
					});
					return Items;
				});
			});
		}

		item.active = true;
		setMainMenu(newMainMenu);
		navigate(item.path);
	};

	const scrollToRight = () => {
		if (margin <= -2598 || margin <= -2034) {
			if (width === 492) {
				setMargin(-3570);
			} else {
				setMargin(-3464);
			}
			document.querySelector(".right-arrow").classList.add("d-none");
			document.querySelector(".left-arrow").classList.remove("d-none");
		} else {
			setMargin((margin) => (margin += -width));
			document.querySelector(".left-arrow").classList.remove("d-none");
		}
	};

	const scrollToLeft = () => {
		if (margin >= -width) {
			setMargin(0);
			document.querySelector(".left-arrow").classList.add("d-none");
			document.querySelector(".right-arrow").classList.remove("d-none");
		} else {
			setMargin((margin) => (margin += width));
			document.querySelector(".right-arrow").classList.remove("d-none");
		}
	};

	const closeOverlay = () => {
		document.querySelector(".bg-overlay1").classList.remove("active");
		document.querySelector(".sidebar-link").classList.remove("active");
	};

	const activeClass = (title) => {
		document.querySelector(".item-menu-" + title).classList.add("active");
		document.querySelector(".bg-overlay1").classList.add("active");
	};

	const openCloseSidebar = () => {
		if (sidebartoogle) {
			setSidebartoogle(!sidebartoogle);
			document.querySelector(".page-header").className = "page-header close_icon";
			document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper close_icon ";
		} else {
			setSidebartoogle(!sidebartoogle);
			document.querySelector(".page-header").className = "page-header";
			document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper ";
		}
	};

	return (
		<Fragment>
			<div
				className={`bg-overlay1`}
				onClick={() => {
					closeOverlay();
				}}
			></div>
			<div className="sidebar-wrapper">
				<div className="logo-wrapper">
					<Link to={`/`}>
						<img className="img-fluid for-light" src={require("assets/images/logo/logo.png")} alt="Logo Midio" style={{ width: "8em" }} />
						<img
							className="img-fluid for-dark"
							src={require("assets/images/logo/logo_dark.png")}
							alt="Logo Midio"
							style={{ width: "8em" }}
						/>
					</Link>
					<div className="toggle-sidebar" onClick={() => openCloseSidebar()}>
						<Grid className="status_toggle middle sidebar-toggle" />
					</div>
				</div>
				<div className="logo-icon-wrapper">
					<Link to={`/dashboard`}>
						<img className="img-fluid" src={require("assets/images/logo/logo-icon.png")} alt="" />
					</Link>
				</div>
				<nav className="sidebar-main">
					<div className="left-arrow" onClick={scrollToLeft}>
						<ArrowLeft />
					</div>
					<div
						id="sidebar-menu"
						style={wrapper.split(" ").includes("horizontal-wrapper") ? { marginLeft: margin + "px" } : { margin: "0px" }}
					>
						<ul className="sidebar-links custom-scrollbar">
							<li className="back-btn">
								<div className="mobile-back text-right">
									<span>{"Back"}</span>
									<i className="fa fa-angle-right pl-2" aria-hidden="true"></i>
								</div>
							</li>
							<li
								className="sidebar-main-title"
								onClick={(event) => {
									event.preventDefault();
									toggleNavMain();
								}}
							>
								<div>
									<h6 className="lan-1" style={{ cursor: "pointer" }}>
										Principal
									</h6>
								</div>
							</li>

							{mainmenu.map((Item, i) => {
								let ItemIcon = "";
								return (
									<Fragment key={i}>
										{Item.Items.map((menuItem, i) => {
											ItemIcon = IconFeathers[menuItem.icon];

											return (
												<li className="sidebar-list" key={i}>
													{menuItem.type === "sub" ? (
														<a
															href="javascript"
															className={`sidebar-link sidebar-title item-menu-${menuItem.title} ${
																menuItem.active ? activeClass(menuItem.title) : ""
															}`}
															onClick={(event) => {
																event.preventDefault();
																setNavActive(menuItem);
															}}
														>
															{ItemIcon && <ItemIcon />}
															<span>{menuItem.title}</span>
															<div className="according-menu">
																{menuItem.active ? (
																	<i className="fa fa-angle-down"></i>
																) : (
																	<i className="fa fa-angle-right"></i>
																)}
															</div>
														</a>
													) : (
														""
													)}

													{menuItem.type === "link" ? (
														<Link
															to={menuItem.path + "/"}
															className={`sidebar-link sidebar-title link-nav  ${menuItem.active ? "active" : ""}`}
															onClick={() => toggletNavActive(menuItem)}
														>
															<menuItem.icon />
															<span>{menuItem.title}</span>
															{menuItem.badge ? <label className={menuItem.badge}>{menuItem.badgetxt}</label> : ""}
														</Link>
													) : (
														""
													)}

													{menuItem.children && (
														<div className={`sidebar-submenu ${menuItem.active ? "active" : ""}`}>
															{menuItem.children.map((childrenItem, index) => {
																return (
																	<div key={index} className="sidebar-submenu-item">
																		<div
																			className={`sidebar-submenu-link ${childrenItem.active ? "active" : ""}`}
																			onClick={() => toggletNavActive(childrenItem)}
																		>
																			<i
																				className={`${ICONS[childrenItem.title]} mr-2 icon`}
																				style={{ fontSize: "20px", fontWeight: "100", marginRight: "5px" }}
																			></i>
																			<span style={{ display: sidebartoogle ? "inline-block" : "none" }}>
																				{childrenItem.title}
																			</span>
																		</div>
																	</div>
																);
															})}
														</div>
													)}
												</li>
											);
										})}
									</Fragment>
								);
							})}
						</ul>
					</div>
					<div className="right-arrow" onClick={scrollToRight}>
						<ArrowRight />
					</div>
				</nav>
			</div>
		</Fragment>
	);
};

export default Sidebar;
