export const baseObject = {
	id: "",
	slug: "",
	username: "",
	name: "",
	email: "",
	password: "",
	confirm_password: "",
	photo: "",
	last_name: "",
	last_name_mother: "",
	academic_title: "",
	website: "",
	country_id: "",
	commune_id: "",
	address: "",
	about_me: "",
	courses: [],
	evaluations: [],
	select_courses: [],
};

export const base_filters = [
	{
		key: "filter_name",
		name: "Nombre",
		value: "",
		type: "text",
		options: [],
	},
	{
		key: "filter_course_id",
		name: "Curso",
		value: "",
		type: "select",
		options: [],
	},
];
