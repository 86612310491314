import React, { useState, useEffect, Fragment } from "react";
import { Container, Row, Col } from "reactstrap";
import fileDownload from "js-file-download";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import Breadcrumb from "layout/breadcrumb";
import Paginator from "components/list/Paginator";
import Search from "components/list/Search";
import CourseTable from "./table/CourseTable";
import Fetching from "components/fetching/Fetching";
import Confirm from "components/confirm/Confirm";
import BaseFilter from "components/filter/BaseFilter";

import { getToday } from "services/utils";
import api from "services/api";
import { base_filters, status } from "./course.constants";

import { HANDLE_GLOBAL_FETCHING } from "../../../redux/actionTypes";

const Course = () => {
	const dispatch = useDispatch();
	let navigate = useNavigate();

	const [isFetching, setIsFetching] = useState(true);
	const [page, setPage] = useState(1);
	const [data, setData] = useState([]);
	const [permissions, setPermissions] = useState([]);
	const [from, setFrom] = useState("");
	const [to, setTo] = useState("");
	const [total, setTotal] = useState("");
	const [lastPage, setLastPage] = useState("");
	const [showFilter, setShowFilter] = useState(false);

	const [selected, setSelected] = useState("");
	const [modal, setModal] = useState(false);

	const [filters, setFilters] = useState([...base_filters]);

	useEffect(() => {
		const reduceFilters = filters.reduce((obj, item) => {
			obj[item.key] = item.value;
			return obj;
		}, {});

		setIsFetching(true);
		api.get("/courses", {
			page,
			...reduceFilters,
		}).then((response) => {
			updateData(response);
		});
		// eslint-disable-next-line
	}, [page, filters.map((f) => f.value).join("|")]);

	const updateData = (response) => {
		const { courses, subjects, questions_level, permissions } = response;

		setPermissions(permissions);
		setData(courses.data);
		setFrom(courses.from);
		setTo(courses.to);
		setTotal(courses.total);
		setLastPage(courses.last_page);

		updateFilters(subjects, questions_level, status);

		setIsFetching(false);
	};

	const updateFilters = (subjects, levels, status) => {
		let newFilters = [...filters];

		let subjectKey = newFilters.findIndex((item) => item.key === "filter_subject_id");
		newFilters[subjectKey].options = subjects;

		let levelKey = newFilters.findIndex((item) => item.key === "filter_level_id");
		newFilters[levelKey].options = levels;

		let statusKey = newFilters.findIndex((item) => item.key === "filter_status_id");
		newFilters[statusKey].options = status;

		setFilters([...newFilters]);

		setIsFetching(false);
	};

	// Form
	const createElement = () => {
		navigate("/courses/create");
	};

	const editElement = (item) => {
		navigate(`/courses/${item.slug}/edit`);
	};

	const deleteElement = (item) => {
		setSelected(item.id);
		setModal(true);
	};

	const handleDelete = () => {
		const form = {
			selected,
		};

		api.post("/courses_delete", form).then((response) => {
			updateData(response);
			setSelected("");

			toast.success("Registro eliminado correctamente");
		});
	};

	const applyFilter = (newFilters) => {
		setFilters([...newFilters]);
		setShowFilter(false);
	};

	const handleExcel = () => {
		const filename = `Cursos ${getToday()}.xlsx`;

		const reduceFilters = filters.reduce((obj, item) => {
			obj[item.key] = item.value;
			return obj;
		}, {});

		dispatch({
			type: HANDLE_GLOBAL_FETCHING,
			payload: {
				isGlobalFetching: true,
				documentName: filename,
			},
		});

		api.getExcel("/courses/excel", { ...reduceFilters }).then((data) => {
			fileDownload(data, filename);

			dispatch({
				type: HANDLE_GLOBAL_FETCHING,
				payload: {
					isGlobalFetching: false,
					documentName: "",
				},
			});
		});
	};

	return (
		<Fragment>
			<Breadcrumb title={"Cursos"} parent="Midio" />
			<Container fluid={true}>
				<Row>
					<Search
						to={to}
						from={from}
						total={total}
						permissions={permissions}
						handleCreate={createElement}
						handleExport={handleExcel}
						haveFilter={true}
						showFilter={showFilter}
						setShowFilter={setShowFilter}
					/>

					{showFilter && <BaseFilter filters={filters} applyFilter={applyFilter} />}

					<Col sm={showFilter ? "9" : "12"} style={{ paddingRight: "0px", paddingLeft: "0px" }}>
						{isFetching ? (
							<Fetching />
						) : (
							<CourseTable editElement={editElement} deleteElement={deleteElement} data={data} permissions={permissions} />
						)}
					</Col>

					<Paginator page={page} setPage={setPage} lastPage={lastPage} total={total} />
				</Row>
			</Container>

			<Confirm modal={modal} setModal={setModal} callbackAction={handleDelete} />
		</Fragment>
	);
};

export default Course;
