import { combineReducers } from "redux";
import Customizer from "./customizer/reducer";
import Evaluation from "./evaluation/reducer";

const reducers = combineReducers({
	Customizer,
	Evaluation,
});

export default reducers;
