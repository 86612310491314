import React, { useState, useEffect } from "react";
import { Col } from "reactstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import classes from "./answer.module.scss";

import QuestionFormMultiple from "./Type/AnswerQuestionFormMultiple";
import QuestionFormTOF from "./Type/AnswerQuestionFormTOF";
import QuestionFormSentence from "./Type/AnswerQuestionFormSentence";
import QuestionFormOpen from "./Type/AnswerQuestionOpen";

import api from "services/api";

import Fetching from "components/fetching/Fetching";
import EvaluationFinish from "./EvaluationFinish";

const EvaluationQuestion = ({ selectQuestion, setSelectQuestion, data, setData }) => {
	const [question, setQuestion] = useState({});
	const evaluation = useSelector((context) => context.Evaluation);
	const navigate = useNavigate();

	const [isFetching, setIsFetching] = useState(false);
	const [finishModal, setFinishModal] = useState(false);

	useEffect(() => {
		if (question.id !== data.questions[selectQuestion].id) {
			setQuestion(data.questions && data.questions[selectQuestion]);
		}

		// eslint-disable-next-line
	}, [selectQuestion, question]);

	const components = {
		QuestionFormMultiple: <QuestionFormMultiple question={question} setQuestion={setQuestion} data={data} setData={setData} />,
		QuestionFormTOF: <QuestionFormTOF question={question} setQuestion={setQuestion} data={data} setData={setData} />,
		QuestionFormSentence: <QuestionFormSentence question={question} setQuestion={setQuestion} data={data} setData={setData} />,
		QuestionFormOpen: <QuestionFormOpen question={question} setQuestion={setQuestion} data={data} setData={setData} />,
	};

	const handleConfirm = () => {
		let newQuestions = [...data.questions];
		if (newQuestions[selectQuestion].selected) {
			newQuestions[selectQuestion].answered = 1;
		}

		if (selectQuestion !== data.questions.length - 1) {
			setSelectQuestion(selectQuestion + 1);
		}

		api.post("/save_answer", {
			data,
			question,
		});
	};

	const handleFinish = () => {
		setFinishModal(true);
	};

	const confirmFinish = () => {
		setFinishModal(false);
		setIsFetching(true);
		api.post("/finish_quiz", { evaluation }).then((response) => {
			setIsFetching(false);
			navigate("/evaluations/finish/" + response.evaluation.slug);
		});
	};

	if (isFetching) {
		return <Fetching />;
	}

	return (
		<Col md={12}>
			<div className={classes.divCarousel} style={{ display: "flex" }}>
				{question &&
					question.files &&
					question.files.length !== 0 &&
					question.files.map((file, j) => {
						return <img key={j} src={file.src} style={{ maxWidth: "15em", padding: "5px" }} alt={file.altText} />;
					})}
			</div>

			<div className={classes.divQuestion}>
				{question && (
					<div
						dangerouslySetInnerHTML={{
							__html: question.statement,
						}}
					></div>
				)}
			</div>

			{question &&
				question.type &&
				React.cloneElement(components[question.type.component], {
					question,
					setQuestion,
					data,
				})}

			<div className={classes.divConfirm}>
				<div className={`${classes.button} ${classes.finish}`} onClick={() => handleFinish()}>
					Finalizar Evaluación
				</div>

				<div className={`${classes.button} ${classes.confirm}`} onClick={() => handleConfirm()}>
					Confirmar
				</div>
			</div>

			<EvaluationFinish showModal={finishModal} setShowModal={setFinishModal} handleFinish={confirmFinish} />
		</Col>
	);
};

export default EvaluationQuestion;
