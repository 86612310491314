import React, { useState, useEffect } from "react";

import { Col, Label, Input } from "reactstrap";
import Select from "react-select";

const Fields = (props) => {
	const { data, setData, countries, allCommunes, customers } = props;
	const [communes, setCommunes] = useState([]);

	const handleCountry = (value) => {
		const filter = allCommunes.filter((c) => Number(c.country_id) === Number(value.value));
		setData({
			...data,
			country_id: value.value,
		});
		setCommunes(filter);
	};

	const handleCommune = (value) => {
		setData({
			...data,
			commune_id: value.value,
		});
	};

	const handleCustomer = (value) => {
		let newUserRole = [...data.user_roles];
		newUserRole.forEach((role, index) => {
			newUserRole[index].assigned = false;
		});

		setData({
			...data,
			customer_id: value.value,
			user_roles: newUserRole,
		});
	};

	useEffect(() => {
		if (data.country_id) {
			setCommunes(allCommunes.filter((c) => Number(c.country_id) === Number(data.country_id)));
		}
		// eslint-disable-next-line
	}, [data]);

	return (
		<div className="form-tab-detail">
			<form autoComplete="off">
				<div className="row">
					<Col md="6 mb-2">
						<Label htmlFor="name">Nombre</Label>
						<Input
							className="form-control"
							name="name"
							type="text"
							value={data ? data.name : ""}
							onChange={(e) =>
								setData({
									...data,
									name: e.target.value,
								})
							}
						/>
					</Col>
					<Col md="6 mb-2">
						<Label htmlFor="email">Email</Label>
						<Input
							className="form-control"
							name="email"
							type="text"
							value={data ? data.email : ""}
							onChange={(e) =>
								setData({
									...data,
									email: e.target.value,
									username: e.target.value,
								})
							}
							autoComplete="new-password"
						/>
					</Col>
				</div>

				<div className="row">
					<Col md="6 mb-2">
						<Label htmlFor="name">Apellido Paterno</Label>
						<Input
							className="form-control"
							name="last_name"
							type="text"
							value={data ? data.last_name : ""}
							onChange={(e) =>
								setData({
									...data,
									last_name: e.target.value,
								})
							}
						/>
					</Col>
					<Col md="6 mb-2">
						<Label htmlFor="email">Apellido Materno</Label>
						<Input
							className="form-control"
							name="last_name_mother"
							type="text"
							value={data ? data.last_name_mother : ""}
							onChange={(e) =>
								setData({
									...data,
									last_name_mother: e.target.value,
								})
							}
						/>
					</Col>
				</div>

				<div className="row">
					<Col md="6 mb-2" style={{ display: "none" }}>
						<Label htmlFor="username">Nombre de Usuario</Label>
						<Input className="form-control" name="username" type="text" value={data && data.username} disabled={true} />
					</Col>

					<Col md="6 mb-2">
						<Label htmlFor="customer_id">Cliente</Label>
						<Select
							defaultValue={data ? customers.filter((c) => Number(c.value) === Number(data.customer_id)) : ""}
							placeholder={<div>Seleccione......</div>}
							options={customers}
							onChange={(value) => handleCustomer(value)}
						/>
					</Col>

					<Col md="6 mb-2">
						<Label htmlFor="customer_id">Roles</Label>
						<Select
							value={data && data.user_roles && data.user_roles.filter((rol) => rol.assigned === true)}
							isMulti
							placeholder={<div>Seleccione</div>}
							options={data.user_roles ? data.user_roles.filter((ur) => Number(ur.customer_id) === Number(data.customer_id)) : []}
							onChange={(value) => {
								let newUserRole = [...data.user_roles];
								newUserRole.forEach((selectRole, index) => {
									newUserRole[index].assigned = false;
									const findRole = value.find((role) => role.id === selectRole.id);
									if (findRole) {
										newUserRole[index].assigned = true;
									}
								});
								setData({
									...data,
									user_roles: newUserRole,
								});
							}}
						/>
					</Col>
				</div>

				<div className="row">
					<Col md="6 mb-2">
						<Label htmlFor="password">Contraseña</Label>
						<Input
							className="form-control"
							name="password"
							type="password"
							value={data ? data.password : ""}
							onChange={(e) =>
								setData({
									...data,
									password: e.target.value,
								})
							}
						/>
					</Col>

					<Col md="6 mb-2">
						<Label htmlFor="confirm_password">Confirmar Contraseña</Label>
						<Input
							className="form-control"
							name="confirm_password"
							type="password"
							value={data ? data.confirm_password : ""}
							onChange={(e) =>
								setData({
									...data,
									confirm_password: e.target.value,
								})
							}
						/>
					</Col>
				</div>

				<div className="row">
					<Col md="6 mb-2">
						<Label htmlFor="country_id">País</Label>
						<Select
							defaultValue={data.country_id ? countries.filter((c) => Number(c.value) === Number(data.country_id)) : ""}
							placeholder={<div>Seleccione......</div>}
							options={countries}
							onChange={(value) => handleCountry(value)}
						/>
					</Col>

					<Col md="6 mb-2">
						<Label htmlFor="commune_id">Comuna</Label>
						<Select
							defaultValue={data.commune_id ? allCommunes.filter((c) => Number(c.value) === Number(data.commune_id)) : ""}
							placeholder={<div>Seleccione......</div>}
							options={communes}
							onChange={(value) => handleCommune(value)}
						/>
					</Col>
				</div>

				<div className="row">
					<Col md="12 mb-3">
						<Label htmlFor="address">Dirección</Label>
						<Input
							className="form-control"
							name="address"
							type="text"
							value={data ? data.address : ""}
							onChange={(e) =>
								setData({
									...data,
									address: e.target.value,
								})
							}
						/>
					</Col>
				</div>

				<div className="row">
					<Col md="6 mb-2">
						<Label style={{ marginRight: "1em" }} htmlFor="active">
							Activo
						</Label>
						<Input
							className="checkbox_animated"
							id={`user_active`}
							type="checkbox"
							checked={data ? data.active : false}
							onChange={(e) => {
								setData({
									...data,
									active: e.target.checked,
								});
							}}
						/>
					</Col>
				</div>
			</form>
		</div>
	);
};

export default Fields;
