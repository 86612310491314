import React, { useState } from "react";
import classes from "../evaluation.module.scss";
import EvaluationReviewFinish from "./EvaluationReviewFinish";

const EvaluationReviewHeader = ({ data, setData, setIsFetching }) => {
	const [showModal, setShowModal] = useState(false);

	const handleFinish = () => {
		setShowModal(true);
	};

	return (
		<div>
			<h4 style={{ marginBottom: "0px", marginTop: "0.5em" }}>
				Revisón de preguntas{" "}
				<span
					style={{
						color:
							data.questions.filter((question) => question.pivot.confirm === 1).length === data.questions.length
								? "#00a59b"
								: "#dc3545",
					}}
				>
					({data.questions.filter((question) => question.pivot.confirm === 1).length}/{data.questions.length})
				</span>
			</h4>
			{data.questions.filter((question) => question.pivot.confirm === 1).length === data.questions.length && (
				<div className={classes.question_finish} onClick={() => handleFinish()}>
					<div className={classes.button_finish}>Finalizar Revisión</div>
				</div>
			)}
			<small>
				Antes de enviar tu pregunta, asegúrate de que sea clara y completa para obtener una respuesta precisa. ¿Necesitas afinarla? El botón
				de 'Remplazar pregunta' es tu herramienta clave. Úsalo para transformar tus dudas con un toque de ingenio, perfeccionando así tu
				evaluación.
			</small>

			<EvaluationReviewFinish
				data={data}
				setData={setData}
				showModal={showModal}
				setShowModal={setShowModal}
				setGlobalIsFetching={setIsFetching}
			/>
		</div>
	);
};

export default EvaluationReviewHeader;
