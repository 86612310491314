import React from "react";

import { Row, Col, Input } from "reactstrap";

const FormPermissions = (props) => {
	const { data, setData } = props;

	const handleChange = (e, i, j) => {
		let newData = JSON.parse(JSON.stringify(data));
		newData.permissions[i].details[j].value = e.target.checked;
		setData(newData);
	};

	const handleChangeAll = (e, permission_id) => {
		let newData = JSON.parse(JSON.stringify(data));
		newData.permissions.map((screen) => {
			screen.details.map((detail) => {
				if (detail.permission_id === permission_id) {
					if (e.target.checked) {
						detail.value = true;
					} else {
						detail.value = false;
					}
				}
				return detail;
			});
			return screen;
		});
		setData(newData);
	};

	return (
		<div className="form-tab-detail">
			<Row>
				<Col sm="12" style={{ padding: "10px" }}>
					<table style={{ width: "100%", textAlign: "center" }}>
						<thead>
							<tr>
								<th>Aplicación</th>
								<th>Crear</th>
								<th>Editar</th>
								<th>Ver</th>
								<th>Eliminar</th>
								<th>Exportar</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									<strong>Seleccionar todos</strong>
								</td>
								<td>
									<Input
										className="checkbox_animated"
										id={`create_all`}
										type="checkbox"
										checked={data.permissions.every((screen) => screen.details[0].value) ? true : false}
										onChange={(e) => handleChangeAll(e, 1)}
									/>
								</td>
								<td>
									<Input
										className="checkbox_animated"
										id={`edit_all`}
										type="checkbox"
										checked={data.permissions.every((screen) => screen.details[1].value) ? true : false}
										onChange={(e) => handleChangeAll(e, 2)}
									/>
								</td>
								<td>
									<Input
										className="checkbox_animated"
										id={`show_all`}
										type="checkbox"
										checked={data.permissions.every((screen) => screen.details[2].value) ? true : false}
										onChange={(e) => handleChangeAll(e, 3)}
									/>
								</td>
								<td>
									<Input
										className="checkbox_animated"
										id={`delete_all`}
										type="checkbox"
										checked={data.permissions.every((screen) => screen.details[3].value) ? true : false}
										onChange={(e) => handleChangeAll(e, 4)}
									/>
								</td>
								<td>
									<Input
										className="checkbox_animated"
										id={`export_all`}
										type="checkbox"
										checked={data.permissions.every((screen) => screen.details[4].value) ? true : false}
										onChange={(e) => handleChangeAll(e, 5)}
									/>
								</td>
							</tr>
							<tr>
								<td colSpan={7}>
									<hr style={{ margin: "5px 0" }} />
								</td>
							</tr>
							{data &&
								data.permissions.map((screen, i) => {
									return (
										<tr key={i}>
											<td style={{ textAlign: "left" }}>{screen.screen}</td>
											{screen.details.map((detail, j) => {
												return (
													<td key={j}>
														<Input
															className="checkbox_animated"
															id={`screen_${i}${j}`}
															type="checkbox"
															checked={detail.value}
															onChange={(e) => handleChange(e, i, j)}
														/>
													</td>
												);
											})}
										</tr>
									);
								})}
						</tbody>
					</table>
				</Col>
			</Row>
		</div>
	);
};

export default FormPermissions;
