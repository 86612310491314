import React from "react";
import { Row, Col } from "reactstrap";

import classes from "../course.module.scss";

const CourseTitle = ({ course }) => {
	return (
		<Row>
			<Col md={12} className={classes.courseHeaderContainerSpan} style={{ paddingLeft: "5px", paddingRight: "5px" }}>
				<span className={`${classes.courseHeaderSpan} ${classes.subject}`}>{course.subject && course.subject.name}</span>
				<span className={`${classes.courseHeaderSpan} ${classes.level}`}>{course.level && course.level.name}</span>
			</Col>

			<Col md={12}>
				<h4 style={{ color: "#243280" }}>
					<div
						dangerouslySetInnerHTML={{
							__html: course.description,
						}}
					></div>
				</h4>
			</Col>
		</Row>
	);
};

export default CourseTitle;
