export const baseObject = {
	id: "",
	name: "",
	subject_id: "",
	level_id: "",
	code: "",
	password: "",
	confirm_password: "",
	description: "",
	slug: "",
	finalized_at: "",
	students: [],
	evaluations: [],
};

export const registerCourse = {
	code: "",
	password: "",
};

export const status = [
	{
		value: "in_progess",
		label: "En Progreso",
	},
	{
		value: "finished",
		label: "Finalizado",
	},
];

export const base_filters = [
	{
		key: "filter_subject_id",
		name: "Asignatura",
		value: "",
		type: "select",
		options: [],
	},
	{
		key: "filter_level_id",
		name: "Nivel de Pregunta",
		value: "",
		type: "select",
		options: [],
		filter_by: "filter_subject_id",
	},
	{
		key: "filter_status_id",
		name: "Estado",
		value: "",
		type: "select",
		options: [],
	},
	{
		key: "filter_search",
		name: "Buscar",
		value: "",
		type: "text",
		options: [],
	},
];
