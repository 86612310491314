import React from "react";

import { Input } from "reactstrap";

import classes from "../answer.module.scss";

const AnswerQuestionFormSentence = ({ question, setQuestion, data, setData }) => {
	const handleChange = (value) => {
		const findIndex = data.questions.findIndex((q) => q.id === question.id);
		data.questions[findIndex].selected = {
			answer: value,
		};
		setData({
			...data,
			questions: data.questions,
		});

		setQuestion({
			...question,
			selected: {
				answer: value,
			},
		});
	};

	return (
		<div className={classes.questionSentence}>
			<Input
				className="form-control"
				type="text"
				placeholder=""
				value={question && question.selected ? question.selected.answer : ""}
				onChange={(e) => handleChange(e.target.value)}
			/>
		</div>
	);
};

export default AnswerQuestionFormSentence;
