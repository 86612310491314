import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Clock } from "react-feather";
import { useNavigate, useLocation } from "react-router-dom";
import _ from "lodash";

import classes from "./app.module.scss";
import { TIME_EVALUATION } from "../redux/actionTypes";

import api from "services/api";

const EvaluationInCourse = () => {
	let navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const evaluation = useSelector((content) => content.Evaluation);

	const [loadFinish, setLoadFinish] = useState(false);

	useEffect(() => {
		const interval = setInterval(() => {
			const now = new Date().getTime();
			const endTimestamp = parseInt(localStorage.getItem("evaluationEnd"), 10);
			const secondsLeft = Math.max(Math.floor((endTimestamp - now) / 1000), 0);

			if (secondsLeft === 0) {
				clearInterval(interval);
				if (!loadFinish && evaluation.evaluation_id) {
					setLoadFinish(true);
					api.post("/finish_quiz", { evaluation }).then((response) => {
						setLoadFinish(false);
						navigate("/evaluations/finish/" + response.evaluation.slug);
					});
				}
			} else {
				dispatch({
					type: TIME_EVALUATION,
					payload: { seconds: secondsLeft },
				});
			}
		}, 1000);

		if (!evaluation.evaluation_id) {
			clearInterval(interval);
		}

		return () => clearInterval(interval);

		// eslint-disable-next-line
	}, [dispatch, loadFinish, evaluation.evaluation_id]);

	let second = evaluation.seconds;
	const hour = _.floor(second / 3600 >= 1 ? second / 3600 : 0);

	second = hour > 0 ? second - hour * 3600 : second;

	const minute = _.floor(second / 60 >= 1 ? second / 60 : 0);

	second = minute > 0 ? second - minute * 60 : second;

	const pad = (num, size) => {
		num = num.toString();
		while (num.length < size) num = "0" + num;
		return num;
	};

	return (
		<>
			{evaluation.start && !location.pathname.includes("evaluations/run") && (
				<div className={classes.div_evaluation} onClick={() => navigate("/evaluations/run/" + evaluation.slug)}>
					<div className={classes.icon}>
						<Clock className={classes.icon_feather} />
					</div>
					<div className={classes.content_evaluation}>
						<small className={classes.pretitle}>
							{evaluation.course_name} ({evaluation.course_code})
						</small>
						<div className={classes.title}>{evaluation.evaluation_title}</div>

						<div className={classes.time}>
							<div className={classes.globalTime}>{`${pad(hour, 2)}:${pad(minute, 2)}:${pad(second, 2)}`}</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default EvaluationInCourse;
