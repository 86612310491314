export const baseObject = {
	id: "",
	slug: "",
	title: "",
	description: "",
	minutes: "",
	initial_date: "",
	finish_date: "",
	courseId: "",
	subject_id: "",
	level_id: "",
	quantity_questions: "",
	categories: [],
	contents: [],
	objectives: [],
	questions: [],
};

export const base_filters = {
	search: "",
	name: "",
};
