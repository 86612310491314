import React from "react";
import { Col } from "reactstrap";

import { ReactComponent as FilterIcon } from "assets/fonts/feather/filter.svg";

import classes from "./filter.module.scss";

const Filter = ({ showFilter, setShowFilter }) => {
	return (
		<Col sm="1">
			<div className={classes.div} onClick={() => setShowFilter(!showFilter)}>
				<FilterIcon className={`${classes.icon} ${showFilter && classes.active}`} />
			</div>
		</Col>
	);
};

export default Filter;
