import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import _ from "lodash";
import "react-circular-progressbar/dist/styles.css";

import Breadcrumb from "layout/breadcrumb";

import api from "services/api";

import Fetching from "components/fetching/Fetching";

import classes from "./evaluationFinish.module.scss";

import { INITIAL_EVALUATION } from "../../../../redux/actionTypes";
import EvaluationReviewQuestion from "../review/EvaluationReviewQuestion";
import EvaluationFinishConfirm from "./EvaluationFinishConfirm";

const EvaluationFinish = () => {
	const dispatch = useDispatch();
	const { slug, user_slug } = useParams();

	const [fetching, setFetching] = useState(true);
	const [showConfirm, setShowConfirm] = useState(false);
	const [percentage, setPercentage] = useState(0);
	const [evaluation, setEvaluation] = useState({});
	const [user, setUser] = useState({});
	const [isTeacher, setIsTeacher] = useState(false);

	const [successQty, setSuccessQty] = useState(0);
	const [errorQty, setErrorQty] = useState(0);
	const [pendingQty, setPendingQty] = useState(0);

	const styles = {
		rotation: 0.25,
		strokeLinecap: "butt",
		textSize: "16px",
		pathTransitionDuration: 0.5,
		pathColor: `rgba(0, 165, 141, ${percentage / 100})`,
		textColor: "#00a58d",
		trailColor: "#d6d6d6",
		backgroundColor: "#3ec73e",
	};

	useEffect(() => {
		dispatch({
			type: INITIAL_EVALUATION,
		});

		if (_.isEmpty(evaluation)) {
			api.get("/evaluation", { slug, user_slug }).then((response) => {
				if (response && response.evaluation) {
					setFetching(false);
					setEvaluation(response.evaluation);
					setUser(response.user);

					let sQty = 0;
					let eQty = 0;
					let pQty = 0;
					response.evaluation.questions.forEach((question) => {
						if (question.selected) {
							if (question.selected) {
								if (question.selected.is_correct) {
									sQty += 1;
								} else {
									eQty += 1;
								}
							} else {
								pQty += 1;
							}
						} else {
							eQty += 1;
						}
					});

					setPercentage(Math.round((sQty * 100) / response.evaluation.questions.length));
					setPendingQty(pQty);
					setErrorQty(eQty);
					setSuccessQty(sQty);
				}
			});
		} else {
			let sQty = 0;
			let eQty = 0;
			let pQty = 0;
			evaluation.questions.forEach((question) => {
				if (question.selected) {
					if (question.selected) {
						if (question.selected.is_correct) {
							sQty += 1;
						} else {
							eQty += 1;
						}
					} else {
						pQty += 1;
					}
				} else {
					eQty += 1;
				}
			});

			setPercentage(Math.round((sQty * 100) / evaluation.questions.length));
			setPendingQty(pQty);
			setErrorQty(eQty);
			setSuccessQty(sQty);

			setIsTeacher(Number(localStorage.getItem("user_id")) === Number(evaluation.teacher_id));
		}

		// eslint-disable-next-line
	}, [slug, user_slug, evaluation]);

	if (fetching) {
		return <Fetching />;
	}

	return (
		<Fragment>
			<Breadcrumb title={evaluation ? evaluation.title : "Evaluación"} parent="Evaluaciones" />

			{Number(localStorage.getItem("user_id")) === Number(user.id) && (
				<>
					<div className={classes.title}>
						<div>
							Curso{" - "}
							{evaluation && evaluation.course ? evaluation.course.name : ""}
						</div>
						<div className={classes.subtitle}>Evaluación </div>
						<div className={classes.title_evaluation}>{evaluation ? evaluation.title : ""}</div>
					</div>

					<div className={classes.graph}>
						<div style={{ width: "15em" }}>
							<CircularProgressbar styles={buildStyles(styles)} value={percentage} text={`${percentage}%`} />
						</div>
					</div>
				</>
			)}

			<div className={classes.details}>
				<div className={classes.is_question}>{evaluation && evaluation.questions && `${evaluation.questions.length} preguntas en total`}</div>
				<div className={classes.is_pending}>{`${pendingQty} preguntas pendientes de revisión`}</div>
				<div className={classes.is_correct}>{`${successQty} preguntas correctas`}</div>
				<div className={classes.is_incorrect}>{`${errorQty} preguntas incorrectas`}</div>
			</div>

			{isTeacher && (
				<div className={classes.question_finish} onClick={() => setShowConfirm(true)}>
					<div className={classes.button_finish}>Finalizar Revisión</div>
				</div>
			)}

			<EvaluationReviewQuestion data={evaluation} setData={setEvaluation} typesQuestion={[]} divReview={null} />

			<EvaluationFinishConfirm data={evaluation} user={user} showModal={showConfirm} setShowModal={setShowConfirm} />
		</Fragment>
	);
};

export default EvaluationFinish;
