import React, { useState, useEffect } from "react";
import { Row, Col, Label, Input, Button } from "reactstrap";
import Select from "react-select";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import classes from "../course.module.scss";

const FormFields = (props) => {
	const { data, setData, description, setDescription, questionsLevel, subjects, setFinishCourse, handleSave } = props;

	const [levelQuestions, setlevelQuestions] = useState(questionsLevel);
	const [showPassword, setShowPassword] = useState(false);

	useEffect(() => {
		const levels = questionsLevel.filter((ql) => Number(ql.subject_id) === Number(data.subject_id));
		setlevelQuestions(levels);
		// eslint-disable-next-line
	}, [data]);

	const handleLevel = (value) => {
		setData({
			...data,
			level_id: value.value,
		});
	};

	const handleSubject = (value) => {
		const levels = questionsLevel.filter((ql) => Number(ql.subject_id) === Number(data.subject_id));

		setlevelQuestions(levels);

		setData({
			...data,
			subject_id: value.value,
		});
	};

	const changeDescription = (event, editor) => {
		const newDescription = editor.getData();

		setDescription(newDescription);
	};

	return (
		<>
			<div className="form-tab-detail">
				<form autoComplete="off">
					<div className="row">
						<Col md="6 mb-2">
							<Label htmlFor="name">Nombre</Label>
							<Input
								className="form-control"
								name="name"
								type="text"
								value={data && data.name}
								onChange={(e) =>
									setData({
										...data,
										name: e.target.value,
									})
								}
							/>
						</Col>

						<Col md="6 mb-2">
							<Label htmlFor="code">Código</Label>
							<Input
								className="form-control"
								name="code"
								type="text"
								value={data && data.code}
								onChange={(e) =>
									setData({
										...data,
										code: e.target.value,
									})
								}
								disabled={data.id ? true : false}
							/>
						</Col>
					</div>

					<div className="row">
						<Col md="6 mb-2">
							<Label htmlFor="subject_id">Asignatura</Label>
							<Select
								defaultValue={data.subject_id ? subjects.filter((c) => Number(c.value) === Number(data.subject_id)) : ""}
								placeholder={<div>Seleccione...</div>}
								options={subjects}
								onChange={(value) => handleSubject(value)}
								isDisabled={data.id ? true : false}
							/>
						</Col>
						<Col md="6 mb-2">
							<Label htmlFor="level_id">Nivel</Label>
							<Select
								defaultValue={data.level_id ? levelQuestions.filter((c) => Number(c.value) === Number(data.level_id)) : ""}
								placeholder={<div>Seleccione...</div>}
								options={levelQuestions}
								onChange={(value) => handleLevel(value)}
								isDisabled={data.id ? true : false}
							/>
						</Col>
					</div>

					<div className="row">
						<Col md="12 mb-3">
							<Label htmlFor="description">Descripción</Label>
							<CKEditor
								editor={ClassicEditor}
								onChange={changeDescription}
								config={{
									toolbar: ["heading", "|", "bold", "italic", "underline", "bulletedList", "numberedList", "link"],
									placeholder: "",
								}}
								data={description ? description : ""}
								disabled={data.id ? true : false}
							/>
						</Col>
					</div>

					<hr />

					<div className="row">
						<Col md="6 mb-2">
							<Label htmlFor="password">Contraseña</Label>
							<div style={{ display: "flex" }}>
								<Input
									className="form-control"
									type={showPassword ? "text" : "password"}
									value={data && data.password}
									onChange={(e) =>
										setData({
											...data,
											password: e.target.value,
										})
									}
									autoComplete="new-password"
									disabled={data.id ? true : false}
									style={{ width: data.id ? "60%" : "100%" }}
								/>
								{data.id && (
									<div style={{ width: "35%", display: "flex", justifyContent: "center", alignItems: "center" }}>
										<Button color="primary" onClick={() => setShowPassword(!showPassword)}>
											{showPassword ? "Ocultar Contraseña" : "Mostrar Contraseña"}
										</Button>
									</div>
								)}
							</div>
						</Col>
						{!data.id && (
							<Col md="6 mb-2">
								<Label htmlFor="confirm_password">Confirmar Contraseña</Label>
								<Input
									className="form-control"
									name="confirm_password"
									type="password"
									value={data && data.confirm_password}
									onChange={(e) =>
										setData({
											...data,
											confirm_password: e.target.value,
										})
									}
									autoComplete="new-password"
								/>
							</Col>
						)}
					</div>
				</form>
			</div>
			{!data.finalized_at && (
				<Row>
					<Col className={classes.rowAction}>
						{data.id && (
							<Button className={classes.buttonAction} color="danger" onClick={() => setFinishCourse(true)}>
								Finalizar Curso
							</Button>
						)}

						<Button className={classes.buttonAction} color="primary" onClick={() => handleSave()}>
							Guardar
						</Button>
					</Col>
				</Row>
			)}
		</>
	);
};

export default FormFields;
