import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, Button } from "reactstrap";
import { useNavigate } from "react-router-dom";

import Fetching from "components/fetching/Fetching";

import { baseObject } from "../evaluation.constants";

import api from "services/api";
import EvaluationStepTitle from "./EvaluationStepTitle";
import EvaluationStepDescription from "./EvaluationStepDescription";
import EvaluationStepTime from "./EvaluationStepTime";
import EvaluationStepCreate from "./EvaluationStepCreate";

const EvaluationForm = (props) => {
	let navigate = useNavigate();
	const { form, setForm, courseId, evaluationSlug } = props;

	const [isFetching, setIsFetching] = useState(true);
	const [isSave, setIsSave] = useState(false);
	const [step, setStep] = useState(1);
	const [data, setData] = useState({ ...baseObject, courseId: courseId || "" });

	const handleClose = () => {
		setData({ ...baseObject, courseId: "" });
		navigate(-1);
		setForm(false);
	};

	const handleSave = (newStep) => {
		setStep(newStep);

		const form = {
			...data,
			categories: data.categories.filter((c) => c.selected),
			contents: data.contents.filter((c) => c.selected),
			objectives: data.objectives.filter((c) => c.selected),
			courses: [],
		};

		saveEvaluations(form, newStep);
	};

	const saveEvaluations = (form, newStep, retryCount = 0) => {
		setIsSave(false);
		api.post("/evaluations_save", form)
			.then((response) => {
				setIsSave(false);
				if (!data.id && response && response.evaluation) {
					let newData = {
						...data,
						id: response.evaluation.id,
						slug: response.evaluation.slug,
					};
					setData(newData);
				}

				if (response.evaluation.state_id !== 4 && response.realQuestions.quantity < response.evaluation.quantity_questions) {
					if (retryCount < 5) {
						saveEvaluations(form, retryCount + 1);
					} else {
						console.error("Se ha superado el número de intentos para guardar la evaluación");
					}
				}
				if (response.realQuestions.quantity >= response.evaluation.quantity_questions) {
					window.location.href = `/evaluations/review/${response.evaluation.slug}`;
				}
			})
			.catch((e) => {
				setIsSave(false);
				console.error(e);
			});
	};

	useEffect(() => {
		setIsFetching(true);
		if (form) {
			let newData = { ...baseObject, courseId, slug: evaluationSlug || "" };
			api.get("/evaluation", { ...newData }).then((response) => {
				setData({
					...data,
					id: response.evaluation?.id || "",
					title: response.evaluation?.title || "",
					description: response.evaluation?.description || "",
					minutes: response.evaluation?.minutes || "",
					initial_date: response.evaluation?.initial_date || "",
					finish_date: response.evaluation?.finish_date || "",
					courseId: courseId ? courseId : response.evaluation?.course_id || "",
					categories: response.categories,
					contents: response.contents,
					objectives: response.objectives,
					courses: response.courses,
					subject_id: response.subject_id,
					level_id: response.level_id,
					quantity_questions: response.evaluation?.quantity_questions || "",
				});
				setIsFetching(false);
			});
		}

		// eslint-disable-next-line
	}, [form, courseId, evaluationSlug]);

	return (
		<Modal isOpen={form} toggle={handleClose} size="lg" backdrop="static">
			{isFetching ? (
				<Fetching />
			) : (
				<>
					<ModalBody>
						{step === 1 && <EvaluationStepTitle data={data} setData={setData} isSave={isSave} handleSave={handleSave} />}
						{step === 2 && <EvaluationStepDescription data={data} setData={setData} isSave={isSave} handleSave={handleSave} />}
						{step === 3 && <EvaluationStepTime data={data} setData={setData} isSave={isSave} handleSave={handleSave} />}
						{step === 4 && <EvaluationStepCreate data={data} />}
					</ModalBody>
					{step !== 4 && (
						<ModalFooter>
							<Button disabled={isSave} color="secondary" onClick={handleClose}>
								Cancelar
							</Button>
						</ModalFooter>
					)}
				</>
			)}
		</Modal>
	);
};

export default EvaluationForm;
