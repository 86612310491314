import React from "react";

import { Row, Col, Card, CardHeader, CardBody, CardFooter, Label, Input, FormGroup } from "reactstrap";

const FormUser = (props) => {
	const { data, setData, handleSave } = props;

	return (
		<Col xl="8">
			<Card>
				<CardHeader>
					<h4 className="card-title mb-0">Perfil</h4>
					<div className="card-options">
						<a className="card-options-collapse" href="#javascript">
							<i className="fe fe-chevron-up"></i>
						</a>
						<a className="card-options-remove" href="#javascript">
							<i className="fe fe-x"></i>
						</a>
					</div>
				</CardHeader>
				<CardBody>
					<Row>
						<Col md="6">
							<FormGroup>
								<Label className="form-label">Apellido Paterno</Label>
								<Input
									className="form-control"
									type="text"
									value={data.last_name}
									onChange={(e) =>
										setData({
											...data,
											last_name: e.target.value,
										})
									}
								/>
							</FormGroup>
						</Col>
						<Col md="6">
							<FormGroup>
								<Label className="form-label">Apellido Materno</Label>
								<Input
									className="form-control"
									type="text"
									value={data.last_name_mother}
									onChange={(e) =>
										setData({
											...data,
											last_name_mother: e.target.value,
										})
									}
								/>
							</FormGroup>
						</Col>
						<Col md="6">
							<FormGroup>
								<Label className="form-label">Título Académico</Label>
								<Input
									className="form-control"
									type="text"
									value={data.academic_title}
									onChange={(e) =>
										setData({
											...data,
											academic_title: e.target.value,
										})
									}
								/>
							</FormGroup>
						</Col>
						<Col md="6">
							<FormGroup>
								<Label className="form-label">Sitio Web</Label>
								<Input
									className="form-control"
									value={data.website}
									onChange={(e) =>
										setData({
											...data,
											website: e.target.value,
										})
									}
								/>
							</FormGroup>
						</Col>
						<Col md="12">
							<FormGroup>
								<Label className="form-label">Dirección</Label>
								<Input
									className="form-control"
									type="text"
									value={data.address}
									onChange={(e) =>
										setData({
											...data,
											address: e.target.value,
										})
									}
								/>
							</FormGroup>
						</Col>
						<Col md="12">
							<div className="form-group mb-0">
								<Label className="form-label">Acerca de mí</Label>
								<Input
									type="textarea"
									className="form-control"
									rows="4"
									value={data.about_me}
									onChange={(e) =>
										setData({
											...data,
											about_me: e.target.value,
										})
									}
								/>
							</div>
						</Col>
					</Row>
				</CardBody>
				<CardFooter className="text-right">
					<button className="btn btn-primary" onClick={() => handleSave()}>
						Guardar
					</button>
				</CardFooter>
			</Card>
		</Col>
	);
};

export default FormUser;
