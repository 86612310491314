import React from "react";
import { Table, Thead, Tbody, Th, Tr, Td } from "react-super-responsive-table";

const CourseModalTable = (props) => {
	const { courses } = props;

	return (
		<Table className="base-table">
			<Thead>
				<Tr>
					<Th>Nombre</Th>
					<Th>Código</Th>
					<Th>Nivel</Th>
				</Tr>
			</Thead>
			<Tbody>
				{courses &&
					courses.map((item, i) => {
						return (
							<Tr key={i}>
								<Td>{item.name}</Td>
								<Td>{item.code}</Td>
								<Td style={{ textAlign: "center" }}>{item.level && item.level.name}</Td>
							</Tr>
						);
					})}
			</Tbody>
		</Table>
	);
};

export default CourseModalTable;
