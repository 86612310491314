import React, { useEffect, useState } from "react";

import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

import Fetching from "components/fetching/Fetching";
import api from "services/api";
import CustomerFields from "./CustomerFields";
import CustomerPeriods from "./CustomerPeriods";

const baseObject = {
	name: "",
	email: "",
	phone: "",
	number: "",
	address: "",
	country_id: "",
	commune_id: "",
	expiration_date: "",
};

const CustomerForm = (props) => {
	let navigate = useNavigate();
	const { type, slug } = useParams();

	const { pageName, countries, allCommunes, callbackAction } = props;
	const [isFetching, setIsFetching] = useState(true);
	const [data, setData] = useState(baseObject);
	const [BasicTab, setBasicTab] = useState("1");

	const handleClose = () => {
		navigate("/admin/customers");
	};

	const validSave = () => {
		if (!data.name || data.name === "") {
			toast.error("El campo nombre es obligatorio");
			return false;
		}
		if (!data.expiration_date || data.expiration_date === "") {
			toast.error("El campo de fecha de caducidad es obligatorio");
			return false;
		}
		return true;
	};

	const handleSave = async () => {
		if (validSave()) {
			setIsFetching(true);
			if (await callbackAction(data)) {
				handleClose();
			} else {
				setIsFetching(false);
			}
		}
	};

	useEffect(() => {
		setIsFetching(false);
		if (type) {
			setIsFetching(true);
			api.get("/admin/customer", { type, slug: slug ? slug : "" }).then((response) => {
				setData(response.customer);
				setIsFetching(false);
			});
		} else {
			setData(baseObject);
		}
		// eslint-disable-next-line
	}, [type, slug, allCommunes]);

	return (
		<Modal isOpen={type ? true : false} toggle={handleClose} size="lg">
			{isFetching ? (
				<Fetching />
			) : (
				<>
					<ModalHeader toggle={handleClose}>{pageName}</ModalHeader>
					<ModalBody>
						{data.active === "1" ? (
							<button className="btn btn-sm btn-success" style={{ marginBottom: "5px", marginTop: "5px" }} disabled>
								Activo
							</button>
						) : (
							<button className="btn btn-sm btn-danger" style={{ marginBottom: "5px", marginTop: "5px" }} disabled>
								Inactivo
							</button>
						)}

						<Nav tabs>
							<NavItem>
								<NavLink className={BasicTab === "1" ? "active" : ""} onClick={() => setBasicTab("1")}>
									Formulario
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink className={BasicTab === "2" ? "active" : ""} onClick={() => setBasicTab("2")}>
									Periodos
								</NavLink>
							</NavItem>
						</Nav>
						<TabContent activeTab={BasicTab}>
							<TabPane className="fade show" tabId="1">
								<CustomerFields data={data} setData={setData} countries={countries} allCommunes={allCommunes} />
							</TabPane>
							<TabPane tabId="2">
								<CustomerPeriods data={data} setData={setData} setIsFetching={setIsFetching} />
							</TabPane>
						</TabContent>
					</ModalBody>
					<ModalFooter>
						<Button color="secondary" onClick={handleClose}>
							Cancelar
						</Button>
						<Button color="primary" onClick={() => handleSave()}>
							Guardar
						</Button>
					</ModalFooter>
				</>
			)}
		</Modal>
	);
};

export default CustomerForm;
