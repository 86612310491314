import React, { useState, useEffect } from "react";

import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import _ from "lodash";

// import classes from "./answer.module.scss";

import api from "services/api";
import Fetching from "components/fetching/Fetching";
import EvaluationInitial from "./EvaluationInitial";
import EvaluationInCourse from "./EvaluationInCourse";

const EvaluationAnswer = ({ t }) => {
	const evaluation = useSelector((content) => content.Evaluation);
	const { slug } = useParams();
	let navigate = useNavigate();

	const [isFetching, setIsFetching] = useState(true);
	const [inCourse, setInCourse] = useState(false);
	const [loaded, setLoaded] = useState(false);
	const [data, setData] = useState({});

	useEffect(() => {
		if (_.isEmpty(data) && !loaded) {
			setLoaded(true);

			api.get("/run_quiz", {
				selected: slug,
			})
				.then((response) => {
					let selectedQuestion = 0;
					try {
						response.evaluation.questions.forEach((question, j) => {
							if (!question.answered) {
								selectedQuestion = j;
								// eslint-disable-next-line
								throw "Break";
							}
						});
					} catch (e) {
						if (e !== "Break") throw e;
					}

					response.evaluation.selectedQuestion = selectedQuestion;

					setData(response.evaluation);

					if (evaluation.evaluation_id) {
						setInCourse(true);
					}

					setIsFetching(false);
					setLoaded(false);

					if (response.evaluation.course.finalized_at) {
						navigate("/my_courses/" + response.course.slug);
					}

					if (response.finished) {
						navigate("/evaluations/finish/" + response.evaluation.slug);
					}

					if (evaluation.evaluation_id && evaluation.seconds <= 0) {
						navigate("/evaluations/finish/" + evaluation.slug);
					}
				})
				.catch((error) => {
					navigate(-1);
				});
		}

		// eslint-disable-next-line
	}, [slug, evaluation.evaluation_id]);

	if (isFetching) {
		return <Fetching />;
	}

	return (
		<>
			{inCourse ? (
				<EvaluationInCourse data={data} setData={setData} />
			) : (
				<EvaluationInitial data={data} setData={setData} setIsFetching={setIsFetching} setInCourse={setInCourse} />
			)}
		</>
	);
};

export default EvaluationAnswer;
