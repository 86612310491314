import React, { useEffect, useState } from "react";

import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Col, Label, Input } from "reactstrap";
import { toast } from "react-toastify";

import Fetching from "components/fetching/Fetching";

import api from "services/api";

const baseObject = {
	description: "",
	icon: "",
	indice: "",
	label: "",
	path: "",
};

const ScreenForm = (props) => {
	const [isFetching, setIsFetching] = useState(true);
	const [data, setData] = useState(baseObject);

	const { pageName, selected, form, setForm, callbackAction } = props;

	const handleClose = () => {
		setForm(false);
	};

	const handleSave = () => {
		if (validSave()) {
			callbackAction(data);
			setData(baseObject);
		}
	};

	const validSave = () => {
		if (!data.label || data.label === "") {
			toast.error(("The label field is required"));
			return false;
		}
		if (!data.path || data.path === "") {
			toast.error(("The path field is required"));
			return false;
		}
		return true;
	};

	useEffect(() => {
		setIsFetching(false);
		if (selected) {
			setIsFetching(true);
			api.get("/admin/screen", { selected }).then((response) => {
				setData(response.screen);
				setIsFetching(false);
			});
		} else {
			setData(baseObject);
		}
	}, [selected]);

	return (
		<Modal isOpen={form} toggle={handleClose} size="lg">
			{isFetching ? (
				<Fetching />
			) : (
				<>
					<ModalHeader toggle={handleClose}>{(pageName)}</ModalHeader>
					<ModalBody>
						<div className="row">
							<Col md="6 mb-2">
								<Label htmlFor="label">Etiqueta</Label>
								<Input
									className="form-control"
									name="label"
									type="text"
									value={data.label}
									onChange={(e) =>
										setData({
											...data,
											label: e.target.value,
										})
									}
								/>
							</Col>
							<Col md="6 mb-2">
								<Label htmlFor="description">Descripción</Label>
								<Input
									className="form-control"
									name="description"
									type="text"
									value={data.description}
									onChange={(e) =>
										setData({
											...data,
											description: e.target.value,
										})
									}
								/>
							</Col>
						</div>

						<div className="row">
							<Col md="6 mb-2">
								<Label htmlFor="indice">("Index")</Label>
								<Input
									className="form-control"
									name="indice"
									type="text"
									value={data.indice}
									onChange={(e) =>
										setData({
											...data,
											indice: e.target.value,
										})
									}
								/>
							</Col>

							<Col md="6 mb-2">
								<Label htmlFor="icon">("Icon")</Label>
								<Input
									className="form-control"
									name="icon"
									type="text"
									value={data.icon}
									onChange={(e) =>
										setData({
											...data,
											icon: e.target.value,
										})
									}
								/>
							</Col>
						</div>

						<div className="row">
							<Col md="6 mb-2">
								<Label htmlFor="path">("Path")</Label>
								<Input
									className="form-control"
									name="path"
									type="text"
									value={data.path}
									onChange={(e) =>
										setData({
											...data,
											path: e.target.value,
										})
									}
								/>
							</Col>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button color="secondary" onClick={handleClose}>
							Cancelar
						</Button>
						<Button color="primary" onClick={() => handleSave()}>
							Guardar
						</Button>
					</ModalFooter>
				</>
			)}
		</Modal>
	);
};

export default ScreenForm;
