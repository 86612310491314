import React from "react";
import { useState } from "react";

import { Col, Label, Input } from "reactstrap";

import classes from "../evaluation.module.scss";

const EvaluationFilter = (props) => {
	const { filters, applyFilter } = props;

	const [filterSearch, setFilterSearch] = useState(filters.search);
	const [filterName, setFilterName] = useState(filters.name);

	const apply = () => {
		const params = {
			filterSearch,
			filterName,
		};

		applyFilter(params);
	};

	const clean = () => {
		setFilterSearch("");
		setFilterName("");

		const params = {
			filterSearch: "",
			filterName: "",
		};

		applyFilter({ ...params });
	};

	return (
		<Col sm="3" className={classes.filter}>
			<div className={classes.title}>Filtros</div>
			<hr className={classes.separator} />
			<div>
				<Col sm="12" className={classes.fields}>
					<Label className={classes.label} htmlFor="search">
						Buscar
					</Label>
					<Input className="form-control" name="search" value={filterSearch} onChange={(e) => setFilterSearch(e.target.value)} />
				</Col>

				<Col sm="12" className={classes.fields}>
					<Label className={classes.label} htmlFor="search">
						Nombre
					</Label>
					<Input className="form-control" name="search" value={filterName} onChange={(e) => setFilterSearch(e.target.value)} />
				</Col>
			</div>
			<hr className={classes.separator} />
			<div className={classes.button_apply} onClick={() => apply()}>
				Aplicar
			</div>
			<div className={classes.button_clean} onClick={() => clean()}>
				Limpiar
			</div>
		</Col>
	);
};

export default EvaluationFilter;
