import React from "react";

import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

import EvaluationModalTable from "./EvaluationModalTable";

const EvaluationModalList = (props) => {
	const { pageName, evaluations, list, setList} = props;

	const handleClose = () => {
		setList(false);
	};

	return (
		<Modal isOpen={list} toggle={handleClose} size="lg">
			<>
				<ModalHeader toggle={handleClose}>{(pageName)}</ModalHeader>
				<ModalBody>
					<EvaluationModalTable  evaluations={evaluations} />
				</ModalBody>
				<ModalFooter>
					<Button color="primary" onClick={() => handleClose()}>
						Aceptar
					</Button>
				</ModalFooter>
			</>
		</Modal>
	);
};

export default EvaluationModalList;
