import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import fileDownload from "js-file-download";
import { useDispatch } from "react-redux";

import api from "services/api";

import classes from "../evaluation.module.scss";
import Fetching from "components/fetching/Fetching";
import Breadcrumb from "layout/breadcrumb";

import EvaluationReviewQuestion from "./EvaluationReviewQuestion";
import EvaluationReviewStudent from "./EvaluationReviewStudent";
import EvaluationReviewHeader from "./EvaluationReviewHeader";

import { HANDLE_GLOBAL_FETCHING } from "../../../../redux/actionTypes";

import { getToday } from "services/utils";

const EvaluationReview = () => {
	const { slug } = useParams();
	const dispatch = useDispatch();
	const divReview = useRef(null);

	const [data, setData] = useState({});
	const [view, setView] = useState("students");
	const [typesQuestion, setTypesQuestion] = useState([]);
	const [isFetching, setIsFetching] = useState(true);

	useEffect(() => {
		setIsFetching(true);
		api.get("/evaluations/review", { slug }).then((response) => {
			setData(response.evaluation);
			setTypesQuestion(response.typesQuestion);
			setIsFetching(false);
		});
		// eslint-disable-next-line
	}, [slug]);

	const handleExcel = () => {
		const filename = `${data.title ? data.title : ""} ${getToday()}.xlsx`;

		dispatch({
			type: HANDLE_GLOBAL_FETCHING,
			payload: {
				isGlobalFetching: true,
				documentName: filename,
			},
		});

		api.getExcel("/evaluations/excel", { evaluation_id: data.id }).then((data) => {
			fileDownload(data, filename);

			dispatch({
				type: HANDLE_GLOBAL_FETCHING,
				payload: {
					isGlobalFetching: false,
					documentName: "",
				},
			});
		});
	};

	const handlePDF = () => {
		const filename = `Evaluación - ${data.title ? data.title : ""} ${getToday()}.pdf`;

		const form = {
			evaluation_slug: data.slug,
		};

		dispatch({
			type: HANDLE_GLOBAL_FETCHING,
			payload: {
				isGlobalFetching: true,
				documentName: filename,
			},
		});

		api.getExcel("/evaluations/print", form).then((response) => {
			const href = URL.createObjectURL(response);

			const link = document.createElement("a");
			link.href = href;
			link.setAttribute("download", filename);
			document.body.appendChild(link);
			link.click();

			document.body.removeChild(link);
			URL.revokeObjectURL(href);

			dispatch({
				type: HANDLE_GLOBAL_FETCHING,
				payload: {
					isGlobalFetching: false,
					documentName: "",
				},
			});
		});
	};

	if (isFetching) {
		return <Fetching />;
	}

	return (
		<div className={classes.main_review} ref={divReview}>
			<Breadcrumb
				title={data.title ? data.title : ""}
				parent="Evaluaciones"
				parentUrl="/evaluations"
				color_title="#00a58d"
				style={{ paddingTop: "10px", paddingBottom: "10px" }}
			/>

			<div className={classes.pretitle}>
				{data.subject ? data.subject.name : ""} - {data.level ? data.level.name : ""}{" "}
			</div>

			<div>
				({data.course ? data.course.code : ""}) {data.course ? data.course.name : ""} | {data.questions ? data.questions.length : 0} preguntas
				| {data.minutes ? data.minutes : 0} minutos
			</div>

			{data.state_id !== 5 && (
				<div className={classes.options}>
					<div className={`${classes.button} ${classes.change}`} onClick={() => setView(view === "students" ? "questions" : "students")}>
						{view === "students" ? " Ver Preguntas" : "Ver Alumnos"}
					</div>

					<div className={classes.button} onClick={() => handleExcel()}>
						<i className="fa fa-file-excel-o" aria-hidden="true"></i> Exportar Excel
					</div>

					<div className={classes.button} onClick={() => handlePDF()}>
						<i className="fa fa-file-pdf-o" aria-hidden="true"></i> Exportar PDF
					</div>
				</div>
			)}

			{data.state_id === 5 && <EvaluationReviewHeader data={data} setData={setData} setIsFetching={setIsFetching} />}
			{data.state_id === 5 && <EvaluationReviewQuestion data={data} setData={setData} typesQuestion={typesQuestion} divReview={divReview} />}
			{data.state_id === 1 && view === "students" && <EvaluationReviewStudent data={data} setData={setData} />}
			{data.state_id === 1 && view === "questions" && (
				<EvaluationReviewQuestion data={data} setData={setData} typesQuestion={typesQuestion} divReview={divReview} />
			)}
		</div>
	);
};

export default EvaluationReview;
