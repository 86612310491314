import React, { useState, useEffect, useCallback, Fragment, useMemo } from "react";
import { Container, Row, Col } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import fileDownload from "js-file-download";
import { toast } from "react-toastify";
import debounce from "lodash/debounce";

import Breadcrumb from "layout/breadcrumb";
import Paginator from "components/list/Paginator";
import Search from "components/list/Search";
import Fetching from "components/fetching/Fetching";
import Confirm from "components/confirm/Confirm";
import BaseFilter from "components/filter/BaseFilter";

import QuestionLevelsTable from "./table/QuestionLevelsTable";
import QuestionLevelsForm from "./form/QuestionLevelsForm";

import api from "services/api";
import { getToday } from "services/utils";
import { HANDLE_GLOBAL_FETCHING } from "../../../redux/actionTypes";
import { base_filters } from "./questionLevels.constants";

const QuestionLevels = (props) => {
	let navigate = useNavigate();
	const dispatch = useDispatch();

	const [isFetching, setIsFetching] = useState(true);
	const [search, setSearch] = useState("");
	const [mainSearch, setMainSearch] = useState("");
	const [page, setPage] = useState(1);
	const [data, setData] = useState([]);
	const [permissions, setPermissions] = useState([]);
	const [from, setFrom] = useState("");
	const [to, setTo] = useState("");
	const [total, setTotal] = useState("");
	const [lastPage, setLastPage] = useState("");

	const [allSubjects, setAllSubjects] = useState([]);

	const [selected, setSelected] = useState("");
	const [modal, setModal] = useState(false);
	const [showFilter, setShowFilter] = useState(false);

	const [filters, setFilters] = useState([...base_filters]);

	useEffect(() => {
		const reduceFilters = filters.reduce((obj, item) => {
			obj[item.key] = item.value;
			return obj;
		}, {});

		setIsFetching(true);
		api.get("/admin/question_levels", {
			page,
			search,
			...reduceFilters,
		}).then((response) => {
			updateData(response);
		});
		// eslint-disable-next-line
	}, [page, search, filters.map((f) => f.value).join("|")]);

	const updateData = (response) => {
		const { question_levels, subjects, permissions } = response;

		setPermissions(permissions);
		setData(question_levels.data);
		setFrom(question_levels.from);
		setTo(question_levels.to);
		setTotal(question_levels.total);
		setLastPage(question_levels.last_page);

		setAllSubjects(subjects);

		updateFilters(subjects);

		setIsFetching(false);
	};

	const updateFilters = (subjects) => {
		let newFilters = [...filters];

		let subjectKey = newFilters.findIndex((item) => item.key === "filter_subject_id");
		newFilters[subjectKey].options = subjects;

		setFilters([...newFilters]);

		setIsFetching(false);
	};

	const debouncedSetSearch = useMemo(
		() =>
			debounce((v) => {
				setSearch(v);
			}, 500),
		[setSearch]
	);

	const delayedQuery = useCallback(
		(...args) => {
			debouncedSetSearch(...args);
		},
		[debouncedSetSearch]
	);

	const setDelaySearch = (value) => {
		setMainSearch(value);
		delayedQuery(value);
	};

	// Form
	const createElement = () => {
		navigate("/admin/question_levels/create");
	};

	const handleEdit = async (data) => {
		const response = await api.post("/admin/question_levels_save", data).catch((e) => {
			return { error: e.message };
		});
		if (response.hasOwnProperty("error")) {
			return false;
		}

		updateData(response);
		toast.success("Registro guardado correctamente");
		return true;
	};

	const deleteElement = (item) => {
		setSelected(item.id);
		setModal(true);
	};

	const handleDelete = () => {
		api.post("/admin/question_levels_delete", { selected }).then((response) => {
			updateData(response);
			setSelected("");

			toast.success("Registro eliminado correctamente");
		});
	};

	const applyFilter = (newFilters) => {
		setFilters([...newFilters]);
		setShowFilter(false);
	};

	const handleExcel = () => {
		const filename = `Nivel de Pregunta ${getToday()}.xlsx`;

		const reduceFilters = filters.reduce((obj, item) => {
			obj[item.key] = item.value;
			return obj;
		}, {});

		dispatch({
			type: HANDLE_GLOBAL_FETCHING,
			payload: {
				isGlobalFetching: true,
				documentName: filename,
			},
		});

		api.getExcel("/admin/question_levels/excel", { ...reduceFilters, search }).then((data) => {
			fileDownload(data, filename);

			dispatch({
				type: HANDLE_GLOBAL_FETCHING,
				payload: {
					isGlobalFetching: false,
					documentName: "",
				},
			});
		});
	};

	return (
		<Fragment>
			<Breadcrumb title="Nivel" parent="Mantenedores" />
			<Container fluid={true}>
				<Row>
					<Search
						search={mainSearch}
						setSearch={setDelaySearch}
						to={to}
						from={from}
						total={total}
						permissions={permissions}
						handleCreate={createElement}
						handleExport={handleExcel}
						haveFilter={true}
						filters={filters}
						showFilter={showFilter}
						setShowFilter={setShowFilter}
					/>

					{showFilter && <BaseFilter filters={filters} applyFilter={applyFilter} />}

					<Col sm={showFilter ? "9" : "12"}>
						{isFetching ? <Fetching /> : <QuestionLevelsTable deleteElement={deleteElement} data={data} permissions={permissions} />}
					</Col>

					<Paginator page={page} setPage={setPage} lastPage={lastPage} total={total} />
				</Row>
			</Container>

			<QuestionLevelsForm selected={selected} callbackAction={handleEdit} subjects={allSubjects} />

			<Confirm modal={modal} setModal={setModal} callbackAction={handleDelete} />
		</Fragment>
	);
};

export default QuestionLevels;
