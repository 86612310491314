import React from "react";
import { Modal, ModalHeader, ModalFooter, Button } from "reactstrap";

import api from "services/api";

const EvaluationReviewFinish = ({ data, setData, showModal, setShowModal, setGlobalIsFetching }) => {
	const toggle = () => {
		setShowModal(!showModal);
	};

	const handleFinish = () => {
		setGlobalIsFetching(true);
		toggle();
		api.post("/evaluations/review/question/finish", { ...data }).then((response) => {
			let newData = { ...data };
			newData.state_id = 1;
			setData(newData);

			setGlobalIsFetching(false);
		});
	};

	return (
		<Modal isOpen={showModal} toggle={toggle} className="modal-body" backdrop="static">
			<ModalHeader toggle={toggle}>Estas seguro que deseas finalizar la revisión de preguntas?</ModalHeader>
			<ModalFooter>
				<Button color="secondary" onClick={toggle}>
					Cancelar
				</Button>
				<Button color="primary" onClick={() => handleFinish()}>
					Confirmar
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default EvaluationReviewFinish;
