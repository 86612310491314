import React from "react";
import { formatMilesDecimal } from "services/utils";

import api from "services/api";

const CustomerPeriods = ({ data, setData, setIsFetching }) => {
	const handleChangePeriod = (i) => {
		let newPeriods = [...data.periods];
		newPeriods.paid = true;

		setIsFetching(true);
		api.post("/admin/customer/periods", { ...data.periods[i] }).then((response) => {
			setIsFetching(false);
			setData({
				...data,
				periods: newPeriods,
			});
		});
	};

	const handleDeletePeriod = (i) => {
		let newPeriods = [...data.periods];

		setIsFetching(true);
		api.post("/admin/customer/periods/delete", { ...data.periods[i] }).then((response) => {
			newPeriods.splice(i, 1);
			setIsFetching(false);
			setData({
				...data,
				active: response.active,
				periods: newPeriods,
			});
		});
	};

	return (
		<div className="row">
			<div className="col-md-12 mb-3">
				<div className="table-responsive">
					<table className="table table-sm table-bordered">
						<thead>
							<tr>
								<th>Periodo</th>
								<th>Fecha Inicio</th>
								<th>Fecha Fin</th>
								<th>Precio</th>
								<th>Acciones</th>
							</tr>
						</thead>
						<tbody>
							{data &&
								data.periods &&
								data.periods.map((period, i) => (
									<tr key={i}>
										<td>{i + 1}</td>
										<td>{period.initial_date}</td>
										<td>{period.final_date}</td>
										<td>{formatMilesDecimal(period.price)}</td>
										<td>
											{!period.paid ? (
												<button className="btn btn-sm btn-warning" onClick={(i) => handleChangePeriod(i)}>
													<i className="fa fa-money"></i>
												</button>
											) : (
												<button className="btn btn-sm btn-success" disabled>
													<i className="fa fa-money"></i>
												</button>
											)}
											<button className="btn btn-sm btn-danger" style={{ margin: "2px" }} onClick={() => handleDeletePeriod(i)}>
												<i className="fa fa-trash"></i>
											</button>
										</td>
									</tr>
								))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

export default CustomerPeriods;
