import React from "react";
import { Col, Label, Input, Button } from "reactstrap";
import { toast } from "react-toastify";
import { integerValidate } from "services/utils";

import { ReactComponent as MagicSvg } from "assets/icons/magic.svg";

const EvaluationStepTime = ({ data, setData, handleSave, isSave }) => {
	const handleValid = () => {
		if (!data.initial_date) {
			toast.error("El campo fecha inicial es obligatorio");
			return false;
		}
		if (!data.finish_date) {
			toast.error("El campo fecha final es obligatorio");
			return false;
		}
		if (!data.minutes) {
			toast.error("El campo minutos es obligatorio");
			return false;
		}

		handleSave(4);
	};

	return (
		<div>
			<div style={{ display: "flex" }}>
				<div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
					<h4>
						Los últimos ajustes para <span style={{ color: "#00a58d" }}>crear</span> la evaluación...
					</h4>
				</div>
			</div>

			<div className="row">
				<Col md="12 mb-3">
					<Label style={{ fontSize: "16px" }} htmlFor="description">
						En que fechas estará disponible?
					</Label>
					<div className="row">
						<Col md="6">
							<Label style={{ fontSize: "16px" }} htmlFor="description">
								Desde
							</Label>
							<Input
								className="form-control"
								name="initial_date"
								type="date"
								value={data.initial_date && data.initial_date}
								onChange={(e) =>
									setData({
										...data,
										initial_date: e.target.value,
									})
								}
							/>
						</Col>
						<Col md="6">
							<Label style={{ fontSize: "16px" }} htmlFor="description">
								Hasta
							</Label>
							<Input
								className="form-control"
								name="finish_date"
								type="date"
								value={data.finish_date && data.finish_date}
								onChange={(e) =>
									setData({
										...data,
										finish_date: e.target.value,
									})
								}
							/>
						</Col>
					</div>
				</Col>

				<Col md="12 mb-3">
					<Label style={{ fontSize: "16px" }} htmlFor="description">
						Cuantos minutos tendrán los estudiantes para realizarla?
					</Label>
					<Input
						className="form-control"
						name="minutes"
						type="text"
						onKeyDown={(e) => {
							integerValidate(e);
							e.key === "Enter" && handleValid();
						}}
						value={data.minutes && data.minutes}
						onChange={(e) =>
							setData({
								...data,
								minutes: e.target.value,
							})
						}
					/>
				</Col>
			</div>

			<div style={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
				<Button disabled={isSave} color="secondary" style={{ padding: "10px 30px", marginRight: "10px" }} onClick={() => handleSave(2)}>
					Volver
				</Button>

				<Button
					disabled={isSave}
					color="primary"
					style={{ padding: "10px 30px", display: "flex", justifyContent: "center", alignItems: "center" }}
					onClick={() => handleValid(3)}
				>
					{isSave ? (
						"Guardando..."
					) : (
						<>
							Crear evaluación <MagicSvg style={{ marginLeft: "10px" }} />
						</>
					)}
				</Button>
			</div>
		</div>
	);
};

export default EvaluationStepTime;
