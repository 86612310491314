import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";

import classes from "../course.module.scss";

const FormStudentsImport = ({ modal, setModal, callbackAction }) => {
	const [fileName, setFileName] = useState("");

	const toggle = () => setModal(!modal);

	const applyAction = () => {
		callbackAction();
		setFileName("");
		setModal(false);
	};

	const handleUploadFile = () => {
		const file = document.getElementById("fileStudent");
		file.click();
		file.onchange = (e) => {
			setFileName(e.target.files[0].name);
		};
	};

	return (
		<Modal isOpen={modal} toggle={toggle} backdrop="static">
			<ModalHeader toggle={toggle} style={{ fontSize: "1rem" }}>
				Importar estudiantes
			</ModalHeader>
			<ModalBody>
				<p>Esta acción inscribirá a los estudiantes en el curso.</p>
				<div>
					<span>
						<strong>Nota:</strong>
					</span>
					<ul>
						<li>- El archivo debe ser en formato .xlsx</li>
						<li>- El archivo debe contener los campos: Nombre de Usuario, Nombre y Email</li>
						<li>- Si se completa el campo contraseña, se actualizará la contraseña del estudiante</li>
					</ul>

					<div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
						<div className={classes.buttonUploadFile} onClick={() => handleUploadFile()}>
							Subir Archivo
						</div>
						<br />
						{fileName ? (
							<span style={{ marginLeft: "10px" }}>
								<strong>Archivo seleccionado: </strong> {fileName}
							</span>
						) : (
							""
						)}
						<input type="file" id="fileStudent" style={{ display: "none" }} accept=".xlsx" />
					</div>
				</div>
			</ModalBody>
			<ModalFooter>
				<Button color="secondary" onClick={toggle}>
					Cancelar
				</Button>
				{fileName ? (
					<Button disabled={fileName === "" ? true : false} color="primary" onClick={applyAction}>
						Importar Estudiantes
					</Button>
				) : (
					""
				)}
			</ModalFooter>
		</Modal>
	);
};

export default FormStudentsImport;
