import React from "react";

import classes from "../answer.module.scss";

const AnswerQuestionFormTOF = ({ question, setQuestion, data, setData }) => {
	const handleSelect = (option) => {
		const findIndex = data.questions.findIndex((q) => q.id === question.id);
		data.questions[findIndex].selected = {
			answer: option,
		};
		setData({
			...data,
			questions: data.questions,
		});

		setQuestion({
			...question,
			selected: {
				answer: option,
			},
		});
	};

	return (
		<div className={classes.tof}>
			<div
				className={`${classes.option} ${question.selected && question.selected.answer === "false" && classes.false}`}
				onClick={() => handleSelect("false")}
			>
				Falso
			</div>
			<div
				className={`${classes.option} ${question.selected && question.selected.answer === "true" && classes.true}`}
				onClick={() => handleSelect("true")}
			>
				Verdadero
			</div>
		</div>
	);
};

export default AnswerQuestionFormTOF;
