export const baseObject = {
	name: "",
	last_name: "",
	last_name_mother: "",
	email: "",
	username: "",
	active: false,
	address: "",
	password: "",
	confirm_password: "",
	customer_id: "",
	country_id: "",
	commune_id: "",
	permissions: [],
	user_roles: [],
};

export const base_filters = [
	{
		key: "filter_customer_id",
		name: "Cliente",
		value: "",
		type: "select",
		options: [],
	},
	{
		key: "filter_name",
		name: "Nombre",
		value: "",
		type: "text",
		options: [],
	},
	{
		key: "filter_email",
		name: "Email",
		value: "",
		type: "text",
		options: [],
	},
];
