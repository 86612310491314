import React from "react";
import { Spinner } from "reactstrap";

const Fetching = ({ customStyle }) => {
	const other_styles = customStyle ? customStyle : null;

	return (
		<div className="fetching-data" style={{ ...other_styles }}>
			<Spinner type="grow" />
		</div>
	);
};

export default Fetching;
