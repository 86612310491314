import React, { useState, useEffect, useCallback, Fragment, useMemo } from "react";
import { Container, Row, Col } from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import debounce from "lodash/debounce";
import { toast } from "react-toastify";

import Breadcrumb from "layout/breadcrumb";
import Paginator from "components/list/Paginator";
import Search from "components/list/Search";
import EvaluationTable from "./table/EvaluationTable";
import Fetching from "components/fetching/Fetching";
import Confirm from "components/confirm/Confirm";
import EvaluationForm from "./form/EvaluationForm";
import EvaluationFilter from "./table/EvaluationFilter";

import api from "services/api";
import { base_filters } from "./evaluation.constants";

import { HANDLE_GLOBAL_FETCHING } from "../../../redux/actionTypes";

const Evaluation = () => {
	let navigate = useNavigate();
	const dispatch = useDispatch();
	const { action, evaluation_slug } = useParams();

	const [isFetching, setIsFetching] = useState(true);
	const [search, setSearch] = useState("");
	const [mainSearch, setMainSearch] = useState("");
	const [page, setPage] = useState(1);
	const [data, setData] = useState([]);
	const [permissions, setPermissions] = useState([]);
	const [from, setFrom] = useState("");
	const [to, setTo] = useState("");
	const [total, setTotal] = useState("");
	const [lastPage, setLastPage] = useState("");
	const [showFilter, setShowFilter] = useState(false);

	const [selected, setSelected] = useState("");
	const [slug, setSlug] = useState(evaluation_slug || "");
	const [showEvaluation, setShowEvaluation] = useState(!!(action && ["create", "edit"].includes(action)));
	const [modal, setModal] = useState(false);

	const [roles, setRoles] = useState([]);
	const [courses, setCourses] = useState([]);

	const [filters, setFilters] = useState({ ...base_filters });

	useEffect(() => {
		const form = {
			page,
			search,
		};

		api.get("/evaluations", form).then((response) => {
			updateData(response);
		});
		// eslint-disable-next-line
	}, [page, search]);

	const updateData = (response) => {
		const { evaluations, roles, permissions, courses } = response;

		setPermissions(permissions);
		setRoles(roles);
		setCourses(courses);

		setData(evaluations.data);
		setFrom(evaluations.from);
		setTo(evaluations.to);
		setTotal(evaluations.total);
		setLastPage(evaluations.last_page);

		setIsFetching(false);
	};

	const debouncedSetSearch = useMemo(
		() =>
			debounce((v) => {
				setSearch(v);
			}, 500),
		[setSearch]
	);

	const delayedQuery = useCallback(
		(...args) => {
			debouncedSetSearch(...args);
		},
		[debouncedSetSearch]
	);

	const setDelaySearch = (value) => {
		setMainSearch(value);
		delayedQuery(value);
	};

	// Form
	const createElement = () => {
		setSlug("");
		setShowEvaluation(true);
		navigate("/evaluations/create");
	};

	const editElement = (item) => {
		setSelected(item.id);
		navigate(`/evaluations/${item.slug}/tests`);
	};

	const deleteElement = (item) => {
		setSelected(item.id);
		setModal(true);
	};

	const handleDelete = () => {
		const form = {
			selected,
		};

		api.post("/evaluations_delete", form).then((response) => {
			updateData(response);
			setSelected("");

			toast.success("Registro eliminado correctamente");
		});
	};

	const handlePrint = (item) => {
		const fileName = `Evaluación - ${item.title}.pdf`;

		const form = {
			evaluation_slug: item.slug,
		};

		dispatch({
			type: HANDLE_GLOBAL_FETCHING,
			payload: {
				isGlobalFetching: true,
				documentName: fileName,
			},
		});

		api.getExcel("/evaluations/print", form).then((response) => {
			const href = URL.createObjectURL(response);

			const link = document.createElement("a");
			link.href = href;
			link.setAttribute("download", fileName);
			document.body.appendChild(link);
			link.click();

			document.body.removeChild(link);
			URL.revokeObjectURL(href);

			dispatch({
				type: HANDLE_GLOBAL_FETCHING,
				payload: {
					isGlobalFetching: false,
					documentName: "",
				},
			});
		});
	};

	const applyFilter = (params) => {
		let newFilters = { ...filters };

		if (params.filterSearch !== filters.search) {
			newFilters.search = params.filterSearch;
		}
		if (params.filterName !== filters.name) {
			newFilters.name = params.filterName;
		}

		setFilters(newFilters);
		setShowFilter(false);
	};

	return (
		<Fragment>
			<Breadcrumb title={"Evaluaciones"} parent="Midio" />
			<Container fluid={true}>
				<Row>
					<Search
						search={mainSearch}
						setSearch={setDelaySearch}
						to={to}
						from={from}
						total={total}
						permissions={permissions}
						handleCreate={createElement}
						haveFilter={true}
						showFilter={showFilter}
						setShowFilter={setShowFilter}
					/>

					{showFilter && <EvaluationFilter filters={filters} applyFilter={applyFilter} />}

					<Col sm={showFilter ? "9" : "12"} style={{ paddingRight: "0px", paddingLeft: "0px" }}>
						{isFetching ? (
							<Fetching />
						) : (
							<EvaluationTable
								editElement={editElement}
								deleteElement={deleteElement}
								data={data}
								permissions={permissions}
								roles={roles}
								setShowEvaluation={setShowEvaluation}
								setSlug={setSlug}
								courses={courses}
								updateData={updateData}
								handlePrint={handlePrint}
							/>
						)}
					</Col>

					<Paginator page={page} setPage={setPage} lastPage={lastPage} total={total} />
				</Row>
			</Container>
			<Confirm modal={modal} setModal={setModal} callbackAction={handleDelete} />
			<EvaluationForm form={showEvaluation} setForm={setShowEvaluation} evaluationSlug={slug} />
		</Fragment>
	);
};

export default Evaluation;
