import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from "reactstrap";

import { START_EVALUATION } from "../../../../redux/actionTypes";
import api from "services/api";

import { ReactComponent as StartIcon } from "assets/icons/start.svg";
import classes from "./answer.module.scss";

const EvaluationInitial = ({ data, setData, setIsFetching, setInCourse }) => {
	const dispatch = useDispatch();
	const [open, setOpen] = useState();

	const handleStart = () => {
		setIsFetching(true);

		api.post("/initial_quiz", {
			id: data.id,
			data: data,
		}).then((response) => {
			const endTimestamp = new Date().getTime() + data.minutes * 60 * 1000;
			localStorage.setItem("evaluationEnd", endTimestamp.toString());

			dispatch({
				type: START_EVALUATION,
				payload: {
					start: true,
					evaluation_id: response.evaluation_id,
					slug: data.slug,
					evaluation_title: data.title,
					course_code: data.course.code,
					course_name: data.course.name,
					seconds: data.minutes * 60,
					response: response,
				},
			});

			setData({ ...data, initial_quiz: response });
			setInCourse(true);
			setIsFetching(false);
		});
	};

	const toggle = (id) => {
		if (open === id) {
			setOpen();
		} else {
			setOpen(id);
		}
	};

	return (
		<div className={classes.divInitial}>
			<div className={classes.textStart} style={{ fontWeight: 500, paddingBottom: "1px" }}>
				{data.title} ({data.minutes} minutos)
			</div>
			<div className={classes.textStart}>Al iniciar la prueba debe tener en cuenta los siguientes puntos</div>

			<Accordion open={open ? open : "0"} toggle={toggle} style={{ width: "65vh" }}>
				<AccordionItem>
					<AccordionHeader targetId="1">
						<h4>1. Condiciones</h4>
					</AccordionHeader>
					<AccordionBody accordionId="1">
						<div className={classes.subtextStart}>
							<p>
								<strong>Dispositivo:</strong> Necesitarás un dispositivo (computadora, laptop, tablet o teléfono inteligente) con
								acceso a internet y que cumpla con los requisitos mínimos del sistema para la plataforma de evaluación.
							</p>
							<p>
								<strong>Conexión a internet:</strong> Es necesario tener una conexión a internet estable y suficientemente rápida para
								poder acceder y completar la evaluación online.
							</p>
							<p>
								<strong>Tiempo:</strong> Asegúrate de tener suficiente tiempo para completar la evaluación, según las indicaciones y
								plazos establecidos por el profesor o institución. Para ayudarte con el tiempo la plataforma midio cuenta con una
								notificación de tiempo restante que se encontrará la parte inferior derecha de la pantalla.
							</p>
							<p>
								<strong>Lugar adecuado:</strong> Busca un lugar tranquilo y sin distracciones para realizar la evaluación. Asegúrate
								de que el ambiente sea cómodo y que cuentes con buena iluminación.
							</p>
							<p>
								<strong>Herramientas permitidas:</strong> Revisa qué herramientas o recursos están permitidos para la evaluación y
								tenlos preparados (calculadora, diccionario, etc.).
							</p>
							<p>
								<strong>Identificación:</strong> Verifica los requisitos de identificación para acceder a la evaluación, como el
								ingreso de un nombre de usuario y contraseña, o la autenticación biométrica si corresponde.
							</p>
							<p>
								<strong>Interrupciones:</strong> Asegúrate de que no habrá interrupciones durante la evaluación, como llamadas
								telefónicas o visitas inesperadas.
							</p>
							<p>
								<strong>Respetar las reglas:</strong> Sigue las reglas y pautas establecidas para la evaluación, como el no utilizar
								materiales o recursos no permitidos, no comunicarse con otros estudiantes, entre otros.
							</p>
						</div>
					</AccordionBody>
				</AccordionItem>
				<AccordionItem>
					<AccordionHeader targetId="2">
						<h4>2. Instrucciones</h4>
					</AccordionHeader>
					<AccordionBody accordionId="2">
						<div className={classes.subtextStart}>
							<p>
								<strong> Identificación:</strong> Ingresa tus datos personales y verifica que estén correctos (nombre completo, número
								de identificación, correo electrónico, entre otros).
							</p>
							<p>
								<strong> Preguntas:</strong> Lee cuidadosamente las preguntas de la evaluación. Asegúrate de entender las
								instrucciones para responder cada pregunta.
							</p>
							<p>
								<strong> Herramientas permitidas:</strong> Revisa qué herramientas o recursos están permitidos para la evaluación y
								tenlos preparados (calculadora, diccionario, entre otros).
							</p>
							<p>
								<strong> Integridad:</strong> Recuerda que la evaluación es una oportunidad para que el profesor tenga una idea de tus
								fortalezas y debilidades académicas, por lo que debes ser honesto y completo en tus respuestas.
							</p>
							<p>
								<strong> Respetar las reglas:</strong> Sigue las reglas y pautas establecidas para la evaluación, como el no utilizar
								materiales o recursos no permitidos, no comunicarse con otros estudiantes, entre otros.
							</p>
						</div>
					</AccordionBody>
				</AccordionItem>
				<AccordionItem>
					<AccordionHeader targetId="3">
						<h4>3. Importante</h4>
					</AccordionHeader>
					<AccordionBody accordionId="3">
						<div className={classes.subtextStart}>
							<p>
								Las evaluaciones tienen por objetivo mejorar el aprendizaje de los estudiantes a través de la pesquisa de información
								académica de los estudiantes de la manera más integral y fidedigna posible, es por eso que el profesor a cargo te
								invita a responder sin ayuda.
							</p>
							<p>
								Si ocurre cualquier error durante tu identificación en la página o en el desarrollo de la evaluación te invitamos a
								escribir a soporte@midio.cl donde nuestro equipo atenderá el problema.
							</p>
						</div>
					</AccordionBody>
				</AccordionItem>
			</Accordion>

			<h4 className={classes.ready}>Estás listo?</h4>

			<div>
				<div className={classes.buttonStart} onClick={() => handleStart()}>
					Iniciar
				</div>
			</div>

			<StartIcon className={classes.IconStart} />
		</div>
	);
};

export default EvaluationInitial;
