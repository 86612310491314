import React, { useState } from "react";
import { Col, Label, Input } from "reactstrap";
import Select from "react-select";

import classes from "./baseFilter.module.scss";

const BaseFilter = (props) => {
	const { filters, applyFilter } = props;

	const [localFilter, setLocalFilter] = useState([...filters]);

	const changeFilter = (index, value) => {
		const newFilter = [...localFilter];
		newFilter[index].value = value;
		setLocalFilter(newFilter);
	};

	const apply = () => {
		applyFilter(localFilter);
	};

	const clean = () => {
		let newFilters = [...localFilter];
		newFilters = newFilters.map((filter) => {
			filter.value = "";
			return filter;
		});

		setLocalFilter([...newFilters]);
		applyFilter([...newFilters]);
	};

	return (
		<Col sm="3" className={` ${classes.filter}`}>
			<div className={classes.title}>Filtros</div>
			<hr className={classes.separator} />
			<div>
				<Col sm="12" className={classes.fields}>
					{localFilter.map((filter, index) => {
						let options = [...filter.options];

						if (filter.filter_by) {
							const filterBy = localFilter.find((f) => f.key === filter.filter_by);
							if (filterBy) {
								options = filter.options.filter((f) => Number(f[filter.filter_by]) === Number(filterBy.value));
							}
						}

						return (
							<React.Fragment key={filter.name}>
								<Label className={classes.label} htmlFor={filter.name}>
									{filter.name}
								</Label>
								{filter.type === "select" && (options.length > 0 || filter.filter_by) && (
									<Select
										defaultValue={filter.value ? options.filter((f) => Number(f.value) === Number(filter.value)) : ""}
										placeholder={<div>Seleccione......</div>}
										options={options}
										onChange={(value) => changeFilter(index, value.value)}
										onKeyDown={(e) => {
											if (e.key === "Enter") {
												apply();
											}
										}}
									/>
								)}
								{filter.type === "text" && (
									<Input
										className="form-control"
										name={filter.name}
										value={filter.value}
										onChange={(e) => changeFilter(index, e.target.value)}
										onKeyDown={(e) => {
											if (e.key === "Enter") {
												apply();
											}
										}}
									/>
								)}
							</React.Fragment>
						);
					})}
				</Col>
			</div>

			<div className={classes.filterButtons}>
				<div className={classes.button_clean} onClick={() => clean()}>
					Limpiar
				</div>
				<div className={classes.button_apply} onClick={() => apply()}>
					Aplicar
				</div>
			</div>
		</Col>
	);
};

export default BaseFilter;
