import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { toast } from "react-toastify";

import api from "services/api";
import Fetching from "components/fetching/Fetching";

import classes from "../../midio.module.scss";

const EvaluationClone = ({ selectCourses, selectEvaluation, setSelectCourses, setSelectEvaluation, courses, updateData, modal, setModal }) => {
	const [isFetching, setIsFetching] = useState(false);

	const toggle = () => {
		setSelectEvaluation({});
		setSelectCourses([]);
		setModal(!modal);
	};

	const handleClone = () => {
		const form = {
			evaluation_id: selectEvaluation.id,
			courses: selectCourses.map((item) => item.id),
			page: 1,
			search: "",
		};

		setIsFetching(true);
		api.post("/evaluations/clone", form).then((response) => {
			toast.success("Evaluación clonada con éxito");
			setSelectEvaluation({});
			setSelectCourses([]);
			setIsFetching(false);
			setModal(false);
			updateData(response);
		});
	};

	return (
		<Modal isOpen={modal} toggle={toggle} backdrop="static">
			{isFetching ? (
				<Fetching />
			) : (
				<>
					<ModalHeader toggle={toggle}>Clonar Evaluación</ModalHeader>
					<ModalBody>
						<div className={classes.modal_body}>
							<h4 style={{ fontSize: "16px" }}>
								<strong>Evaluación </strong> {`  ${selectEvaluation.title}`}
							</h4>
							<span>Se clonará la evaluación a los siguientes cursos, selecciona los cursos a los que deseas clonar la evaluación</span>
							{courses
								.filter((course) => Number(course.id) !== Number(selectEvaluation.course_id))
								.map((item, i) => {
									return (
										<div
											style={{
												cursor: "pointer",
												padding: "10px",
												border: "1px solid #ccc",
												margin: "5px 0",
												borderRadius: "5px",
												backgroundColor: selectCourses.find((course) => course.id === item.id) ? "#00a58d" : "",
												color: selectCourses.find((course) => course.id === item.id) ? "white" : "black",
											}}
											key={i}
											className={classes.modal_item}
											onClick={() => {
												setSelectCourses([...selectCourses, item]);
											}}
										>
											{item.label}
										</div>
									);
								})}
						</div>
					</ModalBody>
					<ModalFooter>
						<Button color="secondary" onClick={toggle}>
							Cancelar
						</Button>
						<Button color="primary" onClick={() => handleClone()}>
							Clonar
						</Button>{" "}
					</ModalFooter>
				</>
			)}
		</Modal>
	);
};

export default EvaluationClone;
