import React from "react";

import { Col, Label, Input } from "reactstrap";

const Fields = (props) => {
	const { data, setData } = props;
	return (
		<div className="form-tab-detail">
			<div className="row">
				<Col md="6 mb-2">
					<Label htmlFor="label">Etiqueta</Label>
					<Input
						className="form-control"
						name="label"
						type="text"
						value={data.label}
						onChange={(e) =>
							setData({
								...data,
								label: e.target.value,
							})
						}
					/>
				</Col>
				<Col md="6 mb-2">
					<Label htmlFor="description">Descripción</Label>
					<Input
						className="form-control"
						name="description"
						type="text"
						value={data.description}
						onChange={(e) =>
							setData({
								...data,
								description: e.target.value,
							})
						}
					/>
				</Col>
			</div>

			<div className="row">
				<Col md="6 mb-2">
					<Label htmlFor="icon">("Icon")</Label>
					<Input
						className="form-control"
						name="icon"
						type="text"
						value={data.icon}
						onChange={(e) =>
							setData({
								...data,
								icon: e.target.value,
							})
						}
					/>
				</Col>
			</div>
		</div>
	);
};

export default Fields;
