import React from "react";
import { Spinner } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as DeleteIcon } from "assets/icons/closemin.svg";
import { HANDLE_GLOBAL_FETCHING } from "../../redux/actionTypes";

const Document = () => {
	const dispatch = useDispatch();
	const { documentName, isGlobalFetching } = useSelector((state) => state.Customizer);

	const closeAlert = () => {
		dispatch({
			type: HANDLE_GLOBAL_FETCHING,
			payload: {
				isGlobalFetching: false,
				documentName: "",
			},
		});
	};

	return (
		<>
			{isGlobalFetching && (
				<div className="load-all-page">
					<Spinner animation="border" variant="success" size="sm" />
					<div
						style={{
							marginLeft: "10px",
						}}
					>
						<span
							style={{
								fontSize: "15px",
								fontWeight: "500",
							}}
						>
							Generando documento {documentName && <i>{documentName}</i>}
						</span>
						<br />
						<small>esto puede tardar unos minutos, el documento se descargará automáticamente.</small>
					</div>
					<DeleteIcon onClick={() => closeAlert()} style={{ marginLeft: "15px", cursor: "pointer" }} />
				</div>
			)}
		</>
	);
};

export default Document;
